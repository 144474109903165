import { lazy } from 'react';

/**
 * @description Lazily load the `Inbox` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const InboxAsync = lazy(async function () {
  return import('./Inbox').then((module) => ({ default: module.Inbox }));
});
