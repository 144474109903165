import { lazy } from 'react';

/**
 * @description Lazily load the `Organization` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const OrganizationAsync = lazy(async function () {
  return import('./Organization').then((module) => ({ default: module.Organization }));
});
