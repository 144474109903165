import { configureStore as RtkConfigureStore, getDefaultMiddleware, type StoreEnhancer } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { initialState as features } from './state/features';
import { rootReducer } from './state/rootReducer';

export const createStore = (preloadedState = {}, devTools = false, enhancers: StoreEnhancer[] = []) => {
  const persistConfig = {
    blacklist: [
      // always use values from initialState, not localStorage
      'features',
      // has dedicated persist config, exclude from the global
      'app',
    ],
    key: 'root',
    storage,
  };

  return RtkConfigureStore({
    devTools,
    enhancers(defaultEnhancers) {
      return defaultEnhancers.concat(enhancers);
    },
    middleware: getDefaultMiddleware({
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }),
    preloadedState,
    reducer: persistReducer(persistConfig, rootReducer),
  });
};

export function configureStore(preloadedState = {}, enhancers: StoreEnhancer[] = []) {
  const store = createStore(preloadedState, features.reduxDevtools, enhancers);
  const persistor = persistStore(store);
  return { persistor, store };
}
