import { lazy } from 'react';

/**
 * @description Lazily load `Home`
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const HomeAsync = lazy(async function () {
  return import('./Home').then((module) => ({ default: module.Home }));
});
