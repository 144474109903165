import { Provider, type ProviderProps } from 'react-redux';
import { PersistGate, type PersistGateProps } from 'redux-persist/integration/react';

import { configureStore } from '../../configureStore';

export const { store, persistor } = configureStore({ auth: { token: 'TOKEN' } });

export type ReduxProviderProps = React.PropsWithChildren & {
  providerProps?: Partial<ProviderProps>;
  persistorProps?: Partial<PersistGateProps>;
};

export function ReduxProvider({ providerProps, persistorProps, children }: ReduxProviderProps) {
  return (
    <Provider store={store} {...providerProps}>
      <PersistGate persistor={persistor} {...persistorProps}>
        {children}
      </PersistGate>
    </Provider>
  );
}
