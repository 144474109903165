import { lazy } from 'react';

/**
 * @description Lazily load the `LoginVerification` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const LoginVerificationAsync = lazy(async function () {
  return import('./LoginVerification').then((module) => ({ default: module.LoginVerification }));
});
