/**
 * Generated by @openapi-codegen
 *
 * @version v1 (2025-02-02T00:14+00:00.73ed29f6e94ae64cb2f7dd6b39cf80893559c260)
 */
import * as reactQuery from '@tanstack/react-query';

import { CrowdCoursingContext, useCrowdCoursingContext } from './CrowdCoursingContext';
import type * as Fetcher from './CrowdCoursingFetcher';
import { crowdCoursingFetch } from './CrowdCoursingFetcher';
import type * as Schemas from './CrowdCoursingSchemas';

export type BackgroundCheckGetProductsQueryParams = {
  /**
   * @format uuid
   */
  BubbleId?: string;
  /**
   * @format uuid
   */
  SchoolDistrictId?: string;
};

export type BackgroundCheckGetProductsError = Fetcher.ErrorWrapper<undefined>;

export type BackgroundCheckGetProductsResponse = Schemas.BackgroundCheckProduct[];

export type BackgroundCheckGetProductsVariables = {
  queryParams?: BackgroundCheckGetProductsQueryParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBackgroundCheckGetProducts = (variables: BackgroundCheckGetProductsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BackgroundCheckGetProductsResponse,
    BackgroundCheckGetProductsError,
    undefined,
    {},
    BackgroundCheckGetProductsQueryParams,
    {}
  >({ method: 'get', url: '/api/v1/background-checks/products', ...variables, signal });

export const useBackgroundCheckGetProducts = <TData = BackgroundCheckGetProductsResponse>(
  variables: BackgroundCheckGetProductsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BackgroundCheckGetProductsResponse, BackgroundCheckGetProductsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BackgroundCheckGetProductsResponse, BackgroundCheckGetProductsError, TData>({
    queryFn: ({ signal }) => fetchBackgroundCheckGetProducts({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'backgroundCheckGetProducts',
      path: '/api/v1/background-checks/products',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BackgroundCheckCreateSelfPayError = Fetcher.ErrorWrapper<undefined>;

export type BackgroundCheckCreateSelfPayVariables = {
  body: Schemas.SelfPayRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBackgroundCheckCreateSelfPay = (
  variables: BackgroundCheckCreateSelfPayVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<Schemas.SelfPayResult, BackgroundCheckCreateSelfPayError, Schemas.SelfPayRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/background-checks/selfPay',
    ...variables,
    signal,
  });

export const useBackgroundCheckCreateSelfPay = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SelfPayResult,
      BackgroundCheckCreateSelfPayError,
      BackgroundCheckCreateSelfPayVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    Schemas.SelfPayResult,
    BackgroundCheckCreateSelfPayError,
    BackgroundCheckCreateSelfPayVariables
  >({
    mutationFn: (variables: BackgroundCheckCreateSelfPayVariables) =>
      fetchBackgroundCheckCreateSelfPay({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BackgroundCheckCreateDistrictPayPathParams = {
  /**
   * @format uuid
   */
  bubbleId: string;
};

export type BackgroundCheckCreateDistrictPayError = Fetcher.ErrorWrapper<undefined>;

export type BackgroundCheckCreateDistrictPayVariables = {
  body: Schemas.DistrictPayRequest;
  pathParams: BackgroundCheckCreateDistrictPayPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBackgroundCheckCreateDistrictPay = (
  variables: BackgroundCheckCreateDistrictPayVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    BackgroundCheckCreateDistrictPayError,
    Schemas.DistrictPayRequest,
    {},
    {},
    BackgroundCheckCreateDistrictPayPathParams
  >({ method: 'post', url: '/api/v1/background-checks/bubbles/{bubbleId}/districtPay', ...variables, signal });

export const useBackgroundCheckCreateDistrictPay = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      BackgroundCheckCreateDistrictPayError,
      BackgroundCheckCreateDistrictPayVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    BackgroundCheckCreateDistrictPayError,
    BackgroundCheckCreateDistrictPayVariables
  >({
    mutationFn: (variables: BackgroundCheckCreateDistrictPayVariables) =>
      fetchBackgroundCheckCreateDistrictPay({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleGetBubblePathParams = {
  /**
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetBubbleError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetBubbleVariables = {
  pathParams: BubbleGetBubblePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetBubble = (variables: BubbleGetBubbleVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.BubbleDto, BubbleGetBubbleError, undefined, {}, {}, BubbleGetBubblePathParams>({
    method: 'get',
    url: '/api/v1/Bubbles/{bubbleId}',
    ...variables,
    signal,
  });

export const useBubbleGetBubble = <TData = Schemas.BubbleDto>(
  variables: BubbleGetBubbleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BubbleDto, BubbleGetBubbleError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.BubbleDto, BubbleGetBubbleError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetBubble({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'bubbleGetBubble', path: '/api/v1/Bubbles/{bubbleId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleGetBubblesForUserPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type BubbleGetBubblesForUserError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetBubblesForUserResponse = Schemas.BubbleDto[];

export type BubbleGetBubblesForUserVariables = {
  pathParams: BubbleGetBubblesForUserPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetBubblesForUser = (variables: BubbleGetBubblesForUserVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetBubblesForUserResponse,
    BubbleGetBubblesForUserError,
    undefined,
    {},
    {},
    BubbleGetBubblesForUserPathParams
  >({ method: 'get', url: '/api/v1/Bubbles/users/{userId}', ...variables, signal });

export const useBubbleGetBubblesForUser = <TData = BubbleGetBubblesForUserResponse>(
  variables: BubbleGetBubblesForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetBubblesForUserResponse, BubbleGetBubblesForUserError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetBubblesForUserResponse, BubbleGetBubblesForUserError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetBubblesForUser({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'bubbleGetBubblesForUser', path: '/api/v1/Bubbles/users/{userId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleGetRolesForBulkAddPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetRolesForBulkAddError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetRolesForBulkAddResponse = string[];

export type BubbleGetRolesForBulkAddVariables = {
  pathParams: BubbleGetRolesForBulkAddPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetRolesForBulkAdd = (variables: BubbleGetRolesForBulkAddVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetRolesForBulkAddResponse,
    BubbleGetRolesForBulkAddError,
    undefined,
    {},
    {},
    BubbleGetRolesForBulkAddPathParams
  >({ method: 'get', url: '/api/v1/Bubbles/{bubbleId}/role', ...variables, signal });

export const useBubbleGetRolesForBulkAdd = <TData = BubbleGetRolesForBulkAddResponse>(
  variables: BubbleGetRolesForBulkAddVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetRolesForBulkAddResponse, BubbleGetRolesForBulkAddError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetRolesForBulkAddResponse, BubbleGetRolesForBulkAddError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetRolesForBulkAdd({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetRolesForBulkAdd',
      path: '/api/v1/Bubbles/{bubbleId}/role',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleGetUsersForBubblePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetUsersForBubbleError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetUsersForBubbleResponse = Schemas.UserDistrictSummary[];

export type BubbleGetUsersForBubbleVariables = {
  pathParams: BubbleGetUsersForBubblePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetUsersForBubble = (variables: BubbleGetUsersForBubbleVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetUsersForBubbleResponse,
    BubbleGetUsersForBubbleError,
    undefined,
    {},
    {},
    BubbleGetUsersForBubblePathParams
  >({ method: 'get', url: '/api/v1/Bubbles/{bubbleId}/users', ...variables, signal });

export const useBubbleGetUsersForBubble = <TData = BubbleGetUsersForBubbleResponse>(
  variables: BubbleGetUsersForBubbleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetUsersForBubbleResponse, BubbleGetUsersForBubbleError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetUsersForBubbleResponse, BubbleGetUsersForBubbleError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetUsersForBubble({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetUsersForBubble',
      path: '/api/v1/Bubbles/{bubbleId}/users',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleBulkAddUsersAsRolePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleBulkAddUsersAsRoleError = Fetcher.ErrorWrapper<undefined>;

export type BubbleBulkAddUsersAsRoleVariables = {
  body?: Schemas.BulkAddUserRequest;
  pathParams: BubbleBulkAddUsersAsRolePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleBulkAddUsersAsRole = (variables: BubbleBulkAddUsersAsRoleVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    BubbleBulkAddUsersAsRoleError,
    Schemas.BulkAddUserRequest,
    {},
    {},
    BubbleBulkAddUsersAsRolePathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/users', ...variables, signal });

export const useBubbleBulkAddUsersAsRole = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, BubbleBulkAddUsersAsRoleError, BubbleBulkAddUsersAsRoleVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, BubbleBulkAddUsersAsRoleError, BubbleBulkAddUsersAsRoleVariables>({
    mutationFn: (variables: BubbleBulkAddUsersAsRoleVariables) =>
      fetchBubbleBulkAddUsersAsRole({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleAddRoleToUserPathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-null
   *
   * @format uuid
   */
  userId: string;
  /**
   * Required, must meet expected values
   */
  role: string;
};

export type BubbleAddRoleToUserError = Fetcher.ErrorWrapper<undefined>;

export type BubbleAddRoleToUserVariables = {
  pathParams: BubbleAddRoleToUserPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleAddRoleToUser = (variables: BubbleAddRoleToUserVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, BubbleAddRoleToUserError, undefined, {}, {}, BubbleAddRoleToUserPathParams>({
    method: 'post',
    url: '/api/v1/Bubbles/{bubbleId}/users/{userId}/role/{role}"',
    ...variables,
    signal,
  });

export const useBubbleAddRoleToUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, BubbleAddRoleToUserError, BubbleAddRoleToUserVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, BubbleAddRoleToUserError, BubbleAddRoleToUserVariables>({
    mutationFn: (variables: BubbleAddRoleToUserVariables) =>
      fetchBubbleAddRoleToUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleRemoveRoleFromUserPathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-null
   *
   * @format uuid
   */
  userId: string;
  /**
   * Required, must meet expected values
   */
  role: string;
};

export type BubbleRemoveRoleFromUserError = Fetcher.ErrorWrapper<undefined>;

export type BubbleRemoveRoleFromUserVariables = {
  pathParams: BubbleRemoveRoleFromUserPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleRemoveRoleFromUser = (variables: BubbleRemoveRoleFromUserVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, BubbleRemoveRoleFromUserError, undefined, {}, {}, BubbleRemoveRoleFromUserPathParams>({
    method: 'delete',
    url: '/api/v1/Bubbles/{bubbleId}/users/{userId}/role/{role}',
    ...variables,
    signal,
  });

export const useBubbleRemoveRoleFromUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, BubbleRemoveRoleFromUserError, BubbleRemoveRoleFromUserVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, BubbleRemoveRoleFromUserError, BubbleRemoveRoleFromUserVariables>({
    mutationFn: (variables: BubbleRemoveRoleFromUserVariables) =>
      fetchBubbleRemoveRoleFromUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleGetUpcomingRsvpsPathParams = {
  /**
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetUpcomingRsvpsError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetUpcomingRsvpsResponse = Schemas.RsvpResponse[];

export type BubbleGetUpcomingRsvpsVariables = {
  pathParams: BubbleGetUpcomingRsvpsPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetUpcomingRsvps = (variables: BubbleGetUpcomingRsvpsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetUpcomingRsvpsResponse,
    BubbleGetUpcomingRsvpsError,
    undefined,
    {},
    {},
    BubbleGetUpcomingRsvpsPathParams
  >({ method: 'get', url: '/api/v1/Bubbles/{bubbleId}/upcoming-rsvps', ...variables, signal });

export const useBubbleGetUpcomingRsvps = <TData = BubbleGetUpcomingRsvpsResponse>(
  variables: BubbleGetUpcomingRsvpsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetUpcomingRsvpsResponse, BubbleGetUpcomingRsvpsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetUpcomingRsvpsResponse, BubbleGetUpcomingRsvpsError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetUpcomingRsvps({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetUpcomingRsvps',
      path: '/api/v1/Bubbles/{bubbleId}/upcoming-rsvps',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleGetEventsForBubblePathParams = {
  /**
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetEventsForBubbleError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetEventsForBubbleResponse = Schemas.EventDto[];

export type BubbleGetEventsForBubbleVariables = {
  pathParams: BubbleGetEventsForBubblePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetEventsForBubble = (variables: BubbleGetEventsForBubbleVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetEventsForBubbleResponse,
    BubbleGetEventsForBubbleError,
    undefined,
    {},
    {},
    BubbleGetEventsForBubblePathParams
  >({ method: 'get', url: '/api/v1/Bubbles/{bubbleId}/events', ...variables, signal });

export const useBubbleGetEventsForBubble = <TData = BubbleGetEventsForBubbleResponse>(
  variables: BubbleGetEventsForBubbleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetEventsForBubbleResponse, BubbleGetEventsForBubbleError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetEventsForBubbleResponse, BubbleGetEventsForBubbleError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetEventsForBubble({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetEventsForBubble',
      path: '/api/v1/Bubbles/{bubbleId}/events',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleUploadBubblePicturePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleUploadBubblePictureError = Fetcher.ErrorWrapper<undefined>;

export type BubbleUploadBubblePictureRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type BubbleUploadBubblePictureVariables = {
  body?: BubbleUploadBubblePictureRequestBody;
  pathParams: BubbleUploadBubblePicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleUploadBubblePicture = (variables: BubbleUploadBubblePictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    BubbleUploadBubblePictureError,
    BubbleUploadBubblePictureRequestBody,
    {},
    {},
    BubbleUploadBubblePicturePathParams
  >({ method: 'put', url: '/api/v1/Bubbles/{bubbleId}/picture', ...variables, signal });

export const useBubbleUploadBubblePicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, BubbleUploadBubblePictureError, BubbleUploadBubblePictureVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, BubbleUploadBubblePictureError, BubbleUploadBubblePictureVariables>({
    mutationFn: (variables: BubbleUploadBubblePictureVariables) =>
      fetchBubbleUploadBubblePicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleGetBubblePicturePathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetBubblePictureError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetBubblePictureVariables = {
  pathParams: BubbleGetBubblePicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetBubblePicture = (variables: BubbleGetBubblePictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, BubbleGetBubblePictureError, undefined, {}, {}, BubbleGetBubblePicturePathParams>({
    method: 'get',
    url: '/api/v1/Bubbles/{bubbleId}/picture',
    ...variables,
    signal,
  });

export const useBubbleGetBubblePicture = <TData = undefined>(
  variables: BubbleGetBubblePictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, BubbleGetBubblePictureError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, BubbleGetBubblePictureError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetBubblePicture({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetBubblePicture',
      path: '/api/v1/Bubbles/{bubbleId}/picture',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleGetDynamicStatsPathParams = {
  /**
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetDynamicStatsError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetDynamicStatsVariables = {
  body?: Schemas.DynamicStatsRequest;
  pathParams: BubbleGetDynamicStatsPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetDynamicStats = (variables: BubbleGetDynamicStatsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    Schemas.DynamicStatsDto,
    BubbleGetDynamicStatsError,
    Schemas.DynamicStatsRequest,
    {},
    {},
    BubbleGetDynamicStatsPathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/dynamic-stats', ...variables, signal });

export const useBubbleGetDynamicStats = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.DynamicStatsDto, BubbleGetDynamicStatsError, BubbleGetDynamicStatsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.DynamicStatsDto, BubbleGetDynamicStatsError, BubbleGetDynamicStatsVariables>({
    mutationFn: (variables: BubbleGetDynamicStatsVariables) =>
      fetchBubbleGetDynamicStats({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleSetCandidateAsFavoritePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type BubbleSetCandidateAsFavoriteQueryParams = {
  /**
   * Optional, defaults to true
   *
   * @default true
   */
  isFavorite?: boolean;
};

export type BubbleSetCandidateAsFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type BubbleSetCandidateAsFavoriteVariables = {
  pathParams: BubbleSetCandidateAsFavoritePathParams;
  queryParams?: BubbleSetCandidateAsFavoriteQueryParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleSetCandidateAsFavorite = (
  variables: BubbleSetCandidateAsFavoriteVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    BubbleSetCandidateAsFavoriteError,
    undefined,
    {},
    BubbleSetCandidateAsFavoriteQueryParams,
    BubbleSetCandidateAsFavoritePathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/candidates/{userId}/favorite', ...variables, signal });

export const useBubbleSetCandidateAsFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, BubbleSetCandidateAsFavoriteError, BubbleSetCandidateAsFavoriteVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, BubbleSetCandidateAsFavoriteError, BubbleSetCandidateAsFavoriteVariables>({
    mutationFn: (variables: BubbleSetCandidateAsFavoriteVariables) =>
      fetchBubbleSetCandidateAsFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BubbleGetCandidatesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type BubbleGetCandidatesError = Fetcher.ErrorWrapper<undefined>;

export type BubbleGetCandidatesResponse = Schemas.CandidateDto[];

export type BubbleGetCandidatesVariables = {
  pathParams: BubbleGetCandidatesPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleGetCandidates = (variables: BubbleGetCandidatesVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    BubbleGetCandidatesResponse,
    BubbleGetCandidatesError,
    undefined,
    {},
    {},
    BubbleGetCandidatesPathParams
  >({ method: 'get', url: '/api/v1/Bubbles/{bubbleId}/candidates', ...variables, signal });

export const useBubbleGetCandidates = <TData = BubbleGetCandidatesResponse>(
  variables: BubbleGetCandidatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<BubbleGetCandidatesResponse, BubbleGetCandidatesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<BubbleGetCandidatesResponse, BubbleGetCandidatesError, TData>({
    queryFn: ({ signal }) => fetchBubbleGetCandidates({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'bubbleGetCandidates',
      path: '/api/v1/Bubbles/{bubbleId}/candidates',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type BubbleCreateInterviewEventPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type BubbleCreateInterviewEventError = Fetcher.ErrorWrapper<undefined>;

export type BubbleCreateInterviewEventVariables = {
  pathParams: BubbleCreateInterviewEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchBubbleCreateInterviewEvent = (variables: BubbleCreateInterviewEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<string, BubbleCreateInterviewEventError, undefined, {}, {}, BubbleCreateInterviewEventPathParams>({
    method: 'post',
    url: '/api/v1/Bubbles/{bubbleId}/candidates/{userId}/interview',
    ...variables,
    signal,
  });

export const useBubbleCreateInterviewEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, BubbleCreateInterviewEventError, BubbleCreateInterviewEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, BubbleCreateInterviewEventError, BubbleCreateInterviewEventVariables>({
    mutationFn: (variables: BubbleCreateInterviewEventVariables) =>
      fetchBubbleCreateInterviewEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CalendarGetUserCalendarError = Fetcher.ErrorWrapper<undefined>;

export type CalendarGetUserCalendarVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchCalendarGetUserCalendar = (variables: CalendarGetUserCalendarVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, CalendarGetUserCalendarError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/v1/Calendar',
    ...variables,
    signal,
  });

export const useCalendarGetUserCalendar = <TData = undefined>(
  variables: CalendarGetUserCalendarVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, CalendarGetUserCalendarError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, CalendarGetUserCalendarError, TData>({
    queryFn: ({ signal }) => fetchCalendarGetUserCalendar({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'calendarGetUserCalendar', path: '/api/v1/Calendar', variables }),
    ...options,
    ...queryOptions,
  });
};

export type EmailBulkEmailUsersError = Fetcher.ErrorWrapper<undefined>;

export type EmailBulkEmailUsersVariables = {
  body?: Schemas.BulkEmailDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEmailBulkEmailUsers = (variables: EmailBulkEmailUsersVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EmailBulkEmailUsersError, Schemas.BulkEmailDto, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/email/bulk',
    ...variables,
    signal,
  });

export const useEmailBulkEmailUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EmailBulkEmailUsersError, EmailBulkEmailUsersVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EmailBulkEmailUsersError, EmailBulkEmailUsersVariables>({
    mutationFn: (variables: EmailBulkEmailUsersVariables) =>
      fetchEmailBulkEmailUsers({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EndToEndSeedDatabaseError = Fetcher.ErrorWrapper<undefined>;

export type EndToEndSeedDatabaseVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchEndToEndSeedDatabase = (variables: EndToEndSeedDatabaseVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.SeedDbResult, EndToEndSeedDatabaseError, undefined, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/e2e/seed-db',
    ...variables,
    signal,
  });

export const useEndToEndSeedDatabase = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.SeedDbResult, EndToEndSeedDatabaseError, EndToEndSeedDatabaseVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.SeedDbResult, EndToEndSeedDatabaseError, EndToEndSeedDatabaseVariables>({
    mutationFn: (variables: EndToEndSeedDatabaseVariables) =>
      fetchEndToEndSeedDatabase({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventGetEventPathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  eventId: string;
};

export type EventGetEventError = Fetcher.ErrorWrapper<undefined>;

export type EventGetEventVariables = {
  pathParams: EventGetEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventGetEvent = (variables: EventGetEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventGetEventError, undefined, {}, {}, EventGetEventPathParams>({
    method: 'get',
    url: '/api/v1/event/{eventId}',
    ...variables,
    signal,
  });

export const useEventGetEvent = <TData = undefined>(
  variables: EventGetEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, EventGetEventError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, EventGetEventError, TData>({
    queryFn: ({ signal }) => fetchEventGetEvent({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'eventGetEvent', path: '/api/v1/event/{eventId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type EventDeleteEventPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventDeleteEventError = Fetcher.ErrorWrapper<undefined>;

export type EventDeleteEventVariables = {
  pathParams: EventDeleteEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDeleteEvent = (variables: EventDeleteEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDeleteEventError, undefined, {}, {}, EventDeleteEventPathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}',
    ...variables,
    signal,
  });

export const useEventDeleteEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDeleteEventError, EventDeleteEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDeleteEventError, EventDeleteEventVariables>({
    mutationFn: (variables: EventDeleteEventVariables) => fetchEventDeleteEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventUpdateEventPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventUpdateEventError = Fetcher.ErrorWrapper<undefined>;

export type EventUpdateEventVariables = {
  body: Schemas.UpdateEventRequest;
  pathParams: EventUpdateEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventUpdateEvent = (variables: EventUpdateEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    Schemas.EventDto,
    EventUpdateEventError,
    Schemas.UpdateEventRequest,
    {},
    {},
    EventUpdateEventPathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/update', ...variables, signal });

export const useEventUpdateEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.EventDto, EventUpdateEventError, EventUpdateEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.EventDto, EventUpdateEventError, EventUpdateEventVariables>({
    mutationFn: (variables: EventUpdateEventVariables) => fetchEventUpdateEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventCreateEventError = Fetcher.ErrorWrapper<undefined>;

export type EventCreateEventVariables = {
  body: Schemas.CreateEventRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventCreateEvent = (variables: EventCreateEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.EventDto, EventCreateEventError, Schemas.CreateEventRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/event',
    ...variables,
    signal,
  });

export const useEventCreateEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.EventDto, EventCreateEventError, EventCreateEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.EventDto, EventCreateEventError, EventCreateEventVariables>({
    mutationFn: (variables: EventCreateEventVariables) => fetchEventCreateEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventAddToEventPathParams = {
  /**
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventAddToEventError = Fetcher.ErrorWrapper<undefined>;

export type EventAddToEventVariables = {
  pathParams: EventAddToEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventAddToEvent = (variables: EventAddToEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventAddToEventError, undefined, {}, {}, EventAddToEventPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/add/{userId}',
    ...variables,
    signal,
  });

export const useEventAddToEvent = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, EventAddToEventError, EventAddToEventVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventAddToEventError, EventAddToEventVariables>({
    mutationFn: (variables: EventAddToEventVariables) => fetchEventAddToEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventAddToEventByEmailPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventAddToEventByEmailError = Fetcher.ErrorWrapper<undefined>;

export type EventAddToEventByEmailVariables = {
  body: Schemas.AddMemberRequest;
  pathParams: EventAddToEventByEmailPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventAddToEventByEmail = (variables: EventAddToEventByEmailVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    EventAddToEventByEmailError,
    Schemas.AddMemberRequest,
    {},
    {},
    EventAddToEventByEmailPathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/add-member-by-email', ...variables, signal });

export const useEventAddToEventByEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventAddToEventByEmailError, EventAddToEventByEmailVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventAddToEventByEmailError, EventAddToEventByEmailVariables>({
    mutationFn: (variables: EventAddToEventByEmailVariables) =>
      fetchEventAddToEventByEmail({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventRequestAddToEventPathParams = {
  /**
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventRequestAddToEventError = Fetcher.ErrorWrapper<undefined>;

export type EventRequestAddToEventVariables = {
  pathParams: EventRequestAddToEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventRequestAddToEvent = (variables: EventRequestAddToEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventRequestAddToEventError, undefined, {}, {}, EventRequestAddToEventPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/request/{userId}',
    ...variables,
    signal,
  });

export const useEventRequestAddToEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventRequestAddToEventError, EventRequestAddToEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventRequestAddToEventError, EventRequestAddToEventVariables>({
    mutationFn: (variables: EventRequestAddToEventVariables) =>
      fetchEventRequestAddToEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventCreateMeetingTimePathParams = {
  eventId: string;
};

export type EventCreateMeetingTimeError = Fetcher.ErrorWrapper<undefined>;

export type EventCreateMeetingTimeVariables = {
  body?: Schemas.CreateMeetingTimeRequest;
  pathParams: EventCreateMeetingTimePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventCreateMeetingTime = (variables: EventCreateMeetingTimeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    EventCreateMeetingTimeError,
    Schemas.CreateMeetingTimeRequest,
    {},
    {},
    EventCreateMeetingTimePathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/meeting-time', ...variables, signal });

export const useEventCreateMeetingTime = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventCreateMeetingTimeError, EventCreateMeetingTimeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventCreateMeetingTimeError, EventCreateMeetingTimeVariables>({
    mutationFn: (variables: EventCreateMeetingTimeVariables) =>
      fetchEventCreateMeetingTime({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventDenyRequestPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventDenyRequestError = Fetcher.ErrorWrapper<undefined>;

export type EventDenyRequestVariables = {
  pathParams: EventDenyRequestPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDenyRequest = (variables: EventDenyRequestVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDenyRequestError, undefined, {}, {}, EventDenyRequestPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/request/{userId}/deny',
    ...variables,
    signal,
  });

export const useEventDenyRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDenyRequestError, EventDenyRequestVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDenyRequestError, EventDenyRequestVariables>({
    mutationFn: (variables: EventDenyRequestVariables) => fetchEventDenyRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventRemoveFromEventPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventRemoveFromEventError = Fetcher.ErrorWrapper<undefined>;

export type EventRemoveFromEventVariables = {
  pathParams: EventRemoveFromEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventRemoveFromEvent = (variables: EventRemoveFromEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventRemoveFromEventError, undefined, {}, {}, EventRemoveFromEventPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/remove/{userId}',
    ...variables,
    signal,
  });

export const useEventRemoveFromEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventRemoveFromEventError, EventRemoveFromEventVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventRemoveFromEventError, EventRemoveFromEventVariables>({
    mutationFn: (variables: EventRemoveFromEventVariables) =>
      fetchEventRemoveFromEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventLeaveEventPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventLeaveEventError = Fetcher.ErrorWrapper<undefined>;

export type EventLeaveEventVariables = {
  pathParams: EventLeaveEventPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventLeaveEvent = (variables: EventLeaveEventVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventLeaveEventError, undefined, {}, {}, EventLeaveEventPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/leave',
    ...variables,
    signal,
  });

export const useEventLeaveEvent = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, EventLeaveEventError, EventLeaveEventVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventLeaveEventError, EventLeaveEventVariables>({
    mutationFn: (variables: EventLeaveEventVariables) => fetchEventLeaveEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventDeleteMeetingTimePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
};

export type EventDeleteMeetingTimeError = Fetcher.ErrorWrapper<undefined>;

export type EventDeleteMeetingTimeVariables = {
  pathParams: EventDeleteMeetingTimePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDeleteMeetingTime = (variables: EventDeleteMeetingTimeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDeleteMeetingTimeError, undefined, {}, {}, EventDeleteMeetingTimePathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}/meeting-time/{meetingId}',
    ...variables,
    signal,
  });

export const useEventDeleteMeetingTime = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDeleteMeetingTimeError, EventDeleteMeetingTimeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDeleteMeetingTimeError, EventDeleteMeetingTimeVariables>({
    mutationFn: (variables: EventDeleteMeetingTimeVariables) =>
      fetchEventDeleteMeetingTime({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventUpdateMeetingTimePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
  eventId: string;
};

export type EventUpdateMeetingTimeError = Fetcher.ErrorWrapper<undefined>;

export type EventUpdateMeetingTimeVariables = {
  body: Schemas.UpdateMeetingTimeDto;
  pathParams: EventUpdateMeetingTimePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventUpdateMeetingTime = (variables: EventUpdateMeetingTimeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    EventUpdateMeetingTimeError,
    Schemas.UpdateMeetingTimeDto,
    {},
    {},
    EventUpdateMeetingTimePathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/meeting-time/{meetingId}', ...variables, signal });

export const useEventUpdateMeetingTime = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventUpdateMeetingTimeError, EventUpdateMeetingTimeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventUpdateMeetingTimeError, EventUpdateMeetingTimeVariables>({
    mutationFn: (variables: EventUpdateMeetingTimeVariables) =>
      fetchEventUpdateMeetingTime({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventAddRsvpPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
  eventId: string;
};

export type EventAddRsvpError = Fetcher.ErrorWrapper<undefined>;

export type EventAddRsvpVariables = {
  pathParams: EventAddRsvpPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventAddRsvp = (variables: EventAddRsvpVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventAddRsvpError, undefined, {}, {}, EventAddRsvpPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/meeting-time/{meetingId}/rsvp',
    ...variables,
    signal,
  });

export const useEventAddRsvp = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, EventAddRsvpError, EventAddRsvpVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventAddRsvpError, EventAddRsvpVariables>({
    mutationFn: (variables: EventAddRsvpVariables) => fetchEventAddRsvp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventRemoveRsvpPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
  eventId: string;
};

export type EventRemoveRsvpError = Fetcher.ErrorWrapper<undefined>;

export type EventRemoveRsvpVariables = {
  pathParams: EventRemoveRsvpPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventRemoveRsvp = (variables: EventRemoveRsvpVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventRemoveRsvpError, undefined, {}, {}, EventRemoveRsvpPathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}/meeting-time/{meetingId}/rsvp',
    ...variables,
    signal,
  });

export const useEventRemoveRsvp = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, EventRemoveRsvpError, EventRemoveRsvpVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventRemoveRsvpError, EventRemoveRsvpVariables>({
    mutationFn: (variables: EventRemoveRsvpVariables) => fetchEventRemoveRsvp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventMarkUserAsNoShowPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
  eventId: string;
};

export type EventMarkUserAsNoShowError = Fetcher.ErrorWrapper<undefined>;

export type EventMarkUserAsNoShowVariables = {
  pathParams: EventMarkUserAsNoShowPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventMarkUserAsNoShow = (variables: EventMarkUserAsNoShowVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventMarkUserAsNoShowError, undefined, {}, {}, EventMarkUserAsNoShowPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/meeting-time/{meetingId}/user/{userId}/no-show',
    ...variables,
    signal,
  });

export const useEventMarkUserAsNoShow = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventMarkUserAsNoShowError, EventMarkUserAsNoShowVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventMarkUserAsNoShowError, EventMarkUserAsNoShowVariables>({
    mutationFn: (variables: EventMarkUserAsNoShowVariables) =>
      fetchEventMarkUserAsNoShow({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventRemoveNoShowMarkPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
  eventId: string;
};

export type EventRemoveNoShowMarkError = Fetcher.ErrorWrapper<undefined>;

export type EventRemoveNoShowMarkVariables = {
  pathParams: EventRemoveNoShowMarkPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventRemoveNoShowMark = (variables: EventRemoveNoShowMarkVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventRemoveNoShowMarkError, undefined, {}, {}, EventRemoveNoShowMarkPathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}/meeting-time/{meetingId}/user/{userId}/no-show',
    ...variables,
    signal,
  });

export const useEventRemoveNoShowMark = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventRemoveNoShowMarkError, EventRemoveNoShowMarkVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventRemoveNoShowMarkError, EventRemoveNoShowMarkVariables>({
    mutationFn: (variables: EventRemoveNoShowMarkVariables) =>
      fetchEventRemoveNoShowMark({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventAddResourcePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventAddResourceError = Fetcher.ErrorWrapper<undefined>;

export type EventAddResourceVariables = {
  body: Schemas.ResourceDto;
  pathParams: EventAddResourcePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventAddResource = (variables: EventAddResourceVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<string, EventAddResourceError, Schemas.ResourceDto, {}, {}, EventAddResourcePathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/resource',
    ...variables,
    signal,
  });

export const useEventAddResource = (
  options?: Omit<reactQuery.UseMutationOptions<string, EventAddResourceError, EventAddResourceVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, EventAddResourceError, EventAddResourceVariables>({
    mutationFn: (variables: EventAddResourceVariables) => fetchEventAddResource({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventRemoveResourcePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  resourceId: string;
};

export type EventRemoveResourceError = Fetcher.ErrorWrapper<undefined>;

export type EventRemoveResourceVariables = {
  pathParams: EventRemoveResourcePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventRemoveResource = (variables: EventRemoveResourceVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventRemoveResourceError, undefined, {}, {}, EventRemoveResourcePathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}/resource/{resourceId}',
    ...variables,
    signal,
  });

export const useEventRemoveResource = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventRemoveResourceError, EventRemoveResourceVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventRemoveResourceError, EventRemoveResourceVariables>({
    mutationFn: (variables: EventRemoveResourceVariables) =>
      fetchEventRemoveResource({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventAddFacilitatorPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventAddFacilitatorError = Fetcher.ErrorWrapper<undefined>;

export type EventAddFacilitatorVariables = {
  pathParams: EventAddFacilitatorPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventAddFacilitator = (variables: EventAddFacilitatorVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventAddFacilitatorError, undefined, {}, {}, EventAddFacilitatorPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/facilitator/{userId}',
    ...variables,
    signal,
  });

export const useEventAddFacilitator = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventAddFacilitatorError, EventAddFacilitatorVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventAddFacilitatorError, EventAddFacilitatorVariables>({
    mutationFn: (variables: EventAddFacilitatorVariables) =>
      fetchEventAddFacilitator({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventDeleteFacilitatorPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventDeleteFacilitatorError = Fetcher.ErrorWrapper<undefined>;

export type EventDeleteFacilitatorVariables = {
  pathParams: EventDeleteFacilitatorPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDeleteFacilitator = (variables: EventDeleteFacilitatorVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDeleteFacilitatorError, undefined, {}, {}, EventDeleteFacilitatorPathParams>({
    method: 'delete',
    url: '/api/v1/event/{eventId}/facilitator/{userId}',
    ...variables,
    signal,
  });

export const useEventDeleteFacilitator = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDeleteFacilitatorError, EventDeleteFacilitatorVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDeleteFacilitatorError, EventDeleteFacilitatorVariables>({
    mutationFn: (variables: EventDeleteFacilitatorVariables) =>
      fetchEventDeleteFacilitator({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventCreateEventMilestonePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventCreateEventMilestoneError = Fetcher.ErrorWrapper<undefined>;

export type EventCreateEventMilestoneVariables = {
  body: Schemas.CreateEventMilestoneRequest;
  pathParams: EventCreateEventMilestonePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventCreateEventMilestone = (variables: EventCreateEventMilestoneVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    string,
    EventCreateEventMilestoneError,
    Schemas.CreateEventMilestoneRequest,
    {},
    {},
    EventCreateEventMilestonePathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/milestones', ...variables, signal });

export const useEventCreateEventMilestone = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, EventCreateEventMilestoneError, EventCreateEventMilestoneVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, EventCreateEventMilestoneError, EventCreateEventMilestoneVariables>({
    mutationFn: (variables: EventCreateEventMilestoneVariables) =>
      fetchEventCreateEventMilestone({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventDeleteEventMilestonePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  milestoneId: string;
};

export type EventDeleteEventMilestoneError = Fetcher.ErrorWrapper<undefined>;

export type EventDeleteEventMilestoneVariables = {
  pathParams: EventDeleteEventMilestonePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDeleteEventMilestone = (variables: EventDeleteEventMilestoneVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDeleteEventMilestoneError, undefined, {}, {}, EventDeleteEventMilestonePathParams>(
    { method: 'post', url: '/api/v1/event/{eventId}/milestones/{milestoneId}', ...variables, signal }
  );

export const useEventDeleteEventMilestone = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDeleteEventMilestoneError, EventDeleteEventMilestoneVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDeleteEventMilestoneError, EventDeleteEventMilestoneVariables>({
    mutationFn: (variables: EventDeleteEventMilestoneVariables) =>
      fetchEventDeleteEventMilestone({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventCreateEventTaskPathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventCreateEventTaskError = Fetcher.ErrorWrapper<undefined>;

export type EventCreateEventTaskVariables = {
  body: Schemas.CreateEventTaskRequest;
  pathParams: EventCreateEventTaskPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventCreateEventTask = (variables: EventCreateEventTaskVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    string,
    EventCreateEventTaskError,
    Schemas.CreateEventTaskRequest,
    {},
    {},
    EventCreateEventTaskPathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/tasks', ...variables, signal });

export const useEventCreateEventTask = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, EventCreateEventTaskError, EventCreateEventTaskVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, EventCreateEventTaskError, EventCreateEventTaskVariables>({
    mutationFn: (variables: EventCreateEventTaskVariables) =>
      fetchEventCreateEventTask({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventDeleteEventTaskPathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  taskId: string;
};

export type EventDeleteEventTaskError = Fetcher.ErrorWrapper<undefined>;

export type EventDeleteEventTaskVariables = {
  pathParams: EventDeleteEventTaskPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventDeleteEventTask = (variables: EventDeleteEventTaskVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventDeleteEventTaskError, undefined, {}, {}, EventDeleteEventTaskPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/tasks/{taskId}',
    ...variables,
    signal,
  });

export const useEventDeleteEventTask = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventDeleteEventTaskError, EventDeleteEventTaskVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventDeleteEventTaskError, EventDeleteEventTaskVariables>({
    mutationFn: (variables: EventDeleteEventTaskVariables) =>
      fetchEventDeleteEventTask({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventGetEventCheckInQrCodePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventGetEventCheckInQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type EventGetEventCheckInQrCodeVariables = {
  pathParams: EventGetEventCheckInQrCodePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventGetEventCheckInQrCode = (variables: EventGetEventCheckInQrCodeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<string, EventGetEventCheckInQrCodeError, undefined, {}, {}, EventGetEventCheckInQrCodePathParams>({
    method: 'get',
    url: '/api/v1/event/{eventId}/check-in.svg',
    ...variables,
    signal,
  });

export const useEventGetEventCheckInQrCode = <TData = string>(
  variables: EventGetEventCheckInQrCodeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, EventGetEventCheckInQrCodeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<string, EventGetEventCheckInQrCodeError, TData>({
    queryFn: ({ signal }) => fetchEventGetEventCheckInQrCode({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'eventGetEventCheckInQrCode',
      path: '/api/v1/event/{eventId}/check-in.svg',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type EventEventCheckInPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventEventCheckInError = Fetcher.ErrorWrapper<undefined>;

export type EventEventCheckInVariables = {
  pathParams: EventEventCheckInPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventEventCheckIn = (variables: EventEventCheckInVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventEventCheckInError, undefined, {}, {}, EventEventCheckInPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/check-in',
    ...variables,
    signal,
  });

export const useEventEventCheckIn = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventEventCheckInError, EventEventCheckInVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventEventCheckInError, EventEventCheckInVariables>({
    mutationFn: (variables: EventEventCheckInVariables) => fetchEventEventCheckIn({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventEventCheckInByIdentifierPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   */
  identifier: string;
};

export type EventEventCheckInByIdentifierError = Fetcher.ErrorWrapper<undefined>;

export type EventEventCheckInByIdentifierVariables = {
  pathParams: EventEventCheckInByIdentifierPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventEventCheckInByIdentifier = (
  variables: EventEventCheckInByIdentifierVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    EventEventCheckInByIdentifierError,
    undefined,
    {},
    {},
    EventEventCheckInByIdentifierPathParams
  >({ method: 'post', url: '/api/v1/event/{eventId}/check-in/{identifier}', ...variables, signal });

export const useEventEventCheckInByIdentifier = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EventEventCheckInByIdentifierError,
      EventEventCheckInByIdentifierVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventEventCheckInByIdentifierError, EventEventCheckInByIdentifierVariables>({
    mutationFn: (variables: EventEventCheckInByIdentifierVariables) =>
      fetchEventEventCheckInByIdentifier({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventUploadEventPicturePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventUploadEventPictureError = Fetcher.ErrorWrapper<undefined>;

export type EventUploadEventPictureRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type EventUploadEventPictureVariables = {
  body?: EventUploadEventPictureRequestBody;
  pathParams: EventUploadEventPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventUploadEventPicture = (variables: EventUploadEventPictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    EventUploadEventPictureError,
    EventUploadEventPictureRequestBody,
    {},
    {},
    EventUploadEventPicturePathParams
  >({ method: 'put', url: '/api/v1/event/{eventId}/picture', ...variables, signal });

export const useEventUploadEventPicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventUploadEventPictureError, EventUploadEventPictureVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventUploadEventPictureError, EventUploadEventPictureVariables>({
    mutationFn: (variables: EventUploadEventPictureVariables) =>
      fetchEventUploadEventPicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EventGetEventPicturePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  eventId: string;
};

export type EventGetEventPictureError = Fetcher.ErrorWrapper<undefined>;

export type EventGetEventPictureVariables = {
  pathParams: EventGetEventPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventGetEventPicture = (variables: EventGetEventPictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventGetEventPictureError, undefined, {}, {}, EventGetEventPicturePathParams>({
    method: 'get',
    url: '/api/v1/event/{eventId}/picture',
    ...variables,
    signal,
  });

export const useEventGetEventPicture = <TData = undefined>(
  variables: EventGetEventPictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, EventGetEventPictureError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, EventGetEventPictureError, TData>({
    queryFn: ({ signal }) => fetchEventGetEventPicture({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'eventGetEventPicture', path: '/api/v1/event/{eventId}/picture', variables }),
    ...options,
    ...queryOptions,
  });
};

export type EventChangeOwnershipPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  eventId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type EventChangeOwnershipError = Fetcher.ErrorWrapper<undefined>;

export type EventChangeOwnershipVariables = {
  pathParams: EventChangeOwnershipPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchEventChangeOwnership = (variables: EventChangeOwnershipVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, EventChangeOwnershipError, undefined, {}, {}, EventChangeOwnershipPathParams>({
    method: 'post',
    url: '/api/v1/event/{eventId}/owner/{userId}',
    ...variables,
    signal,
  });

export const useEventChangeOwnership = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, EventChangeOwnershipError, EventChangeOwnershipVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, EventChangeOwnershipError, EventChangeOwnershipVariables>({
    mutationFn: (variables: EventChangeOwnershipVariables) =>
      fetchEventChangeOwnership({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoginSendAuthCodeError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type LoginSendAuthCodeVariables = {
  body?: Schemas.LoginRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchLoginSendAuthCode = (variables: LoginSendAuthCodeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, LoginSendAuthCodeError, Schemas.LoginRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/Login',
    ...variables,
    signal,
  });

export const useLoginSendAuthCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, LoginSendAuthCodeError, LoginSendAuthCodeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, LoginSendAuthCodeError, LoginSendAuthCodeVariables>({
    mutationFn: (variables: LoginSendAuthCodeVariables) => fetchLoginSendAuthCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoginResendAuthCodeError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type LoginResendAuthCodeVariables = {
  body?: Schemas.LoginRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchLoginResendAuthCode = (variables: LoginResendAuthCodeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, LoginResendAuthCodeError, Schemas.LoginRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/Login/resend',
    ...variables,
    signal,
  });

export const useLoginResendAuthCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, LoginResendAuthCodeError, LoginResendAuthCodeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, LoginResendAuthCodeError, LoginResendAuthCodeVariables>({
    mutationFn: (variables: LoginResendAuthCodeVariables) =>
      fetchLoginResendAuthCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoginVerifyAuthenticationError = Fetcher.ErrorWrapper<undefined>;

export type LoginVerifyAuthenticationVariables = {
  body?: Schemas.VerifyLoginRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchLoginVerifyAuthentication = (variables: LoginVerifyAuthenticationVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.VerificationDto, LoginVerifyAuthenticationError, Schemas.VerifyLoginRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/Login/verify',
    ...variables,
    signal,
  });

export const useLoginVerifyAuthentication = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VerificationDto,
      LoginVerifyAuthenticationError,
      LoginVerifyAuthenticationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    Schemas.VerificationDto,
    LoginVerifyAuthenticationError,
    LoginVerifyAuthenticationVariables
  >({
    mutationFn: (variables: LoginVerifyAuthenticationVariables) =>
      fetchLoginVerifyAuthentication({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoginWhoAmIError = Fetcher.ErrorWrapper<undefined>;

export type LoginWhoAmIVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchLoginWhoAmI = (variables: LoginWhoAmIVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.ProfileDto, LoginWhoAmIError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/v1/Login/whoami',
    ...variables,
    signal,
  });

export const useLoginWhoAmI = <TData = Schemas.ProfileDto>(
  variables: LoginWhoAmIVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProfileDto, LoginWhoAmIError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.ProfileDto, LoginWhoAmIError, TData>({
    queryFn: ({ signal }) => fetchLoginWhoAmI({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'loginWhoAmI', path: '/api/v1/Login/whoami', variables }),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationCreateOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationCreateOrganizationVariables = {
  body?: Schemas.CreateOrganizationDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationCreateOrganization = (
  variables: OrganizationCreateOrganizationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<string, OrganizationCreateOrganizationError, Schemas.CreateOrganizationDto, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/Organization',
    ...variables,
    signal,
  });

export const useOrganizationCreateOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, OrganizationCreateOrganizationError, OrganizationCreateOrganizationVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, OrganizationCreateOrganizationError, OrganizationCreateOrganizationVariables>({
    mutationFn: (variables: OrganizationCreateOrganizationVariables) =>
      fetchOrganizationCreateOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationGetOrganizationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationGetOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationGetOrganizationVariables = {
  pathParams: OrganizationGetOrganizationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationGetOrganization = (
  variables: OrganizationGetOrganizationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    Schemas.OrganizationSummary,
    OrganizationGetOrganizationError,
    undefined,
    {},
    {},
    OrganizationGetOrganizationPathParams
  >({ method: 'get', url: '/api/v1/Organization/{organizationId}', ...variables, signal });

export const useOrganizationGetOrganization = <TData = Schemas.OrganizationSummary>(
  variables: OrganizationGetOrganizationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationSummary, OrganizationGetOrganizationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.OrganizationSummary, OrganizationGetOrganizationError, TData>({
    queryFn: ({ signal }) => fetchOrganizationGetOrganization({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'organizationGetOrganization',
      path: '/api/v1/Organization/{organizationId}',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationDeleteOrganizationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationDeleteOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationDeleteOrganizationVariables = {
  pathParams: OrganizationDeleteOrganizationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationDeleteOrganization = (
  variables: OrganizationDeleteOrganizationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    OrganizationDeleteOrganizationError,
    undefined,
    {},
    {},
    OrganizationDeleteOrganizationPathParams
  >({ method: 'delete', url: '/api/v1/Organization/{organizationId}', ...variables, signal });

export const useOrganizationDeleteOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationDeleteOrganizationError,
      OrganizationDeleteOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationDeleteOrganizationError,
    OrganizationDeleteOrganizationVariables
  >({
    mutationFn: (variables: OrganizationDeleteOrganizationVariables) =>
      fetchOrganizationDeleteOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationUploadOrganizationPicturePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationUploadOrganizationPictureError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationUploadOrganizationPictureRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type OrganizationUploadOrganizationPictureVariables = {
  body?: OrganizationUploadOrganizationPictureRequestBody;
  pathParams: OrganizationUploadOrganizationPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationUploadOrganizationPicture = (
  variables: OrganizationUploadOrganizationPictureVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    OrganizationUploadOrganizationPictureError,
    OrganizationUploadOrganizationPictureRequestBody,
    {},
    {},
    OrganizationUploadOrganizationPicturePathParams
  >({ method: 'put', url: '/api/v1/Organization/{organizationId}/picture', ...variables, signal });

export const useOrganizationUploadOrganizationPicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationUploadOrganizationPictureError,
      OrganizationUploadOrganizationPictureVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationUploadOrganizationPictureError,
    OrganizationUploadOrganizationPictureVariables
  >({
    mutationFn: (variables: OrganizationUploadOrganizationPictureVariables) =>
      fetchOrganizationUploadOrganizationPicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationGetOrganizationPicturePathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationGetOrganizationPictureError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationGetOrganizationPictureVariables = {
  pathParams: OrganizationGetOrganizationPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationGetOrganizationPicture = (
  variables: OrganizationGetOrganizationPictureVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    OrganizationGetOrganizationPictureError,
    undefined,
    {},
    {},
    OrganizationGetOrganizationPicturePathParams
  >({ method: 'get', url: '/api/v1/Organization/{organizationId}/picture', ...variables, signal });

export const useOrganizationGetOrganizationPicture = <TData = undefined>(
  variables: OrganizationGetOrganizationPictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, OrganizationGetOrganizationPictureError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, OrganizationGetOrganizationPictureError, TData>({
    queryFn: ({ signal }) => fetchOrganizationGetOrganizationPicture({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'organizationGetOrganizationPicture',
      path: '/api/v1/Organization/{organizationId}/picture',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationAddExistingUserToOrganizationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type OrganizationAddExistingUserToOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationAddExistingUserToOrganizationVariables = {
  pathParams: OrganizationAddExistingUserToOrganizationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationAddExistingUserToOrganization = (
  variables: OrganizationAddExistingUserToOrganizationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    OrganizationAddExistingUserToOrganizationError,
    undefined,
    {},
    {},
    OrganizationAddExistingUserToOrganizationPathParams
  >({ method: 'post', url: '/api/v1/Organization/{organizationId}/user/{userId}', ...variables, signal });

export const useOrganizationAddExistingUserToOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationAddExistingUserToOrganizationError,
      OrganizationAddExistingUserToOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationAddExistingUserToOrganizationError,
    OrganizationAddExistingUserToOrganizationVariables
  >({
    mutationFn: (variables: OrganizationAddExistingUserToOrganizationVariables) =>
      fetchOrganizationAddExistingUserToOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationAddNewUserToOrganizationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationAddNewUserToOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationAddNewUserToOrganizationVariables = {
  body?: Schemas.AddUserToOrgDto;
  pathParams: OrganizationAddNewUserToOrganizationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationAddNewUserToOrganization = (
  variables: OrganizationAddNewUserToOrganizationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    string,
    OrganizationAddNewUserToOrganizationError,
    Schemas.AddUserToOrgDto,
    {},
    {},
    OrganizationAddNewUserToOrganizationPathParams
  >({ method: 'post', url: '/api/v1/Organization/{organizationId}/user', ...variables, signal });

export const useOrganizationAddNewUserToOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      OrganizationAddNewUserToOrganizationError,
      OrganizationAddNewUserToOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    string,
    OrganizationAddNewUserToOrganizationError,
    OrganizationAddNewUserToOrganizationVariables
  >({
    mutationFn: (variables: OrganizationAddNewUserToOrganizationVariables) =>
      fetchOrganizationAddNewUserToOrganization({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationAddOrganizationDonationPathParams = {
  organizationId: string;
};

export type OrganizationAddOrganizationDonationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationAddOrganizationDonationVariables = {
  body: Schemas.OrgDonationDto;
  pathParams: OrganizationAddOrganizationDonationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationAddOrganizationDonation = (
  variables: OrganizationAddOrganizationDonationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    string,
    OrganizationAddOrganizationDonationError,
    Schemas.OrgDonationDto,
    {},
    {},
    OrganizationAddOrganizationDonationPathParams
  >({ method: 'post', url: '/api/v1/Organization/{organizationId}/donation', ...variables, signal });

export const useOrganizationAddOrganizationDonation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      OrganizationAddOrganizationDonationError,
      OrganizationAddOrganizationDonationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    string,
    OrganizationAddOrganizationDonationError,
    OrganizationAddOrganizationDonationVariables
  >({
    mutationFn: (variables: OrganizationAddOrganizationDonationVariables) =>
      fetchOrganizationAddOrganizationDonation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationDeleteOrganizationDonationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  donationId: string;
  organizationId: string;
};

export type OrganizationDeleteOrganizationDonationError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationDeleteOrganizationDonationVariables = {
  pathParams: OrganizationDeleteOrganizationDonationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationDeleteOrganizationDonation = (
  variables: OrganizationDeleteOrganizationDonationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    OrganizationDeleteOrganizationDonationError,
    undefined,
    {},
    {},
    OrganizationDeleteOrganizationDonationPathParams
  >({ method: 'post', url: '/api/v1/Organization/{organizationId}/donation/{donationId}', ...variables, signal });

export const useOrganizationDeleteOrganizationDonation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationDeleteOrganizationDonationError,
      OrganizationDeleteOrganizationDonationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationDeleteOrganizationDonationError,
    OrganizationDeleteOrganizationDonationVariables
  >({
    mutationFn: (variables: OrganizationDeleteOrganizationDonationVariables) =>
      fetchOrganizationDeleteOrganizationDonation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationGetOrganizationDonationsPathParams = {
  /**
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationGetOrganizationDonationsError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationGetOrganizationDonationsResponse = Schemas.OrgDonationDto[];

export type OrganizationGetOrganizationDonationsVariables = {
  pathParams: OrganizationGetOrganizationDonationsPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationGetOrganizationDonations = (
  variables: OrganizationGetOrganizationDonationsVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    OrganizationGetOrganizationDonationsResponse,
    OrganizationGetOrganizationDonationsError,
    undefined,
    {},
    {},
    OrganizationGetOrganizationDonationsPathParams
  >({ method: 'get', url: '/api/v1/Organization/{organizationId}/donations', ...variables, signal });

export const useOrganizationGetOrganizationDonations = <TData = OrganizationGetOrganizationDonationsResponse>(
  variables: OrganizationGetOrganizationDonationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationGetOrganizationDonationsResponse,
      OrganizationGetOrganizationDonationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    OrganizationGetOrganizationDonationsResponse,
    OrganizationGetOrganizationDonationsError,
    TData
  >({
    queryFn: ({ signal }) => fetchOrganizationGetOrganizationDonations({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'organizationGetOrganizationDonations',
      path: '/api/v1/Organization/{organizationId}/donations',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationGetOrganizationMembersPathParams = {
  /**
   * @format uuid
   */
  organizationId: string;
};

export type OrganizationGetOrganizationMembersError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationGetOrganizationMembersResponse = Schemas.UserDto[];

export type OrganizationGetOrganizationMembersVariables = {
  pathParams: OrganizationGetOrganizationMembersPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationGetOrganizationMembers = (
  variables: OrganizationGetOrganizationMembersVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    OrganizationGetOrganizationMembersResponse,
    OrganizationGetOrganizationMembersError,
    undefined,
    {},
    {},
    OrganizationGetOrganizationMembersPathParams
  >({ method: 'get', url: '/api/v1/Organization/{organizationId}/members', ...variables, signal });

export const useOrganizationGetOrganizationMembers = <TData = OrganizationGetOrganizationMembersResponse>(
  variables: OrganizationGetOrganizationMembersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationGetOrganizationMembersResponse,
      OrganizationGetOrganizationMembersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    OrganizationGetOrganizationMembersResponse,
    OrganizationGetOrganizationMembersError,
    TData
  >({
    queryFn: ({ signal }) => fetchOrganizationGetOrganizationMembers({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'organizationGetOrganizationMembers',
      path: '/api/v1/Organization/{organizationId}/members',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationGetOrganizationRolesError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationGetOrganizationRolesResponse = string[];

export type OrganizationGetOrganizationRolesVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchOrganizationGetOrganizationRoles = (
  variables: OrganizationGetOrganizationRolesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    OrganizationGetOrganizationRolesResponse,
    OrganizationGetOrganizationRolesError,
    undefined,
    {},
    {},
    {}
  >({ method: 'get', url: '/api/v1/Organization/roles', ...variables, signal });

export const useOrganizationGetOrganizationRoles = <TData = OrganizationGetOrganizationRolesResponse>(
  variables: OrganizationGetOrganizationRolesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OrganizationGetOrganizationRolesResponse, OrganizationGetOrganizationRolesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<OrganizationGetOrganizationRolesResponse, OrganizationGetOrganizationRolesError, TData>({
    queryFn: ({ signal }) => fetchOrganizationGetOrganizationRoles({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'organizationGetOrganizationRoles',
      path: '/api/v1/Organization/roles',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type PaymentCreatePaymentAccountPathParams = {
  /**
   * current logged in user
   *
   * @format uuid
   */
  userId: string;
};

export type PaymentCreatePaymentAccountError = Fetcher.ErrorWrapper<undefined>;

export type PaymentCreatePaymentAccountVariables = {
  pathParams: PaymentCreatePaymentAccountPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchPaymentCreatePaymentAccount = (
  variables: PaymentCreatePaymentAccountVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    Schemas.UrlResult,
    PaymentCreatePaymentAccountError,
    undefined,
    {},
    {},
    PaymentCreatePaymentAccountPathParams
  >({ method: 'post', url: '/api/v1/Profile/{userId}/payment-account', ...variables, signal });

export const usePaymentCreatePaymentAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UrlResult,
      PaymentCreatePaymentAccountError,
      PaymentCreatePaymentAccountVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    Schemas.UrlResult,
    PaymentCreatePaymentAccountError,
    PaymentCreatePaymentAccountVariables
  >({
    mutationFn: (variables: PaymentCreatePaymentAccountVariables) =>
      fetchPaymentCreatePaymentAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PaymentGetPaymentAccountPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type PaymentGetPaymentAccountError = Fetcher.ErrorWrapper<undefined>;

export type PaymentGetPaymentAccountVariables = {
  pathParams: PaymentGetPaymentAccountPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchPaymentGetPaymentAccount = (variables: PaymentGetPaymentAccountVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    Schemas.PaymentAccountStatusDto,
    PaymentGetPaymentAccountError,
    undefined,
    {},
    {},
    PaymentGetPaymentAccountPathParams
  >({ method: 'get', url: '/api/v1/Profile/{userId}/payment-account', ...variables, signal });

export const usePaymentGetPaymentAccount = <TData = Schemas.PaymentAccountStatusDto>(
  variables: PaymentGetPaymentAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PaymentAccountStatusDto, PaymentGetPaymentAccountError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.PaymentAccountStatusDto, PaymentGetPaymentAccountError, TData>({
    queryFn: ({ signal }) => fetchPaymentGetPaymentAccount({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'paymentGetPaymentAccount',
      path: '/api/v1/Profile/{userId}/payment-account',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type PaymentGetStripeLoginPathParams = {
  /**
   * current logged in user
   *
   * @format uuid
   */
  userId: string;
};

export type PaymentGetStripeLoginError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.ProblemDetails;
    }
>;

export type PaymentGetStripeLoginVariables = {
  pathParams: PaymentGetStripeLoginPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchPaymentGetStripeLogin = (variables: PaymentGetStripeLoginVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.UrlResult, PaymentGetStripeLoginError, undefined, {}, {}, PaymentGetStripeLoginPathParams>(
    { method: 'get', url: '/api/v1/Profile/{userId}/payment-account/login', ...variables, signal }
  );

export const usePaymentGetStripeLogin = <TData = Schemas.UrlResult>(
  variables: PaymentGetStripeLoginVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UrlResult, PaymentGetStripeLoginError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.UrlResult, PaymentGetStripeLoginError, TData>({
    queryFn: ({ signal }) => fetchPaymentGetStripeLogin({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'paymentGetStripeLogin',
      path: '/api/v1/Profile/{userId}/payment-account/login',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type PaymentGetCheckoutSessionPathParams = {
  /**
   * current logged in user
   *
   * @format uuid
   */
  userId: string;
};

export type PaymentGetCheckoutSessionError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Schemas.ProblemDetails;
}>;

export type PaymentGetCheckoutSessionVariables = {
  body?: Schemas.PaymentSessionDto;
  pathParams: PaymentGetCheckoutSessionPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchPaymentGetCheckoutSession = (variables: PaymentGetCheckoutSessionVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    Schemas.UrlResult,
    PaymentGetCheckoutSessionError,
    Schemas.PaymentSessionDto,
    {},
    {},
    PaymentGetCheckoutSessionPathParams
  >({ method: 'post', url: '/api/v1/Profile/{userId}/payment-sessions', ...variables, signal });

export const usePaymentGetCheckoutSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UrlResult,
      PaymentGetCheckoutSessionError,
      PaymentGetCheckoutSessionVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.UrlResult, PaymentGetCheckoutSessionError, PaymentGetCheckoutSessionVariables>({
    mutationFn: (variables: PaymentGetCheckoutSessionVariables) =>
      fetchPaymentGetCheckoutSession({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProfileGetProfilePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ProfileGetProfileError = Fetcher.ErrorWrapper<undefined>;

export type ProfileGetProfileVariables = {
  pathParams: ProfileGetProfilePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchProfileGetProfile = (variables: ProfileGetProfileVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ProfileGetProfileError, undefined, {}, {}, ProfileGetProfilePathParams>({
    method: 'get',
    url: '/api/v1/Profile/{userId}',
    ...variables,
    signal,
  });

export const useProfileGetProfile = <TData = undefined>(
  variables: ProfileGetProfileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProfileGetProfileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, ProfileGetProfileError, TData>({
    queryFn: ({ signal }) => fetchProfileGetProfile({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'profileGetProfile', path: '/api/v1/Profile/{userId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type ProfileUploadProfilePicturePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ProfileUploadProfilePictureError = Fetcher.ErrorWrapper<undefined>;

export type ProfileUploadProfilePictureRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type ProfileUploadProfilePictureVariables = {
  body?: ProfileUploadProfilePictureRequestBody;
  pathParams: ProfileUploadProfilePicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchProfileUploadProfilePicture = (
  variables: ProfileUploadProfilePictureVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    ProfileUploadProfilePictureError,
    ProfileUploadProfilePictureRequestBody,
    {},
    {},
    ProfileUploadProfilePicturePathParams
  >({ method: 'put', url: '/api/v1/Profile/{userId}/picture', ...variables, signal });

export const useProfileUploadProfilePicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ProfileUploadProfilePictureError, ProfileUploadProfilePictureVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, ProfileUploadProfilePictureError, ProfileUploadProfilePictureVariables>({
    mutationFn: (variables: ProfileUploadProfilePictureVariables) =>
      fetchProfileUploadProfilePicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProfileGetProfilePicturePathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  userId: string;
};

export type ProfileGetProfilePictureError = Fetcher.ErrorWrapper<undefined>;

export type ProfileGetProfilePictureVariables = {
  pathParams: ProfileGetProfilePicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchProfileGetProfilePicture = (variables: ProfileGetProfilePictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ProfileGetProfilePictureError, undefined, {}, {}, ProfileGetProfilePicturePathParams>({
    method: 'get',
    url: '/api/v1/Profile/{userId}/picture',
    ...variables,
    signal,
  });

export const useProfileGetProfilePicture = <TData = undefined>(
  variables: ProfileGetProfilePictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProfileGetProfilePictureError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, ProfileGetProfilePictureError, TData>({
    queryFn: ({ signal }) => fetchProfileGetProfilePicture({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'profileGetProfilePicture',
      path: '/api/v1/Profile/{userId}/picture',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type ProfileUpdatePersonalInformationPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ProfileUpdatePersonalInformationError = Fetcher.ErrorWrapper<undefined>;

export type ProfileUpdatePersonalInformationVariables = {
  body: Schemas.PersonalInfoUpdateRequest;
  pathParams: ProfileUpdatePersonalInformationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchProfileUpdatePersonalInformation = (
  variables: ProfileUpdatePersonalInformationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    ProfileUpdatePersonalInformationError,
    Schemas.PersonalInfoUpdateRequest,
    {},
    {},
    ProfileUpdatePersonalInformationPathParams
  >({ method: 'put', url: '/api/v1/Profile/{userId}/personal-information', ...variables, signal });

export const useProfileUpdatePersonalInformation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProfileUpdatePersonalInformationError,
      ProfileUpdatePersonalInformationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    ProfileUpdatePersonalInformationError,
    ProfileUpdatePersonalInformationVariables
  >({
    mutationFn: (variables: ProfileUpdatePersonalInformationVariables) =>
      fetchProfileUpdatePersonalInformation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProfileUpdateAddressPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ProfileUpdateAddressError = Fetcher.ErrorWrapper<undefined>;

export type ProfileUpdateAddressVariables = {
  body: Schemas.AddressDto;
  pathParams: ProfileUpdateAddressPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchProfileUpdateAddress = (variables: ProfileUpdateAddressVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ProfileUpdateAddressError, Schemas.AddressDto, {}, {}, ProfileUpdateAddressPathParams>({
    method: 'put',
    url: '/api/v1/Profile/{userId}/address',
    ...variables,
    signal,
  });

export const useProfileUpdateAddress = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ProfileUpdateAddressError, ProfileUpdateAddressVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, ProfileUpdateAddressError, ProfileUpdateAddressVariables>({
    mutationFn: (variables: ProfileUpdateAddressVariables) =>
      fetchProfileUpdateAddress({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProfileGetStatesError = Fetcher.ErrorWrapper<undefined>;

export type ProfileGetStatesResponse = string[];

export type ProfileGetStatesVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchProfileGetStates = (variables: ProfileGetStatesVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<ProfileGetStatesResponse, ProfileGetStatesError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/v1/Profile/states',
    ...variables,
    signal,
  });

export const useProfileGetStates = <TData = ProfileGetStatesResponse>(
  variables: ProfileGetStatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProfileGetStatesResponse, ProfileGetStatesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<ProfileGetStatesResponse, ProfileGetStatesError, TData>({
    queryFn: ({ signal }) => fetchProfileGetStates({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'profileGetStates', path: '/api/v1/Profile/states', variables }),
    ...options,
    ...queryOptions,
  });
};

export type ReportReportPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  offender: string;
};

export type ReportReportError = Fetcher.ErrorWrapper<undefined>;

export type ReportReportVariables = {
  body?: Schemas.ReportDto;
  pathParams: ReportReportPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchReportReport = (variables: ReportReportVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ReportReportError, Schemas.ReportDto, {}, {}, ReportReportPathParams>({
    method: 'post',
    url: '/api/Report/{offender}',
    ...variables,
    signal,
  });

export const useReportReport = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ReportReportError, ReportReportVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, ReportReportError, ReportReportVariables>({
    mutationFn: (variables: ReportReportVariables) => fetchReportReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReportGetCategoriesError = Fetcher.ErrorWrapper<undefined>;

export type ReportGetCategoriesResponse = string[];

export type ReportGetCategoriesVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchReportGetCategories = (variables: ReportGetCategoriesVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<ReportGetCategoriesResponse, ReportGetCategoriesError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/Report/categories',
    ...variables,
    signal,
  });

export const useReportGetCategories = <TData = ReportGetCategoriesResponse>(
  variables: ReportGetCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ReportGetCategoriesResponse, ReportGetCategoriesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<ReportGetCategoriesResponse, ReportGetCategoriesError, TData>({
    queryFn: ({ signal }) => fetchReportGetCategories({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'reportGetCategories', path: '/api/Report/categories', variables }),
    ...options,
    ...queryOptions,
  });
};

export type ReportGetUnresolvedReportsError = Fetcher.ErrorWrapper<undefined>;

export type ReportGetUnresolvedReportsResponse = Schemas.ReportDto[];

export type ReportGetUnresolvedReportsVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchReportGetUnresolvedReports = (variables: ReportGetUnresolvedReportsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<ReportGetUnresolvedReportsResponse, ReportGetUnresolvedReportsError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/Report/unresolved',
    ...variables,
    signal,
  });

export const useReportGetUnresolvedReports = <TData = ReportGetUnresolvedReportsResponse>(
  variables: ReportGetUnresolvedReportsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ReportGetUnresolvedReportsResponse, ReportGetUnresolvedReportsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<ReportGetUnresolvedReportsResponse, ReportGetUnresolvedReportsError, TData>({
    queryFn: ({ signal }) => fetchReportGetUnresolvedReports({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'reportGetUnresolvedReports', path: '/api/Report/unresolved', variables }),
    ...options,
    ...queryOptions,
  });
};

export type ReportAddModeratorResponseError = Fetcher.ErrorWrapper<undefined>;

export type ReportAddModeratorResponseVariables = {
  body?: Schemas.ReportDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchReportAddModeratorResponse = (variables: ReportAddModeratorResponseVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ReportAddModeratorResponseError, Schemas.ReportDto, {}, {}, {}>({
    method: 'put',
    url: '/api/Report/moderator-response',
    ...variables,
    signal,
  });

export const useReportAddModeratorResponse = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ReportAddModeratorResponseError, ReportAddModeratorResponseVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, ReportAddModeratorResponseError, ReportAddModeratorResponseVariables>({
    mutationFn: (variables: ReportAddModeratorResponseVariables) =>
      fetchReportAddModeratorResponse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResumeGetValidFileExtensionsError = Fetcher.ErrorWrapper<undefined>;

export type ResumeGetValidFileExtensionsResponse = string[];

export type ResumeGetValidFileExtensionsVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchResumeGetValidFileExtensions = (
  variables: ResumeGetValidFileExtensionsVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<ResumeGetValidFileExtensionsResponse, ResumeGetValidFileExtensionsError, undefined, {}, {}, {}>({
    method: 'get',
    url: '/api/Resume/valid-file-extensions',
    ...variables,
    signal,
  });

export const useResumeGetValidFileExtensions = <TData = ResumeGetValidFileExtensionsResponse>(
  variables: ResumeGetValidFileExtensionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ResumeGetValidFileExtensionsResponse, ResumeGetValidFileExtensionsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<ResumeGetValidFileExtensionsResponse, ResumeGetValidFileExtensionsError, TData>({
    queryFn: ({ signal }) => fetchResumeGetValidFileExtensions({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'resumeGetValidFileExtensions',
      path: '/api/Resume/valid-file-extensions',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type ResumeUpsertUserResumeError = Fetcher.ErrorWrapper<undefined>;

export type ResumeUpsertUserResumeVariables = {
  body?: Schemas.ResumeDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchResumeUpsertUserResume = (variables: ResumeUpsertUserResumeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, ResumeUpsertUserResumeError, Schemas.ResumeDto, {}, {}, {}>({
    method: 'put',
    url: '/api/Resume/update',
    ...variables,
    signal,
  });

export const useResumeUpsertUserResume = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ResumeUpsertUserResumeError, ResumeUpsertUserResumeVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, ResumeUpsertUserResumeError, ResumeUpsertUserResumeVariables>({
    mutationFn: (variables: ResumeUpsertUserResumeVariables) =>
      fetchResumeUpsertUserResume({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResumeGetUserResumePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ResumeGetUserResumeError = Fetcher.ErrorWrapper<undefined>;

export type ResumeGetUserResumeVariables = {
  pathParams: ResumeGetUserResumePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchResumeGetUserResume = (variables: ResumeGetUserResumeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.ResumeDto, ResumeGetUserResumeError, undefined, {}, {}, ResumeGetUserResumePathParams>({
    method: 'get',
    url: '/api/Resume/user/{userId}',
    ...variables,
    signal,
  });

export const useResumeGetUserResume = <TData = Schemas.ResumeDto>(
  variables: ResumeGetUserResumeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ResumeDto, ResumeGetUserResumeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.ResumeDto, ResumeGetUserResumeError, TData>({
    queryFn: ({ signal }) => fetchResumeGetUserResume({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'resumeGetUserResume', path: '/api/Resume/user/{userId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type ResumeParseFileIntoResumePathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type ResumeParseFileIntoResumeError = Fetcher.ErrorWrapper<undefined>;

export type ResumeParseFileIntoResumeRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type ResumeParseFileIntoResumeVariables = {
  body?: ResumeParseFileIntoResumeRequestBody;
  pathParams: ResumeParseFileIntoResumePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchResumeParseFileIntoResume = (variables: ResumeParseFileIntoResumeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    Schemas.ResumeDto,
    ResumeParseFileIntoResumeError,
    ResumeParseFileIntoResumeRequestBody,
    {},
    {},
    ResumeParseFileIntoResumePathParams
  >({ method: 'post', url: '/api/Resume/user/{userId}/parse-file', ...variables, signal });

export const useResumeParseFileIntoResume = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ResumeDto,
      ResumeParseFileIntoResumeError,
      ResumeParseFileIntoResumeVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.ResumeDto, ResumeParseFileIntoResumeError, ResumeParseFileIntoResumeVariables>({
    mutationFn: (variables: ResumeParseFileIntoResumeVariables) =>
      fetchResumeParseFileIntoResume({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomGetRoomPathParams = {
  /**
   * Required, non-null
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomGetRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomGetRoomVariables = {
  pathParams: RoomGetRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomGetRoom = (variables: RoomGetRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.RoomDto, RoomGetRoomError, undefined, {}, {}, RoomGetRoomPathParams>({
    method: 'get',
    url: '/api/v1/room/{roomId}',
    ...variables,
    signal,
  });

export const useRoomGetRoom = <TData = Schemas.RoomDto>(
  variables: RoomGetRoomVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.RoomDto, RoomGetRoomError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.RoomDto, RoomGetRoomError, TData>({
    queryFn: ({ signal }) => fetchRoomGetRoom({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'roomGetRoom', path: '/api/v1/room/{roomId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type RoomDeleteRoomPathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomDeleteRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomDeleteRoomVariables = {
  pathParams: RoomDeleteRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDeleteRoom = (variables: RoomDeleteRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomDeleteRoomError, undefined, {}, {}, RoomDeleteRoomPathParams>({
    method: 'delete',
    url: '/api/v1/room/{roomId}',
    ...variables,
    signal,
  });

export const useRoomDeleteRoom = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RoomDeleteRoomError, RoomDeleteRoomVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDeleteRoomError, RoomDeleteRoomVariables>({
    mutationFn: (variables: RoomDeleteRoomVariables) => fetchRoomDeleteRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomGetMyRoomsPathParams = {
  /**
   * Required, non-default
   *
   * @format uuid
   */
  userId: string;
};

export type RoomGetMyRoomsError = Fetcher.ErrorWrapper<undefined>;

export type RoomGetMyRoomsResponse = Schemas.RoomDto[];

export type RoomGetMyRoomsVariables = {
  pathParams: RoomGetMyRoomsPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomGetMyRooms = (variables: RoomGetMyRoomsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<RoomGetMyRoomsResponse, RoomGetMyRoomsError, undefined, {}, {}, RoomGetMyRoomsPathParams>({
    method: 'get',
    url: '/api/v1/room/my-rooms/{userId}',
    ...variables,
    signal,
  });

export const useRoomGetMyRooms = <TData = RoomGetMyRoomsResponse>(
  variables: RoomGetMyRoomsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<RoomGetMyRoomsResponse, RoomGetMyRoomsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<RoomGetMyRoomsResponse, RoomGetMyRoomsError, TData>({
    queryFn: ({ signal }) => fetchRoomGetMyRooms({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'roomGetMyRooms', path: '/api/v1/room/my-rooms/{userId}', variables }),
    ...options,
    ...queryOptions,
  });
};

export type RoomCreateRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomCreateRoomVariables = {
  body: Schemas.CreateRoomRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomCreateRoom = (variables: RoomCreateRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.RoomDto, RoomCreateRoomError, Schemas.CreateRoomRequest, {}, {}, {}>({
    method: 'post',
    url: '/api/v1/room',
    ...variables,
    signal,
  });

export const useRoomCreateRoom = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RoomDto, RoomCreateRoomError, RoomCreateRoomVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<Schemas.RoomDto, RoomCreateRoomError, RoomCreateRoomVariables>({
    mutationFn: (variables: RoomCreateRoomVariables) => fetchRoomCreateRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomUpdateRoomPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomUpdateRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomUpdateRoomVariables = {
  body: Schemas.UpdateRoomRequest;
  pathParams: RoomUpdateRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomUpdateRoom = (variables: RoomUpdateRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomUpdateRoomError, Schemas.UpdateRoomRequest, {}, {}, RoomUpdateRoomPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/update',
    ...variables,
    signal,
  });

export const useRoomUpdateRoom = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RoomUpdateRoomError, RoomUpdateRoomVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomUpdateRoomError, RoomUpdateRoomVariables>({
    mutationFn: (variables: RoomUpdateRoomVariables) => fetchRoomUpdateRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomAddToRoomPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomAddToRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomAddToRoomVariables = {
  pathParams: RoomAddToRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomAddToRoom = (variables: RoomAddToRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomAddToRoomError, undefined, {}, {}, RoomAddToRoomPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/add/{userId}',
    ...variables,
    signal,
  });

export const useRoomAddToRoom = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RoomAddToRoomError, RoomAddToRoomVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomAddToRoomError, RoomAddToRoomVariables>({
    mutationFn: (variables: RoomAddToRoomVariables) => fetchRoomAddToRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomAddToRoomByEmailPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomAddToRoomByEmailError = Fetcher.ErrorWrapper<undefined>;

export type RoomAddToRoomByEmailVariables = {
  body: Schemas.AddMemberRequest;
  pathParams: RoomAddToRoomByEmailPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomAddToRoomByEmail = (variables: RoomAddToRoomByEmailVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    RoomAddToRoomByEmailError,
    Schemas.AddMemberRequest,
    {},
    {},
    RoomAddToRoomByEmailPathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/add-member-by-email', ...variables, signal });

export const useRoomAddToRoomByEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomAddToRoomByEmailError, RoomAddToRoomByEmailVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomAddToRoomByEmailError, RoomAddToRoomByEmailVariables>({
    mutationFn: (variables: RoomAddToRoomByEmailVariables) =>
      fetchRoomAddToRoomByEmail({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomRequestAddToRoomPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomRequestAddToRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomRequestAddToRoomVariables = {
  pathParams: RoomRequestAddToRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomRequestAddToRoom = (variables: RoomRequestAddToRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomRequestAddToRoomError, undefined, {}, {}, RoomRequestAddToRoomPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/request/{userId}',
    ...variables,
    signal,
  });

export const useRoomRequestAddToRoom = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomRequestAddToRoomError, RoomRequestAddToRoomVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomRequestAddToRoomError, RoomRequestAddToRoomVariables>({
    mutationFn: (variables: RoomRequestAddToRoomVariables) =>
      fetchRoomRequestAddToRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomDenyRequestPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomDenyRequestError = Fetcher.ErrorWrapper<undefined>;

export type RoomDenyRequestVariables = {
  pathParams: RoomDenyRequestPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDenyRequest = (variables: RoomDenyRequestVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomDenyRequestError, undefined, {}, {}, RoomDenyRequestPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/request/{userId}/deny',
    ...variables,
    signal,
  });

export const useRoomDenyRequest = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RoomDenyRequestError, RoomDenyRequestVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDenyRequestError, RoomDenyRequestVariables>({
    mutationFn: (variables: RoomDenyRequestVariables) => fetchRoomDenyRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomRemoveFromRoomPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomRemoveFromRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomRemoveFromRoomVariables = {
  pathParams: RoomRemoveFromRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomRemoveFromRoom = (variables: RoomRemoveFromRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomRemoveFromRoomError, undefined, {}, {}, RoomRemoveFromRoomPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/remove/{userId}',
    ...variables,
    signal,
  });

export const useRoomRemoveFromRoom = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomRemoveFromRoomError, RoomRemoveFromRoomVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomRemoveFromRoomError, RoomRemoveFromRoomVariables>({
    mutationFn: (variables: RoomRemoveFromRoomVariables) =>
      fetchRoomRemoveFromRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomBlockFromRoomPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomBlockFromRoomError = Fetcher.ErrorWrapper<undefined>;

export type RoomBlockFromRoomVariables = {
  pathParams: RoomBlockFromRoomPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomBlockFromRoom = (variables: RoomBlockFromRoomVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomBlockFromRoomError, undefined, {}, {}, RoomBlockFromRoomPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/block/{userId}',
    ...variables,
    signal,
  });

export const useRoomBlockFromRoom = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomBlockFromRoomError, RoomBlockFromRoomVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomBlockFromRoomError, RoomBlockFromRoomVariables>({
    mutationFn: (variables: RoomBlockFromRoomVariables) => fetchRoomBlockFromRoom({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomAddFacilitatorPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomAddFacilitatorError = Fetcher.ErrorWrapper<undefined>;

export type RoomAddFacilitatorVariables = {
  pathParams: RoomAddFacilitatorPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomAddFacilitator = (variables: RoomAddFacilitatorVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomAddFacilitatorError, undefined, {}, {}, RoomAddFacilitatorPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/facilitator/{userId}',
    ...variables,
    signal,
  });

export const useRoomAddFacilitator = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomAddFacilitatorError, RoomAddFacilitatorVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomAddFacilitatorError, RoomAddFacilitatorVariables>({
    mutationFn: (variables: RoomAddFacilitatorVariables) =>
      fetchRoomAddFacilitator({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomDeleteFacilitatorPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomDeleteFacilitatorError = Fetcher.ErrorWrapper<undefined>;

export type RoomDeleteFacilitatorVariables = {
  pathParams: RoomDeleteFacilitatorPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDeleteFacilitator = (variables: RoomDeleteFacilitatorVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomDeleteFacilitatorError, undefined, {}, {}, RoomDeleteFacilitatorPathParams>({
    method: 'delete',
    url: '/api/v1/room/{roomId}/facilitator/{userId}',
    ...variables,
    signal,
  });

export const useRoomDeleteFacilitator = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomDeleteFacilitatorError, RoomDeleteFacilitatorVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDeleteFacilitatorError, RoomDeleteFacilitatorVariables>({
    mutationFn: (variables: RoomDeleteFacilitatorVariables) =>
      fetchRoomDeleteFacilitator({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomChangeRoomOwnershipPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type RoomChangeRoomOwnershipError = Fetcher.ErrorWrapper<undefined>;

export type RoomChangeRoomOwnershipVariables = {
  pathParams: RoomChangeRoomOwnershipPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomChangeRoomOwnership = (variables: RoomChangeRoomOwnershipVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomChangeRoomOwnershipError, undefined, {}, {}, RoomChangeRoomOwnershipPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/owner/{userId}',
    ...variables,
    signal,
  });

export const useRoomChangeRoomOwnership = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomChangeRoomOwnershipError, RoomChangeRoomOwnershipVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomChangeRoomOwnershipError, RoomChangeRoomOwnershipVariables>({
    mutationFn: (variables: RoomChangeRoomOwnershipVariables) =>
      fetchRoomChangeRoomOwnership({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomUploadRoomPicturePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomUploadRoomPictureError = Fetcher.ErrorWrapper<undefined>;

export type RoomUploadRoomPictureRequestBody = {
  /**
   * @format binary
   */
  FormFile?: Blob;
};

export type RoomUploadRoomPictureVariables = {
  body?: RoomUploadRoomPictureRequestBody;
  pathParams: RoomUploadRoomPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomUploadRoomPicture = (variables: RoomUploadRoomPictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    undefined,
    RoomUploadRoomPictureError,
    RoomUploadRoomPictureRequestBody,
    {},
    {},
    RoomUploadRoomPicturePathParams
  >({ method: 'put', url: '/api/v1/room/{roomId}/picture', ...variables, signal });

export const useRoomUploadRoomPicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomUploadRoomPictureError, RoomUploadRoomPictureVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomUploadRoomPictureError, RoomUploadRoomPictureVariables>({
    mutationFn: (variables: RoomUploadRoomPictureVariables) =>
      fetchRoomUploadRoomPicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomGetRoomPicturePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomGetRoomPictureError = Fetcher.ErrorWrapper<undefined>;

export type RoomGetRoomPictureVariables = {
  pathParams: RoomGetRoomPicturePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomGetRoomPicture = (variables: RoomGetRoomPictureVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomGetRoomPictureError, undefined, {}, {}, RoomGetRoomPicturePathParams>({
    method: 'get',
    url: '/api/v1/room/{roomId}/picture',
    ...variables,
    signal,
  });

export const useRoomGetRoomPicture = <TData = undefined>(
  variables: RoomGetRoomPictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, RoomGetRoomPictureError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<undefined, RoomGetRoomPictureError, TData>({
    queryFn: ({ signal }) => fetchRoomGetRoomPicture({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'roomGetRoomPicture', path: '/api/v1/room/{roomId}/picture', variables }),
    ...options,
    ...queryOptions,
  });
};

export type RoomGetRoomCheckInQrCodePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomGetRoomCheckInQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type RoomGetRoomCheckInQrCodeVariables = {
  pathParams: RoomGetRoomCheckInQrCodePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomGetRoomCheckInQrCode = (variables: RoomGetRoomCheckInQrCodeVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<string, RoomGetRoomCheckInQrCodeError, undefined, {}, {}, RoomGetRoomCheckInQrCodePathParams>({
    method: 'get',
    url: '/api/v1/room/{roomId}/check-in.svg',
    ...variables,
    signal,
  });

export const useRoomGetRoomCheckInQrCode = <TData = string>(
  variables: RoomGetRoomCheckInQrCodeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, RoomGetRoomCheckInQrCodeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<string, RoomGetRoomCheckInQrCodeError, TData>({
    queryFn: ({ signal }) => fetchRoomGetRoomCheckInQrCode({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'roomGetRoomCheckInQrCode',
      path: '/api/v1/room/{roomId}/check-in.svg',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type RoomRoomCheckInPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomRoomCheckInError = Fetcher.ErrorWrapper<undefined>;

export type RoomRoomCheckInVariables = {
  pathParams: RoomRoomCheckInPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomRoomCheckIn = (variables: RoomRoomCheckInVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomRoomCheckInError, undefined, {}, {}, RoomRoomCheckInPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/check-in',
    ...variables,
    signal,
  });

export const useRoomRoomCheckIn = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RoomRoomCheckInError, RoomRoomCheckInVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomRoomCheckInError, RoomRoomCheckInVariables>({
    mutationFn: (variables: RoomRoomCheckInVariables) => fetchRoomRoomCheckIn({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomRoomCheckInByIdentifierPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   */
  identifier: string;
};

export type RoomRoomCheckInByIdentifierError = Fetcher.ErrorWrapper<undefined>;

export type RoomRoomCheckInByIdentifierVariables = {
  pathParams: RoomRoomCheckInByIdentifierPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomRoomCheckInByIdentifier = (
  variables: RoomRoomCheckInByIdentifierVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    RoomRoomCheckInByIdentifierError,
    undefined,
    {},
    {},
    RoomRoomCheckInByIdentifierPathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/check-in/{identifier}', ...variables, signal });

export const useRoomRoomCheckInByIdentifier = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomRoomCheckInByIdentifierError, RoomRoomCheckInByIdentifierVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomRoomCheckInByIdentifierError, RoomRoomCheckInByIdentifierVariables>({
    mutationFn: (variables: RoomRoomCheckInByIdentifierVariables) =>
      fetchRoomRoomCheckInByIdentifier({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomCreateRoomMilestonePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomCreateRoomMilestoneError = Fetcher.ErrorWrapper<undefined>;

export type RoomCreateRoomMilestoneVariables = {
  body: Schemas.CreateRoomMilestoneRequest;
  pathParams: RoomCreateRoomMilestonePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomCreateRoomMilestone = (variables: RoomCreateRoomMilestoneVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    string,
    RoomCreateRoomMilestoneError,
    Schemas.CreateRoomMilestoneRequest,
    {},
    {},
    RoomCreateRoomMilestonePathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/milestones', ...variables, signal });

export const useRoomCreateRoomMilestone = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, RoomCreateRoomMilestoneError, RoomCreateRoomMilestoneVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, RoomCreateRoomMilestoneError, RoomCreateRoomMilestoneVariables>({
    mutationFn: (variables: RoomCreateRoomMilestoneVariables) =>
      fetchRoomCreateRoomMilestone({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomCreateRoomTaskPathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomCreateRoomTaskError = Fetcher.ErrorWrapper<undefined>;

export type RoomCreateRoomTaskVariables = {
  body: Schemas.CreateRoomTaskRequest;
  pathParams: RoomCreateRoomTaskPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomCreateRoomTask = (variables: RoomCreateRoomTaskVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    string,
    RoomCreateRoomTaskError,
    Schemas.CreateRoomTaskRequest,
    {},
    {},
    RoomCreateRoomTaskPathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/tasks', ...variables, signal });

export const useRoomCreateRoomTask = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, RoomCreateRoomTaskError, RoomCreateRoomTaskVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<string, RoomCreateRoomTaskError, RoomCreateRoomTaskVariables>({
    mutationFn: (variables: RoomCreateRoomTaskVariables) =>
      fetchRoomCreateRoomTask({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomDeleteRoomMilestonePathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  milestoneId: string;
};

export type RoomDeleteRoomMilestoneError = Fetcher.ErrorWrapper<undefined>;

export type RoomDeleteRoomMilestoneVariables = {
  pathParams: RoomDeleteRoomMilestonePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDeleteRoomMilestone = (variables: RoomDeleteRoomMilestoneVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomDeleteRoomMilestoneError, undefined, {}, {}, RoomDeleteRoomMilestonePathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/milestones/{milestoneId}',
    ...variables,
    signal,
  });

export const useRoomDeleteRoomMilestone = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomDeleteRoomMilestoneError, RoomDeleteRoomMilestoneVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDeleteRoomMilestoneError, RoomDeleteRoomMilestoneVariables>({
    mutationFn: (variables: RoomDeleteRoomMilestoneVariables) =>
      fetchRoomDeleteRoomMilestone({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomDeleteRoomTaskPathParams = {
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Require, non-empty
   *
   * @format uuid
   */
  taskId: string;
};

export type RoomDeleteRoomTaskError = Fetcher.ErrorWrapper<undefined>;

export type RoomDeleteRoomTaskVariables = {
  pathParams: RoomDeleteRoomTaskPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDeleteRoomTask = (variables: RoomDeleteRoomTaskVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, RoomDeleteRoomTaskError, undefined, {}, {}, RoomDeleteRoomTaskPathParams>({
    method: 'post',
    url: '/api/v1/room/{roomId}/tasks/{taskId}',
    ...variables,
    signal,
  });

export const useRoomDeleteRoomTask = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomDeleteRoomTaskError, RoomDeleteRoomTaskVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDeleteRoomTaskError, RoomDeleteRoomTaskVariables>({
    mutationFn: (variables: RoomDeleteRoomTaskVariables) =>
      fetchRoomDeleteRoomTask({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomCreateMeetingTimeSeriesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
};

export type RoomCreateMeetingTimeSeriesError = Fetcher.ErrorWrapper<undefined>;

export type RoomCreateMeetingTimeSeriesVariables = {
  body?: Schemas.CreateMeetingTimeSeriesRequest;
  pathParams: RoomCreateMeetingTimeSeriesPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomCreateMeetingTimeSeries = (
  variables: RoomCreateMeetingTimeSeriesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    RoomCreateMeetingTimeSeriesError,
    Schemas.CreateMeetingTimeSeriesRequest,
    {},
    {},
    RoomCreateMeetingTimeSeriesPathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/meeting-time-series', ...variables, signal });

export const useRoomCreateMeetingTimeSeries = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomCreateMeetingTimeSeriesError, RoomCreateMeetingTimeSeriesVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomCreateMeetingTimeSeriesError, RoomCreateMeetingTimeSeriesVariables>({
    mutationFn: (variables: RoomCreateMeetingTimeSeriesVariables) =>
      fetchRoomCreateMeetingTimeSeries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomUpdateMeetingTimeSeriesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  roomId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingTimeSeriesId: string;
};

export type RoomUpdateMeetingTimeSeriesError = Fetcher.ErrorWrapper<undefined>;

export type RoomUpdateMeetingTimeSeriesVariables = {
  body?: Schemas.UpdateMeetingTimeSeriesRequest;
  pathParams: RoomUpdateMeetingTimeSeriesPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomUpdateMeetingTimeSeries = (
  variables: RoomUpdateMeetingTimeSeriesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    RoomUpdateMeetingTimeSeriesError,
    Schemas.UpdateMeetingTimeSeriesRequest,
    {},
    {},
    RoomUpdateMeetingTimeSeriesPathParams
  >({ method: 'put', url: '/api/v1/room/{roomId}/meeting-time-series/{meetingTimeSeriesId}', ...variables, signal });

export const useRoomUpdateMeetingTimeSeries = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomUpdateMeetingTimeSeriesError, RoomUpdateMeetingTimeSeriesVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomUpdateMeetingTimeSeriesError, RoomUpdateMeetingTimeSeriesVariables>({
    mutationFn: (variables: RoomUpdateMeetingTimeSeriesVariables) =>
      fetchRoomUpdateMeetingTimeSeries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RoomDeleteMeetingTimeSeriesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  meetingTimeSeriesId: string;
  roomId: string;
};

export type RoomDeleteMeetingTimeSeriesError = Fetcher.ErrorWrapper<undefined>;

export type RoomDeleteMeetingTimeSeriesVariables = {
  pathParams: RoomDeleteMeetingTimeSeriesPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchRoomDeleteMeetingTimeSeries = (
  variables: RoomDeleteMeetingTimeSeriesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    RoomDeleteMeetingTimeSeriesError,
    undefined,
    {},
    {},
    RoomDeleteMeetingTimeSeriesPathParams
  >({ method: 'post', url: '/api/v1/room/{roomId}/meeting-time-series/{meetingTimeSeriesId}', ...variables, signal });

export const useRoomDeleteMeetingTimeSeries = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RoomDeleteMeetingTimeSeriesError, RoomDeleteMeetingTimeSeriesVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, RoomDeleteMeetingTimeSeriesError, RoomDeleteMeetingTimeSeriesVariables>({
    mutationFn: (variables: RoomDeleteMeetingTimeSeriesVariables) =>
      fetchRoomDeleteMeetingTimeSeries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictGetSchoolsForSchoolDistrictPathParams = {
  /**
   * @format uuid
   */
  schoolDistrictId: string;
  bubbleId: string;
};

export type SchoolDistrictGetSchoolsForSchoolDistrictError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictGetSchoolsForSchoolDistrictResponse = Schemas.SchoolSummaryDto[];

export type SchoolDistrictGetSchoolsForSchoolDistrictVariables = {
  pathParams: SchoolDistrictGetSchoolsForSchoolDistrictPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictGetSchoolsForSchoolDistrict = (
  variables: SchoolDistrictGetSchoolsForSchoolDistrictVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    SchoolDistrictGetSchoolsForSchoolDistrictResponse,
    SchoolDistrictGetSchoolsForSchoolDistrictError,
    undefined,
    {},
    {},
    SchoolDistrictGetSchoolsForSchoolDistrictPathParams
  >({
    method: 'get',
    url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/schools',
    ...variables,
    signal,
  });

export const useSchoolDistrictGetSchoolsForSchoolDistrict = <TData = SchoolDistrictGetSchoolsForSchoolDistrictResponse>(
  variables: SchoolDistrictGetSchoolsForSchoolDistrictVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      SchoolDistrictGetSchoolsForSchoolDistrictResponse,
      SchoolDistrictGetSchoolsForSchoolDistrictError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    SchoolDistrictGetSchoolsForSchoolDistrictResponse,
    SchoolDistrictGetSchoolsForSchoolDistrictError,
    TData
  >({
    queryFn: ({ signal }) =>
      fetchSchoolDistrictGetSchoolsForSchoolDistrict({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'schoolDistrictGetSchoolsForSchoolDistrict',
      path: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/schools',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type SchoolDistrictGetSearchableSchoolsForSchoolDistrictPathParams = {
  /**
   * @format uuid
   */
  schoolDistrictId: string;
};

export type SchoolDistrictGetSearchableSchoolsForSchoolDistrictError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictGetSearchableSchoolsForSchoolDistrictResponse = Schemas.SchoolSummaryDto[];

export type SchoolDistrictGetSearchableSchoolsForSchoolDistrictVariables = {
  pathParams: SchoolDistrictGetSearchableSchoolsForSchoolDistrictPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictGetSearchableSchoolsForSchoolDistrict = (
  variables: SchoolDistrictGetSearchableSchoolsForSchoolDistrictVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    SchoolDistrictGetSearchableSchoolsForSchoolDistrictResponse,
    SchoolDistrictGetSearchableSchoolsForSchoolDistrictError,
    undefined,
    {},
    {},
    SchoolDistrictGetSearchableSchoolsForSchoolDistrictPathParams
  >({ method: 'get', url: '/api/v1/SchoolDistrict/{schoolDistrictId}/schools', ...variables, signal });

export const useSchoolDistrictGetSearchableSchoolsForSchoolDistrict = <
  TData = SchoolDistrictGetSearchableSchoolsForSchoolDistrictResponse
>(
  variables: SchoolDistrictGetSearchableSchoolsForSchoolDistrictVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      SchoolDistrictGetSearchableSchoolsForSchoolDistrictResponse,
      SchoolDistrictGetSearchableSchoolsForSchoolDistrictError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    SchoolDistrictGetSearchableSchoolsForSchoolDistrictResponse,
    SchoolDistrictGetSearchableSchoolsForSchoolDistrictError,
    TData
  >({
    queryFn: ({ signal }) =>
      fetchSchoolDistrictGetSearchableSchoolsForSchoolDistrict({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'schoolDistrictGetSearchableSchoolsForSchoolDistrict',
      path: '/api/v1/SchoolDistrict/{schoolDistrictId}/schools',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type SchoolDistrictGetVettedUsersPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  schoolDistrictId: string;
  bubbleId: string;
};

export type SchoolDistrictGetVettedUsersError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictGetVettedUsersResponse = Schemas.BubbleUserDto[];

export type SchoolDistrictGetVettedUsersVariables = {
  pathParams: SchoolDistrictGetVettedUsersPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictGetVettedUsers = (
  variables: SchoolDistrictGetVettedUsersVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    SchoolDistrictGetVettedUsersResponse,
    SchoolDistrictGetVettedUsersError,
    undefined,
    {},
    {},
    SchoolDistrictGetVettedUsersPathParams
  >({
    method: 'get',
    url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/vetted-users',
    ...variables,
    signal,
  });

export const useSchoolDistrictGetVettedUsers = <TData = SchoolDistrictGetVettedUsersResponse>(
  variables: SchoolDistrictGetVettedUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SchoolDistrictGetVettedUsersResponse, SchoolDistrictGetVettedUsersError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<SchoolDistrictGetVettedUsersResponse, SchoolDistrictGetVettedUsersError, TData>({
    queryFn: ({ signal }) => fetchSchoolDistrictGetVettedUsers({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'schoolDistrictGetVettedUsers',
      path: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/vetted-users',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type SchoolDistrictApproveVettedUserPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type SchoolDistrictApproveVettedUserError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictApproveVettedUserVariables = {
  pathParams: SchoolDistrictApproveVettedUserPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictApproveVettedUser = (
  variables: SchoolDistrictApproveVettedUserVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    SchoolDistrictApproveVettedUserError,
    undefined,
    {},
    {},
    SchoolDistrictApproveVettedUserPathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/user/{userId}/approve', ...variables, signal });

export const useSchoolDistrictApproveVettedUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchoolDistrictApproveVettedUserError,
      SchoolDistrictApproveVettedUserVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    SchoolDistrictApproveVettedUserError,
    SchoolDistrictApproveVettedUserVariables
  >({
    mutationFn: (variables: SchoolDistrictApproveVettedUserVariables) =>
      fetchSchoolDistrictApproveVettedUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictRemoveVettedUserPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type SchoolDistrictRemoveVettedUserError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictRemoveVettedUserVariables = {
  pathParams: SchoolDistrictRemoveVettedUserPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictRemoveVettedUser = (
  variables: SchoolDistrictRemoveVettedUserVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    SchoolDistrictRemoveVettedUserError,
    undefined,
    {},
    {},
    SchoolDistrictRemoveVettedUserPathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/user/{userId}/remove', ...variables, signal });

export const useSchoolDistrictRemoveVettedUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchoolDistrictRemoveVettedUserError,
      SchoolDistrictRemoveVettedUserVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    SchoolDistrictRemoveVettedUserError,
    SchoolDistrictRemoveVettedUserVariables
  >({
    mutationFn: (variables: SchoolDistrictRemoveVettedUserVariables) =>
      fetchSchoolDistrictRemoveVettedUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictPinSchoolPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  districtId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  schoolId: string;
  bubbleId: string;
};

export type SchoolDistrictPinSchoolError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictPinSchoolVariables = {
  pathParams: SchoolDistrictPinSchoolPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictPinSchool = (variables: SchoolDistrictPinSchoolVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, SchoolDistrictPinSchoolError, undefined, {}, {}, SchoolDistrictPinSchoolPathParams>({
    method: 'post',
    url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{districtId}/school/{schoolId}/pin',
    ...variables,
    signal,
  });

export const useSchoolDistrictPinSchool = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SchoolDistrictPinSchoolError, SchoolDistrictPinSchoolVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, SchoolDistrictPinSchoolError, SchoolDistrictPinSchoolVariables>({
    mutationFn: (variables: SchoolDistrictPinSchoolVariables) =>
      fetchSchoolDistrictPinSchool({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictUnpinSchoolPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  districtId: string;
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  schoolId: string;
  bubbleId: string;
};

export type SchoolDistrictUnpinSchoolError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictUnpinSchoolVariables = {
  pathParams: SchoolDistrictUnpinSchoolPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictUnpinSchool = (variables: SchoolDistrictUnpinSchoolVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, SchoolDistrictUnpinSchoolError, undefined, {}, {}, SchoolDistrictUnpinSchoolPathParams>(
    {
      method: 'post',
      url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{districtId}/school/{schoolId}/unpin',
      ...variables,
      signal,
    }
  );

export const useSchoolDistrictUnpinSchool = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SchoolDistrictUnpinSchoolError, SchoolDistrictUnpinSchoolVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, SchoolDistrictUnpinSchoolError, SchoolDistrictUnpinSchoolVariables>({
    mutationFn: (variables: SchoolDistrictUnpinSchoolVariables) =>
      fetchSchoolDistrictUnpinSchool({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictCreateNewDistrictPathParams = {
  bubbleId: string;
};

export type SchoolDistrictCreateNewDistrictError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictCreateNewDistrictVariables = {
  body?: Schemas.CreateSchoolDistrictRequest;
  pathParams: SchoolDistrictCreateNewDistrictPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictCreateNewDistrict = (
  variables: SchoolDistrictCreateNewDistrictVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    SchoolDistrictCreateNewDistrictError,
    Schemas.CreateSchoolDistrictRequest,
    {},
    {},
    SchoolDistrictCreateNewDistrictPathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/create-district', ...variables, signal });

export const useSchoolDistrictCreateNewDistrict = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchoolDistrictCreateNewDistrictError,
      SchoolDistrictCreateNewDistrictVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    SchoolDistrictCreateNewDistrictError,
    SchoolDistrictCreateNewDistrictVariables
  >({
    mutationFn: (variables: SchoolDistrictCreateNewDistrictVariables) =>
      fetchSchoolDistrictCreateNewDistrict({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SchoolDistrictCreateNewSchoolPathParams = {
  bubbleId: string;
};

export type SchoolDistrictCreateNewSchoolError = Fetcher.ErrorWrapper<undefined>;

export type SchoolDistrictCreateNewSchoolVariables = {
  body?: Schemas.CreateSchoolRequest;
  pathParams: SchoolDistrictCreateNewSchoolPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSchoolDistrictCreateNewSchool = (
  variables: SchoolDistrictCreateNewSchoolVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    SchoolDistrictCreateNewSchoolError,
    Schemas.CreateSchoolRequest,
    {},
    {},
    SchoolDistrictCreateNewSchoolPathParams
  >({ method: 'post', url: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/create-school', ...variables, signal });

export const useSchoolDistrictCreateNewSchool = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchoolDistrictCreateNewSchoolError,
      SchoolDistrictCreateNewSchoolVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, SchoolDistrictCreateNewSchoolError, SchoolDistrictCreateNewSchoolVariables>({
    mutationFn: (variables: SchoolDistrictCreateNewSchoolVariables) =>
      fetchSchoolDistrictCreateNewSchool({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchSearchError = Fetcher.ErrorWrapper<undefined>;

export type SearchSearchResponse = Schemas.SearchResultDto[];

export type SearchSearchVariables = {
  body?: Schemas.SearchDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSearchSearch = (variables: SearchSearchVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<SearchSearchResponse, SearchSearchError, Schemas.SearchDto, {}, {}, {}>({
    method: 'post',
    url: '/api/Search/search',
    ...variables,
    signal,
  });

export const useSearchSearch = (
  options?: Omit<
    reactQuery.UseMutationOptions<SearchSearchResponse, SearchSearchError, SearchSearchVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<SearchSearchResponse, SearchSearchError, SearchSearchVariables>({
    mutationFn: (variables: SearchSearchVariables) => fetchSearchSearch({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchSearchRoomsAndEventsError = Fetcher.ErrorWrapper<undefined>;

export type SearchSearchRoomsAndEventsResponse = Schemas.SearchResultDto[];

export type SearchSearchRoomsAndEventsVariables = {
  body?: Schemas.SearchDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSearchSearchRoomsAndEvents = (variables: SearchSearchRoomsAndEventsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    SearchSearchRoomsAndEventsResponse,
    SearchSearchRoomsAndEventsError,
    Schemas.SearchDto,
    {},
    {},
    {}
  >({ method: 'post', url: '/api/Search/search/rooms-and-events', ...variables, signal });

export const useSearchSearchRoomsAndEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SearchSearchRoomsAndEventsResponse,
      SearchSearchRoomsAndEventsError,
      SearchSearchRoomsAndEventsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    SearchSearchRoomsAndEventsResponse,
    SearchSearchRoomsAndEventsError,
    SearchSearchRoomsAndEventsVariables
  >({
    mutationFn: (variables: SearchSearchRoomsAndEventsVariables) =>
      fetchSearchSearchRoomsAndEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchSearchRoomsError = Fetcher.ErrorWrapper<undefined>;

export type SearchSearchRoomsResponse = Schemas.SearchResultDto[];

export type SearchSearchRoomsVariables = {
  body?: Schemas.SearchDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSearchSearchRooms = (variables: SearchSearchRoomsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<SearchSearchRoomsResponse, SearchSearchRoomsError, Schemas.SearchDto, {}, {}, {}>({
    method: 'post',
    url: '/api/Search/search/rooms',
    ...variables,
    signal,
  });

export const useSearchSearchRooms = (
  options?: Omit<
    reactQuery.UseMutationOptions<SearchSearchRoomsResponse, SearchSearchRoomsError, SearchSearchRoomsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<SearchSearchRoomsResponse, SearchSearchRoomsError, SearchSearchRoomsVariables>({
    mutationFn: (variables: SearchSearchRoomsVariables) => fetchSearchSearchRooms({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchSearchEventsError = Fetcher.ErrorWrapper<undefined>;

export type SearchSearchEventsResponse = Schemas.SearchResultDto[];

export type SearchSearchEventsVariables = {
  body?: Schemas.SearchDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSearchSearchEvents = (variables: SearchSearchEventsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<SearchSearchEventsResponse, SearchSearchEventsError, Schemas.SearchDto, {}, {}, {}>({
    method: 'post',
    url: '/api/Search/search/events',
    ...variables,
    signal,
  });

export const useSearchSearchEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<SearchSearchEventsResponse, SearchSearchEventsError, SearchSearchEventsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<SearchSearchEventsResponse, SearchSearchEventsError, SearchSearchEventsVariables>({
    mutationFn: (variables: SearchSearchEventsVariables) =>
      fetchSearchSearchEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchSearchCandidatesError = Fetcher.ErrorWrapper<undefined>;

export type SearchSearchCandidatesResponse = Schemas.SearchCandidateResultDto[];

export type SearchSearchCandidatesVariables = {
  body?: Schemas.SearchCandidateDto;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchSearchSearchCandidates = (variables: SearchSearchCandidatesVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    SearchSearchCandidatesResponse,
    SearchSearchCandidatesError,
    Schemas.SearchCandidateDto,
    {},
    {},
    {}
  >({ method: 'post', url: '/api/Search/search/candidates', ...variables, signal });

export const useSearchSearchCandidates = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SearchSearchCandidatesResponse,
      SearchSearchCandidatesError,
      SearchSearchCandidatesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    SearchSearchCandidatesResponse,
    SearchSearchCandidatesError,
    SearchSearchCandidatesVariables
  >({
    mutationFn: (variables: SearchSearchCandidatesVariables) =>
      fetchSearchSearchCandidates({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type TranslationGetLabelsError = Fetcher.ErrorWrapper<undefined>;

export type TranslationGetLabelsResponse = Schemas.StringStringTuple[];

export type TranslationGetLabelsRequestBody = string[];

export type TranslationGetLabelsVariables = {
  body?: TranslationGetLabelsRequestBody;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchTranslationGetLabels = (variables: TranslationGetLabelsVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    TranslationGetLabelsResponse,
    TranslationGetLabelsError,
    TranslationGetLabelsRequestBody,
    {},
    {},
    {}
  >({ method: 'post', url: '/api/v1/translations/labels', ...variables, signal });

export const useTranslationGetLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TranslationGetLabelsResponse,
      TranslationGetLabelsError,
      TranslationGetLabelsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<TranslationGetLabelsResponse, TranslationGetLabelsError, TranslationGetLabelsVariables>(
    {
      mutationFn: (variables: TranslationGetLabelsVariables) =>
        fetchTranslationGetLabels({ ...fetcherOptions, ...variables }),
      ...options,
    }
  );
};

export type TranslationGetTranslatableLanguagesError = Fetcher.ErrorWrapper<undefined>;

export type TranslationGetTranslatableLanguagesResponse = Schemas.LanguageToEnum[];

export type TranslationGetTranslatableLanguagesVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchTranslationGetTranslatableLanguages = (
  variables: TranslationGetTranslatableLanguagesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    TranslationGetTranslatableLanguagesResponse,
    TranslationGetTranslatableLanguagesError,
    undefined,
    {},
    {},
    {}
  >({ method: 'get', url: '/api/v1/translations/translatable-languages', ...variables, signal });

export const useTranslationGetTranslatableLanguages = <TData = TranslationGetTranslatableLanguagesResponse>(
  variables: TranslationGetTranslatableLanguagesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TranslationGetTranslatableLanguagesResponse,
      TranslationGetTranslatableLanguagesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    TranslationGetTranslatableLanguagesResponse,
    TranslationGetTranslatableLanguagesError,
    TData
  >({
    queryFn: ({ signal }) => fetchTranslationGetTranslatableLanguages({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'translationGetTranslatableLanguages',
      path: '/api/v1/translations/translatable-languages',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type UserGetPublicUserInfoPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type UserGetPublicUserInfoError = Fetcher.ErrorWrapper<undefined>;

export type UserGetPublicUserInfoVariables = {
  pathParams: UserGetPublicUserInfoPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserGetPublicUserInfo = (variables: UserGetPublicUserInfoVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<Schemas.UserDto, UserGetPublicUserInfoError, undefined, {}, {}, UserGetPublicUserInfoPathParams>({
    method: 'get',
    url: '/api/v1/User/{userId}/public',
    ...variables,
    signal,
  });

export const useUserGetPublicUserInfo = <TData = Schemas.UserDto>(
  variables: UserGetPublicUserInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UserDto, UserGetPublicUserInfoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.UserDto, UserGetPublicUserInfoError, TData>({
    queryFn: ({ signal }) => fetchUserGetPublicUserInfo({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'userGetPublicUserInfo', path: '/api/v1/User/{userId}/public', variables }),
    ...options,
    ...queryOptions,
  });
};

export type UserGetUserRsvpsSearchPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type UserGetUserRsvpsSearchQueryParams = {
  /**
   * Optional, defaults to today
   *
   * @format date-time
   */
  BeginTime?: string;
  /**
   * Optional, defaults to the end of next year
   *
   * @format date-time
   */
  EndTime?: string;
};

export type UserGetUserRsvpsSearchError = Fetcher.ErrorWrapper<undefined>;

export type UserGetUserRsvpsSearchResponse = Schemas.RsvpResponse[];

export type UserGetUserRsvpsSearchVariables = {
  pathParams: UserGetUserRsvpsSearchPathParams;
  queryParams?: UserGetUserRsvpsSearchQueryParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserGetUserRsvpsSearch = (variables: UserGetUserRsvpsSearchVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<
    UserGetUserRsvpsSearchResponse,
    UserGetUserRsvpsSearchError,
    undefined,
    {},
    UserGetUserRsvpsSearchQueryParams,
    UserGetUserRsvpsSearchPathParams
  >({ method: 'get', url: '/api/v1/User/{userId}/rsvps', ...variables, signal });

export const useUserGetUserRsvpsSearch = <TData = UserGetUserRsvpsSearchResponse>(
  variables: UserGetUserRsvpsSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UserGetUserRsvpsSearchResponse, UserGetUserRsvpsSearchError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<UserGetUserRsvpsSearchResponse, UserGetUserRsvpsSearchError, TData>({
    queryFn: ({ signal }) => fetchUserGetUserRsvpsSearch({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({ operationId: 'userGetUserRsvpsSearch', path: '/api/v1/User/{userId}/rsvps', variables }),
    ...options,
    ...queryOptions,
  });
};

export type UserSetDailyEmailNotificationPreferencesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type UserSetDailyEmailNotificationPreferencesQueryParams = {
  /**
   * Required
   */
  allowNotifications?: boolean;
};

export type UserSetDailyEmailNotificationPreferencesError = Fetcher.ErrorWrapper<undefined>;

export type UserSetDailyEmailNotificationPreferencesVariables = {
  pathParams: UserSetDailyEmailNotificationPreferencesPathParams;
  queryParams?: UserSetDailyEmailNotificationPreferencesQueryParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserSetDailyEmailNotificationPreferences = (
  variables: UserSetDailyEmailNotificationPreferencesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    UserSetDailyEmailNotificationPreferencesError,
    undefined,
    {},
    UserSetDailyEmailNotificationPreferencesQueryParams,
    UserSetDailyEmailNotificationPreferencesPathParams
  >({ method: 'post', url: '/api/v1/User/{userId}/daily-email-notifications', ...variables, signal });

export const useUserSetDailyEmailNotificationPreferences = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserSetDailyEmailNotificationPreferencesError,
      UserSetDailyEmailNotificationPreferencesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    UserSetDailyEmailNotificationPreferencesError,
    UserSetDailyEmailNotificationPreferencesVariables
  >({
    mutationFn: (variables: UserSetDailyEmailNotificationPreferencesVariables) =>
      fetchUserSetDailyEmailNotificationPreferences({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserSetWeeklyEmailNotificationPreferencesPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  userId: string;
};

export type UserSetWeeklyEmailNotificationPreferencesQueryParams = {
  /**
   * Required
   */
  allowNotifications?: boolean;
};

export type UserSetWeeklyEmailNotificationPreferencesError = Fetcher.ErrorWrapper<undefined>;

export type UserSetWeeklyEmailNotificationPreferencesVariables = {
  pathParams: UserSetWeeklyEmailNotificationPreferencesPathParams;
  queryParams?: UserSetWeeklyEmailNotificationPreferencesQueryParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserSetWeeklyEmailNotificationPreferences = (
  variables: UserSetWeeklyEmailNotificationPreferencesVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    UserSetWeeklyEmailNotificationPreferencesError,
    undefined,
    {},
    UserSetWeeklyEmailNotificationPreferencesQueryParams,
    UserSetWeeklyEmailNotificationPreferencesPathParams
  >({ method: 'post', url: '/api/v1/User/{userId}/weekly-email-notifications', ...variables, signal });

export const useUserSetWeeklyEmailNotificationPreferences = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserSetWeeklyEmailNotificationPreferencesError,
      UserSetWeeklyEmailNotificationPreferencesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    UserSetWeeklyEmailNotificationPreferencesError,
    UserSetWeeklyEmailNotificationPreferencesVariables
  >({
    mutationFn: (variables: UserSetWeeklyEmailNotificationPreferencesVariables) =>
      fetchUserSetWeeklyEmailNotificationPreferences({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserSetDefaultLanguagePathParams = {
  /**
   * CrowdCoursing.Common.Translations.DefaultLanguage
   */
  defaultLanguage: Schemas.DefaultLanguage;
  userId: string;
};

export type UserSetDefaultLanguageError = Fetcher.ErrorWrapper<undefined>;

export type UserSetDefaultLanguageVariables = {
  pathParams: UserSetDefaultLanguagePathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserSetDefaultLanguage = (variables: UserSetDefaultLanguageVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, UserSetDefaultLanguageError, undefined, {}, {}, UserSetDefaultLanguagePathParams>({
    method: 'post',
    url: '/api/v1/User/{userId}/set-default-language/{defaultLanguage}',
    ...variables,
    signal,
  });

export const useUserSetDefaultLanguage = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UserSetDefaultLanguageError, UserSetDefaultLanguageVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, UserSetDefaultLanguageError, UserSetDefaultLanguageVariables>({
    mutationFn: (variables: UserSetDefaultLanguageVariables) =>
      fetchUserSetDefaultLanguage({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserSetGenderPathParams = {
  /**
   * CrowdCoursing.Domain.Entities.Users.Gender
   */
  gender: Schemas.Gender;
  userId: string;
};

export type UserSetGenderError = Fetcher.ErrorWrapper<undefined>;

export type UserSetGenderVariables = {
  pathParams: UserSetGenderPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserSetGender = (variables: UserSetGenderVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, UserSetGenderError, undefined, {}, {}, UserSetGenderPathParams>({
    method: 'post',
    url: '/api/v1/User/{userId}/set-gender/{gender}',
    ...variables,
    signal,
  });

export const useUserSetGender = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UserSetGenderError, UserSetGenderVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, UserSetGenderError, UserSetGenderVariables>({
    mutationFn: (variables: UserSetGenderVariables) => fetchUserSetGender({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserSetAgeBracketPathParams = {
  /**
   * CrowdCoursing.Domain.Entities.Users.AgeBracket
   */
  age: Schemas.AgeBracket;
  userId: string;
};

export type UserSetAgeBracketError = Fetcher.ErrorWrapper<undefined>;

export type UserSetAgeBracketVariables = {
  pathParams: UserSetAgeBracketPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserSetAgeBracket = (variables: UserSetAgeBracketVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, UserSetAgeBracketError, undefined, {}, {}, UserSetAgeBracketPathParams>({
    method: 'post',
    url: '/api/v1/User/{userId}/set-age-bracket/{age}',
    ...variables,
    signal,
  });

export const useUserSetAgeBracket = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UserSetAgeBracketError, UserSetAgeBracketVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, UserSetAgeBracketError, UserSetAgeBracketVariables>({
    mutationFn: (variables: UserSetAgeBracketVariables) => fetchUserSetAgeBracket({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserMarkNotificationReadPathParams = {
  /**
   * Notification Id
   *
   * @format uuid
   */
  notificationId: string;
  userId: string;
};

export type UserMarkNotificationReadError = Fetcher.ErrorWrapper<undefined>;

export type UserMarkNotificationReadVariables = {
  pathParams: UserMarkNotificationReadPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserMarkNotificationRead = (variables: UserMarkNotificationReadVariables, signal?: AbortSignal) =>
  crowdCoursingFetch<undefined, UserMarkNotificationReadError, undefined, {}, {}, UserMarkNotificationReadPathParams>({
    method: 'post',
    url: '/api/v1/User/{userId}/mark-notification-read/{notificationId}',
    ...variables,
    signal,
  });

export const useUserMarkNotificationRead = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UserMarkNotificationReadError, UserMarkNotificationReadVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, UserMarkNotificationReadError, UserMarkNotificationReadVariables>({
    mutationFn: (variables: UserMarkNotificationReadVariables) =>
      fetchUserMarkNotificationRead({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserMarkNotificationsAsReadPathParams = {
  userId: string;
};

export type UserMarkNotificationsAsReadError = Fetcher.ErrorWrapper<undefined>;

export type UserMarkNotificationsAsReadVariables = {
  body?: Schemas.BulkNotificationsOperation;
  pathParams: UserMarkNotificationsAsReadPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserMarkNotificationsAsRead = (
  variables: UserMarkNotificationsAsReadVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    UserMarkNotificationsAsReadError,
    Schemas.BulkNotificationsOperation,
    {},
    {},
    UserMarkNotificationsAsReadPathParams
  >({ method: 'post', url: '/api/v1/User/{userId}/mark-notifications-as-read', ...variables, signal });

export const useUserMarkNotificationsAsRead = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UserMarkNotificationsAsReadError, UserMarkNotificationsAsReadVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<undefined, UserMarkNotificationsAsReadError, UserMarkNotificationsAsReadVariables>({
    mutationFn: (variables: UserMarkNotificationsAsReadVariables) =>
      fetchUserMarkNotificationsAsRead({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserMarkNotificationsAsArchivedPathParams = {
  userId: string;
};

export type UserMarkNotificationsAsArchivedError = Fetcher.ErrorWrapper<undefined>;

export type UserMarkNotificationsAsArchivedVariables = {
  body?: Schemas.BulkNotificationsOperation;
  pathParams: UserMarkNotificationsAsArchivedPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserMarkNotificationsAsArchived = (
  variables: UserMarkNotificationsAsArchivedVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    UserMarkNotificationsAsArchivedError,
    Schemas.BulkNotificationsOperation,
    {},
    {},
    UserMarkNotificationsAsArchivedPathParams
  >({ method: 'post', url: '/api/v1/User/{userId}/mark-notifications-as-archived', ...variables, signal });

export const useUserMarkNotificationsAsArchived = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserMarkNotificationsAsArchivedError,
      UserMarkNotificationsAsArchivedVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    UserMarkNotificationsAsArchivedError,
    UserMarkNotificationsAsArchivedVariables
  >({
    mutationFn: (variables: UserMarkNotificationsAsArchivedVariables) =>
      fetchUserMarkNotificationsAsArchived({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserGetUserHistoryInformationPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type UserGetUserHistoryInformationError = Fetcher.ErrorWrapper<undefined>;

export type UserGetUserHistoryInformationVariables = {
  pathParams: UserGetUserHistoryInformationPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchUserGetUserHistoryInformation = (
  variables: UserGetUserHistoryInformationVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    Schemas.UserHistoryDto,
    UserGetUserHistoryInformationError,
    undefined,
    {},
    {},
    UserGetUserHistoryInformationPathParams
  >({ method: 'get', url: '/api/v1/User/{userId}/user-history', ...variables, signal });

export const useUserGetUserHistoryInformation = <TData = Schemas.UserHistoryDto>(
  variables: UserGetUserHistoryInformationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UserHistoryDto, UserGetUserHistoryInformationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<Schemas.UserHistoryDto, UserGetUserHistoryInformationError, TData>({
    queryFn: ({ signal }) => fetchUserGetUserHistoryInformation({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'userGetUserHistoryInformation',
      path: '/api/v1/User/{userId}/user-history',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type VolunteeringManuallyAddVolunteeringTimeError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringManuallyAddVolunteeringTimeVariables = {
  body?: Schemas.ManualVolunteerAddRequest;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringManuallyAddVolunteeringTime = (
  variables: VolunteeringManuallyAddVolunteeringTimeVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    VolunteeringManuallyAddVolunteeringTimeError,
    Schemas.ManualVolunteerAddRequest,
    {},
    {},
    {}
  >({ method: 'post', url: '/api/v1/Volunteering/add-volunteer-time', ...variables, signal });

export const useVolunteeringManuallyAddVolunteeringTime = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VolunteeringManuallyAddVolunteeringTimeError,
      VolunteeringManuallyAddVolunteeringTimeVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    VolunteeringManuallyAddVolunteeringTimeError,
    VolunteeringManuallyAddVolunteeringTimeVariables
  >({
    mutationFn: (variables: VolunteeringManuallyAddVolunteeringTimeVariables) =>
      fetchVolunteeringManuallyAddVolunteeringTime({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type VolunteeringConfirmVolunteeringRecordPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  volunteeringRecordId: string;
};

export type VolunteeringConfirmVolunteeringRecordError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringConfirmVolunteeringRecordVariables = {
  pathParams: VolunteeringConfirmVolunteeringRecordPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringConfirmVolunteeringRecord = (
  variables: VolunteeringConfirmVolunteeringRecordVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    VolunteeringConfirmVolunteeringRecordError,
    undefined,
    {},
    {},
    VolunteeringConfirmVolunteeringRecordPathParams
  >({
    method: 'post',
    url: '/api/v1/Volunteering/volunteering-record/{volunteeringRecordId}/confirm-volunteer-time',
    ...variables,
    signal,
  });

export const useVolunteeringConfirmVolunteeringRecord = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VolunteeringConfirmVolunteeringRecordError,
      VolunteeringConfirmVolunteeringRecordVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    VolunteeringConfirmVolunteeringRecordError,
    VolunteeringConfirmVolunteeringRecordVariables
  >({
    mutationFn: (variables: VolunteeringConfirmVolunteeringRecordVariables) =>
      fetchVolunteeringConfirmVolunteeringRecord({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type VolunteeringModifyVolunteeringRecordPathParams = {
  volunteeringRecordId: string;
};

export type VolunteeringModifyVolunteeringRecordError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringModifyVolunteeringRecordVariables = {
  body?: Schemas.UpdateVolunteerRecord;
  pathParams: VolunteeringModifyVolunteeringRecordPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringModifyVolunteeringRecord = (
  variables: VolunteeringModifyVolunteeringRecordVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    undefined,
    VolunteeringModifyVolunteeringRecordError,
    Schemas.UpdateVolunteerRecord,
    {},
    {},
    VolunteeringModifyVolunteeringRecordPathParams
  >({
    method: 'post',
    url: '/api/v1/Volunteering/volunteering-record/{volunteeringRecordId}/update',
    ...variables,
    signal,
  });

export const useVolunteeringModifyVolunteeringRecord = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VolunteeringModifyVolunteeringRecordError,
      VolunteeringModifyVolunteeringRecordVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useCrowdCoursingContext();
  return reactQuery.useMutation<
    undefined,
    VolunteeringModifyVolunteeringRecordError,
    VolunteeringModifyVolunteeringRecordVariables
  >({
    mutationFn: (variables: VolunteeringModifyVolunteeringRecordVariables) =>
      fetchVolunteeringModifyVolunteeringRecord({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type VolunteeringGetMyVolunteeringRecordsError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringGetMyVolunteeringRecordsResponse = Schemas.VolunteeringRecordDto[];

export type VolunteeringGetMyVolunteeringRecordsVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringGetMyVolunteeringRecords = (
  variables: VolunteeringGetMyVolunteeringRecordsVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    VolunteeringGetMyVolunteeringRecordsResponse,
    VolunteeringGetMyVolunteeringRecordsError,
    undefined,
    {},
    {},
    {}
  >({ method: 'get', url: '/api/v1/Volunteering/my-records', ...variables, signal });

export const useVolunteeringGetMyVolunteeringRecords = <TData = VolunteeringGetMyVolunteeringRecordsResponse>(
  variables: VolunteeringGetMyVolunteeringRecordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      VolunteeringGetMyVolunteeringRecordsResponse,
      VolunteeringGetMyVolunteeringRecordsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    VolunteeringGetMyVolunteeringRecordsResponse,
    VolunteeringGetMyVolunteeringRecordsError,
    TData
  >({
    queryFn: ({ signal }) => fetchVolunteeringGetMyVolunteeringRecords({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'volunteeringGetMyVolunteeringRecords',
      path: '/api/v1/Volunteering/my-records',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type VolunteeringGetMyEducatorVolunteeringRecordsError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringGetMyEducatorVolunteeringRecordsResponse = Schemas.VolunteeringRecordDto[];

export type VolunteeringGetMyEducatorVolunteeringRecordsVariables = CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringGetMyEducatorVolunteeringRecords = (
  variables: VolunteeringGetMyEducatorVolunteeringRecordsVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    VolunteeringGetMyEducatorVolunteeringRecordsResponse,
    VolunteeringGetMyEducatorVolunteeringRecordsError,
    undefined,
    {},
    {},
    {}
  >({ method: 'get', url: '/api/v1/Volunteering/my-records/educator', ...variables, signal });

export const useVolunteeringGetMyEducatorVolunteeringRecords = <
  TData = VolunteeringGetMyEducatorVolunteeringRecordsResponse
>(
  variables: VolunteeringGetMyEducatorVolunteeringRecordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      VolunteeringGetMyEducatorVolunteeringRecordsResponse,
      VolunteeringGetMyEducatorVolunteeringRecordsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    VolunteeringGetMyEducatorVolunteeringRecordsResponse,
    VolunteeringGetMyEducatorVolunteeringRecordsError,
    TData
  >({
    queryFn: ({ signal }) =>
      fetchVolunteeringGetMyEducatorVolunteeringRecords({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'volunteeringGetMyEducatorVolunteeringRecords',
      path: '/api/v1/Volunteering/my-records/educator',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type VolunteeringGetMyAdminVolunteeringRecordsPathParams = {
  /**
   * Required, non-empty
   *
   * @format uuid
   */
  bubbleId: string;
};

export type VolunteeringGetMyAdminVolunteeringRecordsError = Fetcher.ErrorWrapper<undefined>;

export type VolunteeringGetMyAdminVolunteeringRecordsResponse = Schemas.VolunteeringRecordDto[];

export type VolunteeringGetMyAdminVolunteeringRecordsVariables = {
  pathParams: VolunteeringGetMyAdminVolunteeringRecordsPathParams;
} & CrowdCoursingContext['fetcherOptions'];

export const fetchVolunteeringGetMyAdminVolunteeringRecords = (
  variables: VolunteeringGetMyAdminVolunteeringRecordsVariables,
  signal?: AbortSignal
) =>
  crowdCoursingFetch<
    VolunteeringGetMyAdminVolunteeringRecordsResponse,
    VolunteeringGetMyAdminVolunteeringRecordsError,
    undefined,
    {},
    {},
    VolunteeringGetMyAdminVolunteeringRecordsPathParams
  >({ method: 'get', url: '/api/v1/Volunteering/my-records/admin/{bubbleId}', ...variables, signal });

export const useVolunteeringGetMyAdminVolunteeringRecords = <TData = VolunteeringGetMyAdminVolunteeringRecordsResponse>(
  variables: VolunteeringGetMyAdminVolunteeringRecordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      VolunteeringGetMyAdminVolunteeringRecordsResponse,
      VolunteeringGetMyAdminVolunteeringRecordsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCrowdCoursingContext(options);
  return reactQuery.useQuery<
    VolunteeringGetMyAdminVolunteeringRecordsResponse,
    VolunteeringGetMyAdminVolunteeringRecordsError,
    TData
  >({
    queryFn: ({ signal }) =>
      fetchVolunteeringGetMyAdminVolunteeringRecords({ ...fetcherOptions, ...variables }, signal),
    queryKey: queryKeyFn({
      operationId: 'volunteeringGetMyAdminVolunteeringRecords',
      path: '/api/v1/Volunteering/my-records/admin/{bubbleId}',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/api/v1/background-checks/products';
      operationId: 'backgroundCheckGetProducts';
      variables: BackgroundCheckGetProductsVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}';
      operationId: 'bubbleGetBubble';
      variables: BubbleGetBubbleVariables;
    }
  | {
      path: '/api/v1/Bubbles/users/{userId}';
      operationId: 'bubbleGetBubblesForUser';
      variables: BubbleGetBubblesForUserVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/role';
      operationId: 'bubbleGetRolesForBulkAdd';
      variables: BubbleGetRolesForBulkAddVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/users';
      operationId: 'bubbleGetUsersForBubble';
      variables: BubbleGetUsersForBubbleVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/upcoming-rsvps';
      operationId: 'bubbleGetUpcomingRsvps';
      variables: BubbleGetUpcomingRsvpsVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/events';
      operationId: 'bubbleGetEventsForBubble';
      variables: BubbleGetEventsForBubbleVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/picture';
      operationId: 'bubbleGetBubblePicture';
      variables: BubbleGetBubblePictureVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/candidates';
      operationId: 'bubbleGetCandidates';
      variables: BubbleGetCandidatesVariables;
    }
  | {
      path: '/api/v1/Calendar';
      operationId: 'calendarGetUserCalendar';
      variables: CalendarGetUserCalendarVariables;
    }
  | {
      path: '/api/v1/event/{eventId}';
      operationId: 'eventGetEvent';
      variables: EventGetEventVariables;
    }
  | {
      path: '/api/v1/event/{eventId}/check-in.svg';
      operationId: 'eventGetEventCheckInQrCode';
      variables: EventGetEventCheckInQrCodeVariables;
    }
  | {
      path: '/api/v1/event/{eventId}/picture';
      operationId: 'eventGetEventPicture';
      variables: EventGetEventPictureVariables;
    }
  | {
      path: '/api/v1/Login/whoami';
      operationId: 'loginWhoAmI';
      variables: LoginWhoAmIVariables;
    }
  | {
      path: '/api/v1/Organization/{organizationId}';
      operationId: 'organizationGetOrganization';
      variables: OrganizationGetOrganizationVariables;
    }
  | {
      path: '/api/v1/Organization/{organizationId}/picture';
      operationId: 'organizationGetOrganizationPicture';
      variables: OrganizationGetOrganizationPictureVariables;
    }
  | {
      path: '/api/v1/Organization/{organizationId}/donations';
      operationId: 'organizationGetOrganizationDonations';
      variables: OrganizationGetOrganizationDonationsVariables;
    }
  | {
      path: '/api/v1/Organization/{organizationId}/members';
      operationId: 'organizationGetOrganizationMembers';
      variables: OrganizationGetOrganizationMembersVariables;
    }
  | {
      path: '/api/v1/Organization/roles';
      operationId: 'organizationGetOrganizationRoles';
      variables: OrganizationGetOrganizationRolesVariables;
    }
  | {
      path: '/api/v1/Profile/{userId}/payment-account';
      operationId: 'paymentGetPaymentAccount';
      variables: PaymentGetPaymentAccountVariables;
    }
  | {
      path: '/api/v1/Profile/{userId}/payment-account/login';
      operationId: 'paymentGetStripeLogin';
      variables: PaymentGetStripeLoginVariables;
    }
  | {
      path: '/api/v1/Profile/{userId}';
      operationId: 'profileGetProfile';
      variables: ProfileGetProfileVariables;
    }
  | {
      path: '/api/v1/Profile/{userId}/picture';
      operationId: 'profileGetProfilePicture';
      variables: ProfileGetProfilePictureVariables;
    }
  | {
      path: '/api/v1/Profile/states';
      operationId: 'profileGetStates';
      variables: ProfileGetStatesVariables;
    }
  | {
      path: '/api/Report/categories';
      operationId: 'reportGetCategories';
      variables: ReportGetCategoriesVariables;
    }
  | {
      path: '/api/Report/unresolved';
      operationId: 'reportGetUnresolvedReports';
      variables: ReportGetUnresolvedReportsVariables;
    }
  | {
      path: '/api/Resume/valid-file-extensions';
      operationId: 'resumeGetValidFileExtensions';
      variables: ResumeGetValidFileExtensionsVariables;
    }
  | {
      path: '/api/Resume/user/{userId}';
      operationId: 'resumeGetUserResume';
      variables: ResumeGetUserResumeVariables;
    }
  | {
      path: '/api/v1/room/{roomId}';
      operationId: 'roomGetRoom';
      variables: RoomGetRoomVariables;
    }
  | {
      path: '/api/v1/room/my-rooms/{userId}';
      operationId: 'roomGetMyRooms';
      variables: RoomGetMyRoomsVariables;
    }
  | {
      path: '/api/v1/room/{roomId}/picture';
      operationId: 'roomGetRoomPicture';
      variables: RoomGetRoomPictureVariables;
    }
  | {
      path: '/api/v1/room/{roomId}/check-in.svg';
      operationId: 'roomGetRoomCheckInQrCode';
      variables: RoomGetRoomCheckInQrCodeVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/schools';
      operationId: 'schoolDistrictGetSchoolsForSchoolDistrict';
      variables: SchoolDistrictGetSchoolsForSchoolDistrictVariables;
    }
  | {
      path: '/api/v1/SchoolDistrict/{schoolDistrictId}/schools';
      operationId: 'schoolDistrictGetSearchableSchoolsForSchoolDistrict';
      variables: SchoolDistrictGetSearchableSchoolsForSchoolDistrictVariables;
    }
  | {
      path: '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/vetted-users';
      operationId: 'schoolDistrictGetVettedUsers';
      variables: SchoolDistrictGetVettedUsersVariables;
    }
  | {
      path: '/api/v1/translations/translatable-languages';
      operationId: 'translationGetTranslatableLanguages';
      variables: TranslationGetTranslatableLanguagesVariables;
    }
  | {
      path: '/api/v1/User/{userId}/public';
      operationId: 'userGetPublicUserInfo';
      variables: UserGetPublicUserInfoVariables;
    }
  | {
      path: '/api/v1/User/{userId}/rsvps';
      operationId: 'userGetUserRsvpsSearch';
      variables: UserGetUserRsvpsSearchVariables;
    }
  | {
      path: '/api/v1/User/{userId}/user-history';
      operationId: 'userGetUserHistoryInformation';
      variables: UserGetUserHistoryInformationVariables;
    }
  | {
      path: '/api/v1/Volunteering/my-records';
      operationId: 'volunteeringGetMyVolunteeringRecords';
      variables: VolunteeringGetMyVolunteeringRecordsVariables;
    }
  | {
      path: '/api/v1/Volunteering/my-records/educator';
      operationId: 'volunteeringGetMyEducatorVolunteeringRecords';
      variables: VolunteeringGetMyEducatorVolunteeringRecordsVariables;
    }
  | {
      path: '/api/v1/Volunteering/my-records/admin/{bubbleId}';
      operationId: 'volunteeringGetMyAdminVolunteeringRecords';
      variables: VolunteeringGetMyAdminVolunteeringRecordsVariables;
    };
