import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type ModifiedCheckBoxProps = Omit<
  MuiCheckboxProps,
  'onChange' | 'onBlur' | 'onFocus' | 'value' | 'checked' | 'type' | 'multiple'
> & { 'data-cy'?: string; 'data-testid'?: string; name: string };

export type CheckboxValue = readonly string[] | undefined;

export interface CheckboxProps extends FieldProps<CheckboxValue, FieldRenderProps<CheckboxValue>> {
  'data-cy'?: string;
  'data-testid'?: string;
  helperText?: string;
  label?: string;
  checkboxes: { checkboxProps: ModifiedCheckBoxProps; formControlLabelProps: Omit<FormControlLabelProps, 'control'> }[];
  formControlProps?: Omit<FormControlProps, 'error'>;
}

export const Checkbox = ({ checkboxes, formControlProps, helperText, label, ...props }: CheckboxProps) => {
  return (
    <Field
      render={({ input: { onChange, value }, meta: { error } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const updatedSelected = event.target.checked
            ? [...(value || []), event.target.name]
            : (value || []).filter((selected) => selected !== event.target.name);

          onChange({
            ...event,
            target: { ...event.target, value: updatedSelected.length > 0 ? updatedSelected : undefined },
          });
        };

        return (
          <>
            <FormControl error={Boolean(error)} {...formControlProps}>
              {label && <FormLabel>{label}</FormLabel>}
              <FormGroup>
                {checkboxes.map(({ formControlLabelProps, checkboxProps }) => {
                  return (
                    <FormControlLabel
                      control={
                        <MuiCheckbox
                          {...checkboxProps}
                          checked={(value || []).some((selection) => selection === checkboxProps.name)}
                          onChange={handleChange}
                        />
                      }
                      key={checkboxProps.name}
                      {...formControlLabelProps}
                    />
                  );
                })}
              </FormGroup>
              {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
            </FormControl>
          </>
        );
      }}
      {...props}
    />
  );
};
