import * as CrowdcoursingApiContext from '@crowdcoursing/api/context';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ReduxProvider, store } from '~services/providers/ReduxProvider';

import { LoginActions, loginWithToken } from './actions/login';
import { serviceOptions } from './services/ApiService';
import { createInstance } from './services/AxiosInstance';
import { QueryProvider } from './services/providers/QueryProvider';
import { browserRouter, memoryRouter } from './services/router/router';
import { MaterialProvider } from './ui/providers/MaterialProvider';

serviceOptions.axios = createInstance(store);

CrowdcoursingApiContext.configureApiContext(() => {
  return {
    baseUrl: import.meta.env.REACT_APP_BACKEND_URL,
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
    unauthorizedCallback() {
      store.dispatch(LoginActions.logout());
    },
  };
});

// expose a helper for e2e to automatically login
if (store.getState().features.loginAsTestUser) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)['e2e:login'] = (token: string) => loginWithToken(token, store.dispatch);
}

export interface AppProps {
  useMemoryRouter?: boolean;
}

export const App = ({ useMemoryRouter }: AppProps) => {
  return <RouterProvider router={useMemoryRouter ? memoryRouter : browserRouter} />;
};

const WrappedApp = () => {
  return (
    <ReduxProvider>
      <QueryProvider>
        <MaterialProvider>
          <Suspense>
            <App />
          </Suspense>
        </MaterialProvider>
      </QueryProvider>
    </ReduxProvider>
  );
};

export default WrappedApp;
