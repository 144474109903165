import { lazy } from 'react';

/**
 * @description Lazily load the `LegacyAppNavigationLayout` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const LegacyAppNavigationLayoutAsync = lazy(async function () {
  return import('./AppNavigationLayout').then((module) => ({ default: module.LegacyAppNavigationLayout }));
});

/**
 * @description Lazily load the `AppNavigationLayout` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const AppNavigationLayoutAsync = lazy(async function () {
  return import('./AppNavigationLayout').then((module) => ({ default: module.AppNavigationLayout }));
});
