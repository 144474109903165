import { createAsyncThunk } from '@reduxjs/toolkit';

import { type CreateRoomRequest, type RoomDto, RoomService, type UpdateRoomRequest } from '../services/ApiService';
import { errorToast, successToast } from '../state/notifications';
import { type State } from '../state/state';

const tDefaultErrorMessage = 'Whoops! Sorry about that. Please try again.';
const tSuccessMessageSeriesCreate = (name: string) => `${name} Series created!`;
const tErrorMessageSeriesCreate = (name: string) => `Failed to create ${name} Series`;

/** create a new room, uploading an image if one is selected */
export const createPod = createAsyncThunk(
  'myPods/createPod',
  async (
    { body, onSuccess }: { body: CreateRoomRequest & { image: File | undefined }; onSuccess?: (room: RoomDto) => void },
    { dispatch }
  ) => {
    const room = await RoomService.room2({ body });
    if (body.image) {
      await dispatch(savePicture({ formFile: body.image, roomId: room.id! }));
    }
    onSuccess?.(room);
    dispatch(successToast({ message: 'Room created successfully!' }));
  }
);

export const createSeries = createAsyncThunk(
  'sessions/createSeries',
  async (args: Parameters<(typeof RoomService)['meetingTimeSeries']>[0], { dispatch }) => {
    const name = args?.body?.seriesName ?? 'New';
    try {
      await RoomService.meetingTimeSeries(args);
      dispatch(successToast({ message: tSuccessMessageSeriesCreate(name) }));
    } catch (error: unknown) {
      dispatch(errorToast({ message: tErrorMessageSeriesCreate(name) }));
      window.console.error(error);
    }
  }
);

const tSuccessMessageSeriesUpdate = (name: string) => `${name} Series updated!`;
const tErrorMessageSeriesUpdate = (name: string) => `Failed to update ${name} Series`;

export const updateSeries = createAsyncThunk(
  'sessions/updateSeries',
  async (args: Parameters<(typeof RoomService)['meetingTimeSeries1']>[0], { dispatch }) => {
    const name = args?.body?.seriesName;
    if (!name) throw new Error('bad args');
    try {
      await RoomService.meetingTimeSeries1(args);
      dispatch(successToast({ message: tSuccessMessageSeriesUpdate(name) }));
    } catch (error: unknown) {
      dispatch(errorToast({ message: tErrorMessageSeriesUpdate(name) }));
    }
  }
);

const tSuccessMessageSeriesDelete = (name: string) => `${name} Series deleted!`;
const tErrorMessageSeriesDelete = (name: string) => `Failed to delete ${name} Series`;

export const deleteSeries = createAsyncThunk(
  'sessions/deleteSeries',
  async (
    { seriesName, args }: { seriesName: string; args: Parameters<(typeof RoomService)['meetingTimeSeries2']>[0] },
    { dispatch }
  ) => {
    try {
      await RoomService.meetingTimeSeries2(args);
      dispatch(successToast({ message: tSuccessMessageSeriesDelete(seriesName) }));
    } catch (error: unknown) {
      dispatch(errorToast({ message: tErrorMessageSeriesDelete(seriesName) }));
    }
  }
);

/**
 * load a room from the backend and mark it as selected
 *
 * TODO: replace with graphql
 */
export const getSelectedPod = createAsyncThunk(
  'app/getPod',
  async (roomId: string) => await RoomService.room({ roomId })
);

export const getMyPods = createAsyncThunk('myPods/getMyPods', async (userId: string) => {
  return await RoomService.myRooms({ userId });
});

// we listen for this in the app reducer to set the selected pod to undefined
export const clearSelectedPod = createAsyncThunk('app/clearSelectedPod', async (userId: string) => {
  return getMyPods(userId);
});

// we listen for this in the app reducer and update the pod
export const setSelectedPod = createAsyncThunk('app/setSelectedPod', async (roomId: string, { dispatch, getState }) => {
  const userId = (getState() as any).user.id;
  await dispatch(getMyPods(userId));

  const pods: RoomDto[] = (getState() as any).pods.myPods;
  const pod = pods.find(({ id }) => id === roomId);
  if (!pod) {
    throw new Error(`(sessions)(updateSession) - unable to find pod with id (${roomId}) for user (${userId})`);
  }

  return pod;
});

/* update */
export const updatePod = createAsyncThunk(
  'myPods/updatePod',
  async ({ body, onSuccess }: { body: UpdateRoomRequest; onSuccess?: () => void }, { dispatch, getState }) => {
    const userId = (getState() as State).user?.id;
    if (!userId) throw new Error('bad args');
    if (!body.roomId) throw new Error('bad args');
    await RoomService.update({ body, roomId: body.roomId });
    await dispatch(clearSelectedPod(userId));
    dispatch(successToast({ message: 'Room updated successfully!' }));
    onSuccess?.();
  }
);

export const requestMembership = createAsyncThunk(
  'app/requestMembershipPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.request(body);
      // updated request Ids are in this heavy data fetching endpoint
      const room = await RoomService.room({ roomId: body.roomId });
      if (room.isPublic) {
        dispatch(successToast({ message: 'Join successful!' }));
      } else {
        dispatch(successToast({ message: 'Join request sent!' }));
      }
      return room;
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const addMember = createAsyncThunk(
  'app/addMemberPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.add(body);
      dispatch(successToast({ message: 'User added to room!' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const addFacilitator = createAsyncThunk(
  'app/addFacilitatorPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.facilitator(body);
      dispatch(successToast({ message: 'Facilitator added!' }));
    } catch (exception: unknown) {
      window.console.log('exception: ', JSON.stringify(exception, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const addOwner = createAsyncThunk(
  'app/addOwnerPod',
  async ({ body, onSuccess }: { body: { roomId: string; userId: string }; onSuccess?: () => void }, { dispatch }) => {
    try {
      await RoomService.owner(body);
      await dispatch(setSelectedPod(body.roomId));
      dispatch(successToast({ message: 'Ownership changed successfully!' }));
      onSuccess?.();
    } catch (exception: unknown) {
      window.console.log('exception: ', JSON.stringify(exception, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const removeFacilitator = createAsyncThunk(
  'app/removeFacilitatorPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.facilitator1(body);
      dispatch(successToast({ message: 'Facilitator permissions removed successfully.' }));
    } catch (exception: unknown) {
      window.console.log('exception: ', JSON.stringify(exception, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const rejectMember = createAsyncThunk(
  'app/rejectMemberPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.deny(body);
      dispatch(successToast({ message: 'Member request rejected.' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

/** @deprecated */
export const deletePod = createAsyncThunk(
  'myPods/deletePod',
  async ({ roomId, onSuccess }: { roomId: string; onSuccess?: () => void }, { dispatch }) => {
    await RoomService.room1({ roomId });
    dispatch(successToast({ message: 'Room deleted successfully!' }));
    onSuccess?.();
    return roomId;
  }
);

export const removeMember = createAsyncThunk(
  'app/removeMemberPod',
  async (body: { roomId: string; userId: string }, { dispatch }) => {
    try {
      await RoomService.remove(body);
      // TODO: drop this re-fetch once my pods are converted to use graphql - this
      // only happens to try to keep our redux DB mirror in sync
      //
      // updated member Ids are in this heavy data fetching endpoint
      const room = await RoomService.room({ roomId: body.roomId });
      dispatch(successToast({ message: 'Member removed!' }));
      return room;
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

type SaveParams = {
  roomId: string;
  formFile: File;
};

export const savePicture = createAsyncThunk('room/savePicture', async (p: SaveParams) => {
  await RoomService.picture(p);
  return { roomId: p.roomId };
});
export const deletePicture = createAsyncThunk('room/deletePicture', async (roomId: string) => {
  await RoomService.picture1({ roomId });
  return { roomId };
});
