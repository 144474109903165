import { lazy } from 'react';

/**
 * @description Lazily load the `Login` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const LoginAsync = lazy(async function () {
  return import('./Login').then((module) => ({ default: module.Login }));
});
