import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, LinkProps } from '@mui/material';

export type ExternalLinkProps = LinkProps & {
  url: string;
  title?: string;
};

/** @deprecated Prefer `~ui/atoms/Link/Link` with `variant="external" */
export const ExternalLink = ({ url, children, ...linkProps }: ExternalLinkProps) => {
  return (
    <Link href={url} rel="noopener noreferrer" target="_blank" variant="body1" {...linkProps}>
      {children}
      <OpenInNewIcon fontSize="inherit" />
    </Link>
  );
};
