import { Location } from 'react-router-dom';

import { Dispatch } from '../hooks/useTypedRedux';
import { LoginService, ProfileDto } from '../services/ApiService';
import { Route } from '../services/router/Route';
import { browserRouter } from '../services/router/router';
import { actions as authActions } from '../state/auth';
import { actions as userActions } from '../state/userSlice';

export type LoginRedirect = {
  from: Location;
};

type LoginProps = {
  email?: string;
  phone?: string;
  redirectState?: LoginRedirect;
};

const login =
  ({ email, phone, redirectState }: LoginProps) =>
  async (dispatch: any) => {
    dispatch(authActions.loginRequested());

    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const result = await LoginService.login({
        body: { email, phoneNumber: phone, timezone },
      });

      if (result === '') {
        // @ts-expect-error - email or phone is required and validated by form and backend
        dispatch(authActions.loginSuccess(email || phone));
        browserRouter.navigate(Route.verify(), { state: redirectState });
      }
    } catch (err) {
      dispatch(authActions.loginFailed());
    }
  };

/**
 * login via a token, loading any secondary data
 *
 * This method is used by e2e tests, should contain any additional fetches
 * to load state
 */
export const loginWithToken = async (token: string, dispatch: Dispatch): Promise<void> => {
  dispatch(authActions.verifySuccess(token));
  // use the token to get more data
  const profile = await LoginService.whoami();
  dispatch(successWhoami(profile));
};

export const successWhoami = (user: ProfileDto) => (dispatch: Dispatch) => {
  dispatch(
    userActions.whoamiSuccess({
      user,
    })
  );
};

export const LoginActions = { login, logout: userActions.logout };
