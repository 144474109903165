import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { MutableState, Tools } from 'final-form';
import arrayMutators from 'final-form-arrays';
import {
  Form as ReactFinalFormForm,
  type FormProps as ReactFinalFormFormProps,
  type FormRenderProps as ReactFinalFormFormRenderProps,
} from 'react-final-form';

/* eslint-disable @typescript-eslint/no-explicit-any */
/** Custom array mutator to empty the array. Useful for cleanup functions */
function clear([name]: any[], state: MutableState<any>, { changeValue }: Tools<any>) {
  changeValue(state, name, (_values) => []);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export type FormRenderProps<FormValues = Record<PropertyKey, unknown>> = ReactFinalFormFormRenderProps<FormValues>;
export type FormBaseProps<FormValues = Record<PropertyKey, unknown>> = ReactFinalFormFormProps<FormValues>;
export type FormSlotProps = { slotProps?: { renderContainer?: BoxProps<'form'> } };
export type FormProps<FormValues = Record<PropertyKey, unknown>> = FormBaseProps<FormValues> & FormSlotProps;

export function Form<FormValues>({ children, onSubmit, slotProps, ...props }: FormProps<FormValues>) {
  return (
    <ReactFinalFormForm<FormValues>
      {...props}
      mutators={{ ...arrayMutators, clear, ...props.mutators }}
      onSubmit={async (values, form) => {
        if (form.getState().hasValidationErrors) {
          return;
        }

        try {
          await onSubmit(values, form);
        } catch (exception) {
          console.error(`(Form)(onSubmit): Error submitting form data.`);
          console.error({ data: values, ...form.getState() });
          console.error(JSON.stringify({ exception }, undefined, 2));
        }
      }}
      render={(renderProps) => {
        return (
          <Box noValidate {...slotProps?.renderContainer} component="form" onSubmit={renderProps.handleSubmit}>
            {typeof children === 'function' ? children(renderProps) : children}
          </Box>
        );
      }}
    />
  );
}
