import { lazy } from 'react';

/**
 * @description Lazily load the `Archived` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const ArchivedAsync = lazy(async function () {
  return import('./Archived').then((module) => ({ default: module.Archived }));
});
