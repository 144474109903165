import { lazy } from 'react';

/**
 * @description Lazily load the `CreateProfile` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const CreateProfileAsync = lazy(async function () {
  return import('./CreateProfile').then((module) => ({ default: module.CreateProfile }));
});
