import { useCallback } from 'react';

import { makeRoomAvatarUrl } from '../utilities/utils';
import { useSelector } from './useTypedRedux';

/**
 * builds a URL to this room's image, with a cache-busting parameter
 *
 * @param roomId
 * @returns URL for the room image
 */
export const useRoomImageUrl = (roomId: string): string => {
  const cacheBuster = useSelector((state) => state.app.roomImageUrlCacheBusters[roomId]);

  const url = new URL(makeRoomAvatarUrl(roomId));
  if (cacheBuster) {
    // silly way to bust caches. TODO: use HTTP cache headers/etags on the
    // backend
    url.searchParams.append('t', cacheBuster);
  }
  return url.href;
};

export function useGetRoomImageUrl() {
  const cacheBusters = useSelector(({ app: { roomImageUrlCacheBusters } }) => roomImageUrlCacheBusters);
  return useCallback(
    /** Generate room avatar url */
    function getRoomImageUrl(roomId: string) {
      const url = new URL(makeRoomAvatarUrl(roomId));
      if (cacheBusters[roomId]) {
        url.searchParams.append('t', cacheBusters[roomId]);
      }
      return url.href;
    },
    [cacheBusters]
  );
}
