import { lazy } from 'react';

/**
 * @description Lazily load the `ResumeBuilder` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const ResumeBuilderAsync = lazy(async function () {
  return import('./ResumeBuilder').then((module) => ({ default: module.ResumeBuilder }));
});
