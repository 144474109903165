/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url,
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class BackgroundCheckService {
  /**
   * find background check products
   */
  static products(
    params: {
      /**  */
      bubbleId?: string;
      /**  */
      schoolDistrictId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BackgroundCheckProduct[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/background-checks/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { BubbleId: params['bubbleId'], SchoolDistrictId: params['schoolDistrictId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * A user wanting to pay for their own background check
   */
  static selfPay(
    params: {
      /** requestBody */
      body?: SelfPayRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelfPayResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/background-checks/selfPay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create a background check paid for by the district
   */
  static districtPay(
    params: {
      /**  */
      bubbleId: string;
      /** requestBody */
      body?: DistrictPayRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/background-checks/bubbles/{bubbleId}/districtPay';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BubbleService {
  /**
   *
   */
  static bubbles(
    params: {
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BubbleDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * For use during the room creation process
   */
  static users(
    params: {
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BubbleDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/users/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves a list of roles for bulk adding users for a given bubble
   */
  static role(
    params: {
      /** Required, non-empty */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/role';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Only Bubble Admins are allowed see user states for a given bubble
   */
  static users1(
    params: {
      /** Required, non-empty */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistrictSummary[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/users';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Only Bubble Admins are allowed to add and remove roles
   */
  static users2(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /** requestBody */
      body?: BulkAddUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/users';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Bubble Admins are allowed to change the roles for folks in their bubble or any of its child bubbles
   */
  static role1(
    params: {
      /** Required, non-null */
      bubbleId: string;
      /** Required, non-null */
      userId: string;
      /** Required, must meet expected values */
      role: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/users/{userId}/role/{role}"';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{role}', params['role'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Bubble Admins are allowed to change the roles for folks in their bubble or any of its child bubbles
   */
  static role2(
    params: {
      /** Required, non-null */
      bubbleId: string;
      /** Required, non-null */
      userId: string;
      /** Required, must meet expected values */
      role: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/users/{userId}/role/{role}';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{role}', params['role'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upcomingRsvps(
    params: {
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RsvpResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/upcoming-rsvps';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static events(
    params: {
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/events';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Uploads a profile picture for a given bubble
   */
  static picture(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/picture';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the picture for a given bubble
   */
  static picture1(
    params: {
      /** Required, non-null */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/picture';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves a CrowdCoursing.Common.DTOs.Statistics.DynamicStatsDto for a given Bubble
   */
  static dynamicStats(
    params: {
      /**  */
      bubbleId: string;
      /** requestBody */
      body?: DynamicStatsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DynamicStatsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/dynamic-stats';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets the candidate as a favorite or removes them as a favorite based upon the isFavorite optional param
   */
  static favorite(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /** Required, non-empty */
      userId: string;
      /** Optional, defaults to true */
      isFavorite?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/candidates/{userId}/favorite';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { isFavorite: params['isFavorite'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves all of the candidates for a given bubble
   */
  static candidates(
    params: {
      /** Required, non-empty */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/candidates';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates an interview event for the caller and the candidate for the given bubble
   */
  static interview(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/candidates/{userId}/interview';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CalendarService {
  /**
 * Retrieves the user's upcoming RSVPs and hosted events via ics file
https://stackoverflow.com/questions/46033843/how-to-create-ics-file-using-c
 */
  static calendar(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Calendar';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class EmailService {
  /**
   * Retrieves the community metrics if the caller is an admin of the community bubble
   */
  static bulk(
    params: {
      /** requestBody */
      body?: BulkEmailDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/email/bulk';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EndToEndService {
  /**
   *
   */
  static seedDb(options: IRequestOptions = {}): Promise<SeedDbResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/e2e/seed-db';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventService {
  /**
   * Gets an event for a given EventId
   */
  static event(
    params: {
      /** Required, non-null */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Soft-deletes a session
   */
  static event1(
    params: {
      /** Required, non-empty */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a CrowdCoursing.Domain.Entities.Events.Event based upon a given CrowdCoursing.Common.DTOs.Events.EventDto
   */
  static update(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** requestBody */
      body?: UpdateEventRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/update';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a CrowdCoursing.Domain.Entities.Events.Event based upon a given CrowdCoursing.Common.DTOs.Events.EventDto
   */
  static event2(
    params: {
      /** requestBody */
      body?: CreateEventRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a user to an CrowdCoursing.Domain.Entities.Events.Event
   */
  static add(
    params: {
      /**  */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/add/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a user to a CrowdCoursing.Domain.Entities.Events.Event by email. Automatically adds them to the system if they don't exist
   */
  static addMemberByEmail(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** requestBody */
      body?: AddMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/add-member-by-email';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a user to request access to a CrowdCoursing.Domain.Entities.Events.Event.
   */
  static request(
    params: {
      /**  */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/request/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static meetingTime(
    params: {
      /**  */
      eventId: string;
      /** requestBody */
      body?: CreateMeetingTimeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a user to request access to a CrowdCoursing.Domain.Entities.Events.Event
   */
  static deny(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/request/{userId}/deny';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a given CrowdCoursing.Domain.Entities.Users.User from a given CrowdCoursing.Domain.Entities.Events.Event
   */
  static remove(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/remove/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * A CrowdCoursing.Domain.Entities.Users.User wants to leave a given CrowdCoursing.Domain.Entities.Events.Event
   */
  static leave(
    params: {
      /** Required, non-empty */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/leave';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a meeting time
   */
  static meetingTime1(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      meetingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{meetingId}', params['meetingId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Updates meeting time within an given event
   */
  static meetingTime2(
    params: {
      /** Required, non-empty */
      meetingId: string;
      /**  */
      eventId: string;
      /** requestBody */
      body?: UpdateMeetingTimeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}';
      url = url.replace('{meetingId}', params['meetingId'] + '');
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a meeting time rsvp for a user
   */
  static rsvp(
    params: {
      /** Required, non-empty */
      meetingId: string;
      /**  */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}/rsvp';
      url = url.replace('{meetingId}', params['meetingId'] + '');
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a meeting time rsvp for a user
   */
  static rsvp1(
    params: {
      /** Required, non-empty */
      meetingId: string;
      /**  */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}/rsvp';
      url = url.replace('{meetingId}', params['meetingId'] + '');
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a meeting time rsvp for a user
   */
  static noShow(
    params: {
      /** Required, non-empty */
      meetingId: string;
      /** Required, non-empty */
      userId: string;
      /**  */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}/user/{userId}/no-show';
      url = url.replace('{meetingId}', params['meetingId'] + '');
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a meeting time rsvp for a user
   */
  static noShow1(
    params: {
      /** Required, non-empty */
      meetingId: string;
      /** Required, non-empty */
      userId: string;
      /**  */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/meeting-time/{meetingId}/user/{userId}/no-show';
      url = url.replace('{meetingId}', params['meetingId'] + '');
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a session resource to a given session
   */
  static resource(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** requestBody */
      body?: ResourceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/resource';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a resource from a given session
   */
  static resource1(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      resourceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/resource/{resourceId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{resourceId}', params['resourceId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a facilitator to the event
   */
  static facilitator(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/facilitator/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a facilitator from being a facilitator of the event
   */
  static facilitator1(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/facilitator/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates an event milestone
   */
  static milestones(
    params: {
      /** Require, non-empty */
      eventId: string;
      /** requestBody */
      body?: CreateEventMilestoneRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/milestones';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes an event milestone
   */
  static milestones1(
    params: {
      /** Require, non-empty */
      eventId: string;
      /** Require, non-empty */
      milestoneId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/milestones/{milestoneId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{milestoneId}', params['milestoneId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates an event task
   */
  static tasks(
    params: {
      /** Require, non-empty */
      eventId: string;
      /** requestBody */
      body?: CreateEventTaskRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/tasks';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes an event task
   */
  static tasks1(
    params: {
      /** Require, non-empty */
      eventId: string;
      /** Require, non-empty */
      taskId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/tasks/{taskId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{taskId}', params['taskId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves an event image, redirects to uri
   */
  static checkInSvg(
    params: {
      /** Require, non-empty */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/check-in.svg';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
 * Checks the current user into the event
Requires the user to be logged in.
 */
  static checkIn(
    params: {
      /** Required, non-empty */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/check-in';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Checks a user into an event for a given phone number or email.
Does not require the user to be logged in.
 */
  static checkIn1(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      identifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/check-in/{identifier}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Uploads an image for an event
   */
  static picture(
    params: {
      /** Require, non-empty */
      eventId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/picture';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves an event image, redirects to uri
   */
  static picture1(
    params: {
      /** Require, non-empty */
      eventId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/picture';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Changes the event ownership to a given user
   */
  static owner(
    params: {
      /** Required, non-empty */
      eventId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/event/{eventId}/owner/{userId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LoginService {
  /**
 * Initiate a login, sends and authcode to the user.
Creates a user if none exists.
Will not send an Auth sms if a valid one still exists.
This is to protect against ddos attacks.
 */
  static login(
    params: {
      /** requestBody */
      body?: LoginRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Initiate a login, sends and authcode to the user.
Creates a user if none exists.
Will send an Auth sms if a valid one still exists.
 */
  static resend(
    params: {
      /** requestBody */
      body?: LoginRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Login/resend';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * complete a login by providing an auth code and the user's identifier (email or phone)
   */
  static verify(
    params: {
      /** requestBody */
      body?: VerifyLoginRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VerificationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Login/verify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * returns the details for current logged in user
   */
  static whoami(options: IRequestOptions = {}): Promise<ProfileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Login/whoami';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationService {
  /**
   * Creates an organization
   */
  static organization(
    params: {
      /** requestBody */
      body?: CreateOrganizationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets an Organization Summary
   */
  static organization1(
    params: {
      /** Required, non-empty */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationSummary> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Deletes an Organization
   */
  static organization2(
    params: {
      /** Required, non-empty */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Uploads a profile picture for a given organization
   */
  static picture(
    params: {
      /** Required, non-empty */
      organizationId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/picture';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the profile picture for a given organization
   */
  static picture1(
    params: {
      /** Required, non-null */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/picture';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Assigns a user to an organization
   */
  static user(
    params: {
      /** Required, non-empty */
      organizationId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/user/{userId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Assigns a user to an organization
   */
  static user1(
    params: {
      /** Required, non-empty */
      organizationId: string;
      /** requestBody */
      body?: AddUserToOrgDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/user';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a donation to an organization
   */
  static donation(
    params: {
      /**  */
      organizationId: string;
      /** requestBody */
      body?: OrgDonationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/donation';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a donation for an organization
   */
  static donation1(
    params: {
      /** Required, non-empty */
      donationId: string;
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/donation/{donationId}';
      url = url.replace('{donationId}', params['donationId'] + '');
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves all of an organization's donations by their donation date, descending
   */
  static donations(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrgDonationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/donations';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves all of an organization's members by their last name, ascending
   */
  static members(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/{organizationId}/members';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static roles(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Organization/roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   * Creates stripe account for the user
   */
  static paymentAccount(
    params: {
      /** current logged in user */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UrlResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/payment-account';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static paymentAccount1(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentAccountStatusDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/payment-account';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a link for the user to login to their stripe dashboard
   */
  static login(
    params: {
      /** current logged in user */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UrlResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/payment-account/login';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a checkout session to make a payment between users
   */
  static paymentSessions(
    params: {
      /** current logged in user */
      userId: string;
      /** requestBody */
      body?: PaymentSessionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UrlResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/payment-sessions';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProfileService {
  /**
   * Gets a CrowdCoursing.Common.DTOs.ProfileDto for a given CrowdCoursing.Domain.Entities.Users.User
   */
  static profile(
    params: {
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Uploads a profile picture for a given user
   */
  static picture(
    params: {
      /** Required, non-empty */
      userId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/picture';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the profile picture for a given user
   */
  static picture1(
    params: {
      /** Required, non-null */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/picture';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Updates the personal information for a user
   */
  static personalInformation(
    params: {
      /** Required, non-empty */
      userId: string;
      /** requestBody */
      body?: PersonalInfoUpdateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/personal-information';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Updates the address info for a given user
   */
  static address(
    params: {
      /** Required, non-empty */
      userId: string;
      /** requestBody */
      body?: AddressDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/{userId}/address';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets the state abbreviations
   */
  static states(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Profile/states';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReportService {
  /**
   * Saves a report
   */
  static report(
    params: {
      /** Required, non-empty */
      offender: string;
      /** requestBody */
      body?: ReportDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/{offender}';
      url = url.replace('{offender}', params['offender'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets the categories available to the user for a Report
   */
  static categories(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets all of the unresolved Reports
   */
  static unresolved(options: IRequestOptions = {}): Promise<ReportDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/unresolved';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets the moderator values for a Report
   */
  static moderatorResponse(
    params: {
      /** requestBody */
      body?: ReportDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/moderator-response';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ResumeService {
  /**
   * Returns a list of valid file extensions
   */
  static validFileExtensions(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resume/valid-file-extensions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Saves a user's resume information. Caller can only update their own information.
   */
  static update(
    params: {
      /** requestBody */
      body?: ResumeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resume/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Returns the user's resume
Anyone can access this information
 */
  static user(
    params: {
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResumeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resume/user/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * User sends a file to be sent to AI in order to be parsed and returned as a CrowdCoursing.Common.DTOs.Users.ResumeDto
   */
  static parseFile(
    params: {
      /** Required, non-empty */
      userId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResumeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Resume/user/{userId}/parse-file';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RoomService {
  /**
   * Gets a room for a given Room Id
   */
  static room(
    params: {
      /** Required, non-null */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Soft deletes a room
   */
  static room1(
    params: {
      /** Require, non-empty */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets the rooms for a given user
   */
  static myRooms(
    params: {
      /** Required, non-default */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/my-rooms/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a CrowdCoursing.Domain.Entities.Rooms.Room based upon a given CrowdCoursing.Common.DTOs.RoomDto
   */
  static room2(
    params: {
      /** requestBody */
      body?: CreateRoomRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Updates a CrowdCoursing.Domain.Entities.Rooms.Room based upon a given CrowdCoursing.Common.DTOs.Room.UpdateRoomRequest
   */
  static update(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** requestBody */
      body?: UpdateRoomRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/update';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Adds a user to a CrowdCoursing.Domain.Entities.Rooms.Room. Requires two calls to adds the room member.
The first call is to designate the CrowdCoursing.Domain.Entities.Users.User as a requester.
 */
  static add(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/add/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a user to a CrowdCoursing.Domain.Entities.Rooms.Room by email. Automatically adds them to the system if they don't exist
   */
  static addMemberByEmail(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** requestBody */
      body?: AddMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/add-member-by-email';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a user to request access to a CrowdCoursing.Domain.Entities.Rooms.Room
   */
  static request(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/request/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a user to request access to a CrowdCoursing.Domain.Entities.Rooms.Room
   */
  static deny(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/request/{userId}/deny';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a given CrowdCoursing.Domain.Entities.Users.User from a given CrowdCoursing.Domain.Entities.Rooms.Room
   */
  static remove(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/remove/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a given CrowdCoursing.Domain.Entities.Users.User from a given CrowdCoursing.Domain.Entities.Rooms.Room
   */
  static block(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/block/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Adds a facilitator to the room
   */
  static facilitator(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/facilitator/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes a facilitator from being a facilitator of the room
   */
  static facilitator1(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/facilitator/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Changes the room ownership to a given user
   */
  static owner(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/owner/{userId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Uploads an image for a room
   */
  static picture(
    params: {
      /** Require, non-empty */
      roomId: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/picture';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves a room image, redirects to uri
   */
  static picture1(
    params: {
      /** Require, non-empty */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/picture';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves a room image, redirects to uri
   */
  static checkInSvg(
    params: {
      /** Require, non-empty */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/check-in.svg';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
 * Checks the current user into the room.
Requires the user to be logged in.
 */
  static checkIn(
    params: {
      /** Required, non-empty */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/check-in';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Checks a user into a room for a given phone number or email.
Does not require the user to be logged in.
 */
  static checkIn1(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      identifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/check-in/{identifier}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a room milestone
   */
  static milestones(
    params: {
      /** Require, non-empty */
      roomId: string;
      /** requestBody */
      body?: CreateRoomMilestoneRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/milestones';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a room task
   */
  static tasks(
    params: {
      /** Require, non-empty */
      roomId: string;
      /** requestBody */
      body?: CreateRoomTaskRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/tasks';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a room milestone
   */
  static milestones1(
    params: {
      /** Require, non-empty */
      roomId: string;
      /** Require, non-empty */
      milestoneId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/milestones/{milestoneId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{milestoneId}', params['milestoneId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hard-deletes a room task
   */
  static tasks1(
    params: {
      /** Require, non-empty */
      roomId: string;
      /** Require, non-empty */
      taskId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/tasks/{taskId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{taskId}', params['taskId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Creates a series for meeting times to be a part of
This affects RSVP options
 */
  static meetingTimeSeries(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** requestBody */
      body?: CreateMeetingTimeSeriesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/meeting-time-series';
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Creates a series for meeting times to be a part of
This affects RSVP options
 */
  static meetingTimeSeries1(
    params: {
      /** Required, non-empty */
      roomId: string;
      /** Required, non-empty */
      meetingTimeSeriesId: string;
      /** requestBody */
      body?: UpdateMeetingTimeSeriesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/meeting-time-series/{meetingTimeSeriesId}';
      url = url.replace('{roomId}', params['roomId'] + '');
      url = url.replace('{meetingTimeSeriesId}', params['meetingTimeSeriesId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Deletes a series, removing all references to that series that may appear in meeting times.
Will not remove meeting times
 */
  static meetingTimeSeries2(
    params: {
      /** Required, non-empty */
      meetingTimeSeriesId: string;
      /**  */
      roomId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/room/{roomId}/meeting-time-series/{meetingTimeSeriesId}';
      url = url.replace('{meetingTimeSeriesId}', params['meetingTimeSeriesId'] + '');
      url = url.replace('{roomId}', params['roomId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SchoolDistrictService {
  /**
   * Retrieves the school DTOs for a given school district id
   */
  static schools(
    params: {
      /**  */
      schoolDistrictId: string;
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolSummaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/schools';
      url = url.replace('{schoolDistrictId}', params['schoolDistrictId'] + '');
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the school DTOs for a given school district id
   */
  static schools1(
    params: {
      /**  */
      schoolDistrictId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolSummaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/SchoolDistrict/{schoolDistrictId}/schools';
      url = url.replace('{schoolDistrictId}', params['schoolDistrictId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the vetted users for a given school district id
   */
  static vettedUsers(
    params: {
      /** Required, non-empty */
      schoolDistrictId: string;
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BubbleUserDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{schoolDistrictId}/vetted-users';
      url = url.replace('{schoolDistrictId}', params['schoolDistrictId'] + '');
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a bubble admin to approve a user as a vetted user
   */
  static approve(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/user/{userId}/approve';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Removes users from the vetted users pool for a given bubble
   */
  static remove(
    params: {
      /** Required, non-empty */
      bubbleId: string;
      /** Required, non-empty */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/user/{userId}/remove';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a District Admin to pin a school
   */
  static pin(
    params: {
      /** Required, non-empty */
      districtId: string;
      /** Required, non-empty */
      schoolId: string;
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{districtId}/school/{schoolId}/pin';
      url = url.replace('{districtId}', params['districtId'] + '');
      url = url.replace('{schoolId}', params['schoolId'] + '');
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Allows a District Admin to unpin a school
   */
  static unpin(
    params: {
      /** Required, non-empty */
      districtId: string;
      /** Required, non-empty */
      schoolId: string;
      /**  */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/{districtId}/school/{schoolId}/unpin';
      url = url.replace('{districtId}', params['districtId'] + '');
      url = url.replace('{schoolId}', params['schoolId'] + '');
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Root users can create a new district
   */
  static createDistrict(
    params: {
      /**  */
      bubbleId: string;
      /** requestBody */
      body?: CreateSchoolDistrictRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/create-district';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Root users can create new schools. BubbleId needs to be the district's bubble id.
   */
  static createSchool(
    params: {
      /**  */
      bubbleId: string;
      /** requestBody */
      body?: CreateSchoolRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Bubbles/{bubbleId}/SchoolDistrict/create-school';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SearchService {
  /**
   * Searches for CrowdCoursing.Common.DTOs.RoomDtos that meet the CrowdCoursing.Common.DTOs.Search.SearchDto criteria
   */
  static search(
    params: {
      /** requestBody */
      body?: SearchDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Search/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Searches for CrowdCoursing.Common.DTOs.RoomDtos that meet the CrowdCoursing.Common.DTOs.Search.SearchDto criteria
   */
  static roomsAndEvents(
    params: {
      /** requestBody */
      body?: SearchDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Search/search/rooms-and-events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Searches for CrowdCoursing.Common.DTOs.RoomDtos that meet the CrowdCoursing.Common.DTOs.Search.SearchDto criteria
   */
  static rooms(
    params: {
      /** requestBody */
      body?: SearchDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Search/search/rooms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Searches for CrowdCoursing.Common.DTOs.RoomDtos that meet the CrowdCoursing.Common.DTOs.Search.SearchDto criteria
   */
  static events(
    params: {
      /** requestBody */
      body?: SearchDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Search/search/events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Searches for CrowdCoursing.Common.DTOs.Search.SearchCandidateResultDtos that meet the CrowdCoursing.Common.DTOs.Search.SearchCandidateDto criteria
   */
  static candidates(
    params: {
      /** requestBody */
      body?: SearchCandidateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchCandidateResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Search/search/candidates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TranslationService {
  /**
   * Takes a list of labels and returns back tuples with the original label and its translation
   */
  static labels(
    params: {
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringStringTuple[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/translations/labels';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gives all of the languages in display form and their corresponding enum int values
   */
  static translatableLanguages(options: IRequestOptions = {}): Promise<LanguageToEnum[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/translations/translatable-languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   * Retrieves user info that doesn't require auth
   */
  static public(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/public';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves all of the RSVPs for a user between a given window of time
   */
  static rsvps(
    params: {
      /** Required, non-empty */
      userId: string;
      /** Optional, defaults to today */
      beginTime?: string;
      /** Optional, defaults to the end of next year */
      endTime?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RsvpResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/rsvps';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { BeginTime: params['beginTime'], EndTime: params['endTime'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets email notification preferences
   */
  static dailyEmailNotifications(
    params: {
      /** Required, non-empty */
      userId: string;
      /** Required */
      allowNotifications?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/daily-email-notifications';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { allowNotifications: params['allowNotifications'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets email notification preferences
   */
  static weeklyEmailNotifications(
    params: {
      /** Required, non-empty */
      userId: string;
      /** Required */
      allowNotifications?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/weekly-email-notifications';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { allowNotifications: params['allowNotifications'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets the default language for a user
   */
  static setDefaultLanguage(
    params: {
      /** CrowdCoursing.Common.Translations.DefaultLanguage */
      defaultLanguage: DefaultLanguage;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/set-default-language/{defaultLanguage}';
      url = url.replace('{defaultLanguage}', params['defaultLanguage'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets the gender for a user
   */
  static setGender(
    params: {
      /** CrowdCoursing.Domain.Entities.Users.Gender */
      gender: Gender;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/set-gender/{gender}';
      url = url.replace('{gender}', params['gender'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sets the age bracket for a user
   */
  static setAgeBracket(
    params: {
      /** CrowdCoursing.Domain.Entities.Users.AgeBracket */
      age: AgeBracket;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/set-age-bracket/{age}';
      url = url.replace('{age}', params['age'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Should be called when a notification is clicked by the user
   */
  static markNotificationRead(
    params: {
      /** Notification Id */
      notificationId: string;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/mark-notification-read/{notificationId}';
      url = url.replace('{notificationId}', params['notificationId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Should be called when the user wants to mark a set of notifications as read
   */
  static markNotificationsAsRead(
    params: {
      /**  */
      userId: string;
      /** requestBody */
      body?: BulkNotificationsOperation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/mark-notifications-as-read';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Should be called when the user wants to mark a set of notifications as archived
   */
  static markNotificationsAsArchived(
    params: {
      /**  */
      userId: string;
      /** requestBody */
      body?: BulkNotificationsOperation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/mark-notifications-as-archived';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userHistory(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserHistoryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/User/{userId}/user-history';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VolunteeringService {
  /**
   * A volunteer, educator or an admin can manually add volunteer time for a user
   */
  static addVolunteerTime(
    params: {
      /** requestBody */
      body?: ManualVolunteerAddRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/add-volunteer-time';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * An educator or an admin can confirm the time for a given volunteering record id
   */
  static confirmVolunteerTime(
    params: {
      /** Required, non-empty */
      volunteeringRecordId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/volunteering-record/{volunteeringRecordId}/confirm-volunteer-time';
      url = url.replace('{volunteeringRecordId}', params['volunteeringRecordId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * An admin or educator can modify the volunteering record
   */
  static update(
    params: {
      /**  */
      volunteeringRecordId: string;
      /** requestBody */
      body?: UpdateVolunteerRecord;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/volunteering-record/{volunteeringRecordId}/update';
      url = url.replace('{volunteeringRecordId}', params['volunteeringRecordId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the volunteering records for a given user from the volunteer perspective
   */
  static myRecords(options: IRequestOptions = {}): Promise<VolunteeringRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/my-records';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the volunteering records for a given user from the educator perspective
   */
  static educator(options: IRequestOptions = {}): Promise<VolunteeringRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/my-records/educator';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieves the volunteering records for a given user from the admin perspective
   */
  static admin(
    params: {
      /** Required, non-empty */
      bubbleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteeringRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/Volunteering/my-records/admin/{bubbleId}';
      url = url.replace('{bubbleId}', params['bubbleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface AddMemberRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;
}

export interface AddUserToOrgDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  role?: string;
}

export interface AddressDto {
  /**  */
  line1?: string;

  /**  */
  line2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;
}

export interface BackgroundCheckProduct {
  /**  */
  name?: string;

  /**  */
  cost?: number;

  /**  */
  id?: string;
}

export interface BubbleDto {
  /**  */
  id?: string;

  /**  */
  parentId?: string;

  /**  */
  name?: string;

  /**  */
  children?: BubbleDto[];
}

export interface BubbleUserDto {
  /**  */
  userId?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  bubbleId?: string;

  /**  */
  bubbleName?: string;

  /**  */
  role?: string;

  /**  */
  status?: string;

  /**  */
  backgroundCheckUrl?: string;

  /**  */
  isBackgroundCheckCompleted?: boolean;
}

export interface BulkAddUserRequest {
  /**  */
  bulkAddUserDtos?: BubbleUserDto[];
}

export interface BulkEmailDto {
  /**  */
  bubbleId?: string;

  /**  */
  recipients?: string[];

  /**  */
  subject?: string;

  /**  */
  emailContents?: string;
}

export interface BulkNotificationsOperation {
  /**  */
  notificationIds?: string[];
}

export interface CandidateDto {
  /**  */
  userId?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  isFavorite?: boolean;

  /**  */
  interviews?: InterviewDto[];
}

export interface CreateEventMilestoneRequest {
  /**  */
  eventId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  dueDate?: Date;
}

export interface CreateEventRequest {
  /**  */
  bubbleId?: string;

  /**  */
  ownerId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  roomId?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  isSearchable?: boolean;

  /**  */
  eventSchedule?: EventScheduleDto;

  /**  */
  eventRules?: EventRuleDto[];
}

export interface CreateEventTaskRequest {
  /**  */
  eventId?: string;

  /**  */
  userId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  dueDate?: Date;
}

export interface CreateMeetingTimeRequest {
  /**  */
  eventId?: string;

  /**  */
  beginMeetingTime?: Date;

  /**  */
  endMeetingTime?: Date;

  /**  */
  maximumSlots?: number;
}

export interface CreateMeetingTimeSeriesRequest {
  /**  */
  roomId?: string;

  /**  */
  seriesName?: string;

  /**  */
  seriesDescription?: string;
}

export interface CreateOrganizationDto {
  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  description?: string;

  /**  */
  bubbleId?: string;

  /**  */
  canCreateEvents?: boolean;
}

export interface CreateRoomMilestoneRequest {
  /**  */
  roomId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  dueDate?: Date;
}

export interface CreateRoomRequest {
  /**  */
  bubbleId?: string;

  /**  */
  ownerId?: string;

  /**  */
  name?: string;

  /**  */
  zipCode?: string;

  /**  */
  description?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  isSearchable?: boolean;
}

export interface CreateRoomTaskRequest {
  /**  */
  roomId?: string;

  /**  */
  userId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  dueDate?: Date;
}

export interface CreateSchoolDistrictRequest {
  /**  */
  creatorUserId?: string;

  /**  */
  districtName?: string;

  /**  */
  county?: string;

  /**  */
  state?: string;

  /**  */
  stateInitials?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  addressLine3?: string;
}

export interface CreateSchoolRequest {
  /**  */
  creatorUserId?: string;

  /**  */
  districtId?: string;

  /**  */
  schoolName?: string;

  /**  */
  stateName?: string;

  /**  */
  stateInitials?: string;

  /**  */
  zipCode?: string;

  /**  */
  zipCodePlus4?: string;

  /**  */
  city?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  addressLine3?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  level?: string;

  /**  */
  lowestGrade?: string;

  /**  */
  highestGrade?: string;

  /**  */
  schoolType?: string;

  /**  */
  isCharterSchool?: boolean;
}

export interface CurrentVolunteerStats {
  /**  */
  name?: string;

  /**  */
  minutesThisWeek?: number;

  /**  */
  minutesThisMonth?: number;

  /**  */
  minutesThisYear?: number;

  /**  */
  minutesAllTime?: number;

  /**  */
  minutesScheduledThisNextYear?: number;

  /**  */
  minutesUnconfirmed?: number;

  /**  */
  economicImpactWeek?: number;

  /**  */
  economicImpactMonth?: number;

  /**  */
  economicImpactYTD?: number;

  /**  */
  economicImpactAllTime?: number;
}

export interface DistrictPayRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  schoolDistrictId?: string;

  /**  */
  productId?: string;

  /**  */
  userId?: string;
}

export interface DynamicStatsDto {
  /**  */
  name?: string;

  /**  */
  bubbleId?: string;

  /**  */
  parentBubbleId?: string;

  /**  */
  eventsCreated?: number;

  /**  */
  eventsCompleted?: number;

  /**  */
  minutesVolunteered?: number;

  /**  */
  economicImpact?: number;

  /**  */
  signIns?: number[];

  /**  */
  children?: DynamicStatsDto[];
}

export interface DynamicStatsRequest {
  /**  */
  bubbleId?: string;

  /**  */
  start?: Date;

  /**  */
  end?: Date;

  /**  */
  includeChildren?: boolean;

  /**  */
  includeChildrenInAggregateStats?: boolean;
}

export interface EducationDto {
  /**  */
  achievements?: string[];

  /**  */
  program?: string;

  /**  */
  school?: string;

  /**  */
  gpaUnweighted?: number;

  /**  */
  gpaWeighted?: number;

  /**  */
  graduationYear?: number;

  /**  */
  orderIndex?: number;
}

export interface EventDto {
  /**  */
  id?: string;

  /**  */
  timestamp?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  bubbleId?: string;

  /**  */
  ownerId?: string;

  /**  */
  roomId?: string;

  /**  */
  name?: string;

  /**  */
  ownerFirstName?: string;

  /**  */
  ownerLastName?: string;

  /**  */
  roomName?: string;

  /**  */
  description?: string;

  /**  */
  isSearchable?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  maxParticipants?: number;

  /**  */
  beginMeetingTime?: Date;

  /**  */
  endMeetingTime?: Date;

  /**  */
  members?: string[];

  /**  */
  requests?: string[];

  /**  */
  meetingTimes?: MeetingTimeDto[];

  /**  */
  rules?: EventRuleDto[];

  /**  */
  tags?: string[];
}

export interface EventRuleDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  order?: number;
}

export interface EventScheduleDto {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  beginMeetingTime?: Date;

  /**  */
  endMeetingTime?: Date;

  /**  */
  seriesId?: string;

  /**  */
  meetingTimeCapacity?: number;

  /**  */
  days?: DayOfWeek[];
}

export interface InterviewDto {
  /**  */
  bubbleId?: string;

  /**  */
  bubbleName?: string;

  /**  */
  interviewerUserId?: string;

  /**  */
  interviewerFirstName?: string;

  /**  */
  interviewerLastName?: string;

  /**  */
  candidateUserId?: string;

  /**  */
  candidateFirstName?: string;

  /**  */
  candidateLastName?: string;

  /**  */
  interviewBeginTime?: Date;

  /**  */
  interviewEndTime?: Date;
}

export interface LanguageToEnum {
  /**  */
  language?: string;

  /**  */
  languageEnum?: DefaultLanguage;
}

export interface LoginRequest {
  /**  */
  identifier?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  timezone?: string;
}

export interface ManualVolunteerAddRequest {
  /**  */
  userId?: string;

  /**  */
  bubbleId?: string;

  /**  */
  roomId?: string;

  /**  */
  eventId?: string;

  /**  */
  start?: Date;

  /**  */
  end?: Date;

  /**  */
  description?: string;
}

export interface MeetingTimeDto {
  /**  */
  id?: string;

  /**  */
  eventId?: string;

  /**  */
  eventName?: string;

  /**  */
  roomName?: string;

  /**  */
  organizerName?: string;

  /**  */
  seriesId?: string;

  /**  */
  beginMeetingTime?: Date;

  /**  */
  endMeetingTime?: Date;

  /**  */
  isFilled?: boolean;

  /**  */
  capacity?: number;

  /**  */
  rsvpUserIds?: string[];
}

export interface OrgDonationDto {
  /**  */
  id?: string;

  /**  */
  organizationId?: string;

  /**  */
  donationType?: DonationType;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  totalDollarValue?: number;

  /**  */
  donationDate?: Date;
}

export interface OrganizationSummary {
  /**  */
  bubbleId?: string;

  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  description?: string;

  /**  */
  isExternal?: boolean;

  /**  */
  canCreateEvents?: boolean;
}

export interface PaymentAccountStatusDto {
  /**  */
  accountCreated?: boolean;

  /**  */
  payoutsEnabled?: boolean;
}

export interface PaymentSessionDto {
  /**  */
  recipientUserId?: string;

  /**  */
  amount?: number;
}

export interface PersonalInfoUpdateRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  professionalPortfolioLink?: string;
}

export interface PersonalInformationDto {
  /**  */
  email?: string;

  /**  */
  linkedIn?: string;

  /**  */
  location?: string;

  /**  */
  name?: string;

  /**  */
  phone?: string;
}

export interface ProblemDetails {
  /**  */
  type?: string;

  /**  */
  title?: string;

  /**  */
  status?: number;

  /**  */
  detail?: string;

  /**  */
  instance?: string;
}

export interface ProfessionalExperienceDto {
  /**  */
  companyName?: string;

  /**  */
  description?: string;

  /**  */
  achievements?: string[];

  /**  */
  end?: Date;

  /**  */
  jobTitle?: string;

  /**  */
  location?: string;

  /**  */
  start?: Date;

  /**  */
  orderIndex?: number;
}

export interface ProfileDto {
  /**  */
  userDto?: UserDto;

  /**  */
  isProfileReady?: boolean;

  /**  */
  isAdmin?: boolean;

  /**  */
  isSiteAdmin?: boolean;

  /**  */
  isDistrictAdmin?: boolean;

  /**  */
  isSchoolAdmin?: boolean;

  /**  */
  isOrganizationAdmin?: boolean;

  /**  */
  isEducator?: boolean;
}

export interface ProjectDto {
  /**  */
  description?: string;

  /**  */
  name?: string;

  /**  */
  orderIndex?: number;
}

export interface ReportDto {
  /**  */
  id?: string;

  /**  */
  timestamp?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  offenderId?: string;

  /**  */
  reporterId?: string;

  /**  */
  modResponse?: string;

  /**  */
  resolvedOn?: Date;

  /**  */
  resolvedBy?: string;

  /**  */
  categories?: string[];

  /**  */
  comment?: string;
}

export interface ResourceDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  link?: string;
}

export interface ResumeDto {
  /**  */
  userId?: string;

  /**  */
  certifications?: string[];

  /**  */
  education?: EducationDto[];

  /**  */
  personalInformation?: PersonalInformationDto;

  /**  */
  professionalExperiences?: ProfessionalExperienceDto[];

  /**  */
  projects?: ProjectDto[];

  /**  */
  skills?: string[];
}

export interface RoomDto {
  /**  */
  id?: string;

  /**  */
  timestamp?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  bubbleId?: string;

  /**  */
  ownerId?: string;

  /**  */
  ownerFirstName?: string;

  /**  */
  ownerLastName?: string;

  /**  */
  zipCode?: string;

  /**  */
  maximumParticipants?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  isSearchable?: boolean;

  /**  */
  roomType?: RelationshipTypeEnum;

  /**  */
  facilitatorIds?: string[];

  /**  */
  memberIds?: string[];

  /**  */
  requestIds?: string[];

  /**  */
  events?: EventDto[];

  /**  */
  series?: SeriesSummary[];

  /**  */
  tags?: string[];

  /**  */
  eventPreviews?: EventDto[];
}

export interface RsvpResponse {
  /**  */
  eventId?: string;

  /**  */
  eventName?: string;

  /**  */
  roomId?: string;

  /**  */
  roomName?: string;

  /**  */
  schoolId?: string;

  /**  */
  schoolName?: string;

  /**  */
  userId?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  isVetted?: boolean;

  /**  */
  ownerUserId?: string;

  /**  */
  ownerFirstName?: string;

  /**  */
  ownerLastName?: string;

  /**  */
  meetingTimeDto?: MeetingTimeDto;
}

export interface SchoolSummaryDto {
  /**  */
  bubbleId?: string;

  /**  */
  schoolDistrictId?: string;

  /**  */
  schoolName?: string;

  /**  */
  city?: string;

  /**  */
  stateInitials?: string;

  /**  */
  zipCode?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;
}

export interface SearchCandidateDto {
  /**  */
  certifications?: string[];

  /**  */
  graduationYear?: number;

  /**  */
  minmumUnweightedGpa?: number;

  /**  */
  minmumWeightedGpa?: number;

  /**  */
  sortBy?: string;

  /**  */
  sortDescending?: boolean;

  /**  */
  filters?: object;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;
}

export interface SearchCandidateResultDto {
  /**  */
  userId?: string;

  /**  */
  certifications?: string[];

  /**  */
  graduationYear?: number;

  /**  */
  minmumUnweightedGpa?: number;

  /**  */
  minmumWeightedGpa?: number;
}

export interface SearchDto {
  /**  */
  roomName?: string;

  /**  */
  schoolDistrictBubbleId?: string;

  /**  */
  schoolBubbleId?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  zipCode?: string;

  /**  */
  subjects?: string[];

  /**  */
  gradeLevels?: string[];

  /**  */
  meetingDays?: string[];

  /**  */
  searchTime?: Date;
}

export interface SearchResultDto {
  /**  */
  id?: string;

  /**  */
  resultType?: SearchResultType;

  /**  */
  bubbleId?: string;

  /**  */
  bubbleName?: string;

  /**  */
  ownerId?: string;

  /**  */
  ownerFirstName?: string;

  /**  */
  ownerLastName?: string;

  /**  */
  address?: string;

  /**  */
  resultName?: string;

  /**  */
  description?: string;

  /**  */
  isAutojoinEnabled?: boolean;

  /**  */
  isSearchable?: boolean;

  /**  */
  weight?: number;

  /**  */
  roomType?: RelationshipTypeEnum;

  /**  */
  events?: EventDto[];

  /**  */
  series?: SeriesSummary[];

  /**  */
  tags?: string[];

  /**  */
  eventPreviews?: EventDto[];
}

export interface SeedDbResult {
  /**  */
  newUserPhone?: string;

  /**  */
  existingUserPhone?: string;

  /**  */
  existingUserId?: string;

  /**  */
  existingUserJwt?: string;

  /**  */
  roomId?: string;

  /**  */
  memberRoomId?: string;

  /**  */
  sessionMemberRoomId?: string;

  /**  */
  sessionId?: string;

  /**  */
  publicRoomId?: string;

  /**  */
  privateRoomId?: string;
}

export interface SelfPayRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  schoolDistrictId?: string;

  /**  */
  productId?: string;
}

export interface SelfPayResult {
  /**  */
  paymentUrl?: string;
}

export interface SeriesSummary {
  /**  */
  id?: string;

  /**  */
  roomId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface StringStringTuple {
  /**  */
  item1?: string;

  /**  */
  item2?: string;
}

export interface UpdateEventRequest {
  /**  */
  facilitatorId?: string;

  /**  */
  bubbleId?: string;

  /**  */
  eventId?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  roomId?: string;

  /**  */
  isSearchable?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  sessionSchedule?: EventScheduleDto;

  /**  */
  meetingTimes?: MeetingTimeDto[];

  /**  */
  eventRules?: EventRuleDto[];
}

export interface UpdateMeetingTimeDto {
  /**  */
  meetingTimeId?: string;

  /**  */
  meetingTimeSeriesId?: string;

  /**  */
  beginMeetingTime?: Date;

  /**  */
  endMeetingTime?: Date;

  /**  */
  maximumSlots?: number;
}

export interface UpdateMeetingTimeSeriesRequest {
  /**  */
  seriesId?: string;

  /**  */
  roomId?: string;

  /**  */
  seriesName?: string;

  /**  */
  seriesDescription?: string;
}

export interface UpdateRoomRequest {
  /**  */
  facilitatorId?: string;

  /**  */
  roomId?: string;

  /**  */
  name?: string;

  /**  */
  zipCode?: string;

  /**  */
  description?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  isSearchable?: boolean;
}

export interface UpdateVolunteerRecord {
  /**  */
  volunteerRecordId?: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  totalMinutesConfirmed?: number;

  /**  */
  description?: string;
}

export interface UrlResult {
  /**  */
  url?: string;
}

export interface UserBubbleMembershipStatus {
  /**  */
  bubbleId?: string;

  /**  */
  bubbleName?: string;

  /**  */
  role?: string;

  /**  */
  status?: string;
}

export interface UserDistrictSummary {
  /**  */
  userId?: string;

  /**  */
  schoolDistrictId?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  backgroundCheckUrl?: string;

  /**  */
  isBackgroundCheckCompleted?: boolean;

  /**  */
  roles?: string[];

  /**  */
  status?: string;

  /**  */
  membershipStatuses?: UserBubbleMembershipStatus[];
}

export interface UserDto {
  /**  */
  id?: string;

  /**  */
  timestamp?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  isAdministrator?: boolean;

  /**  */
  isEducator?: boolean;

  /**  */
  isDailyNotificationsOn?: boolean;

  /**  */
  isWeeklyNotificationsOn?: boolean;
}

export interface UserHistoryDto {
  /**  */
  id?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  profileImageUri?: string;

  /**  */
  volunteerStats?: CurrentVolunteerStats;

  /**  */
  volunteeringRecords?: VolunteeringRecordDto[];

  /**  */
  rooms?: RoomDto[];

  /**  */
  events?: EventDto[];

  /**  */
  rsvps?: MeetingTimeDto[];
}

export interface VerificationDto {
  /**  */
  token?: string;

  /**  */
  isProfileReady?: boolean;
}

export interface VerifyLoginRequest {
  /**  */
  identifier?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  timezone?: string;

  /**  */
  authCode?: number;
}

export interface VolunteeringRecordDto {
  /**  */
  volunteerUserId?: string;

  /**  */
  volunteerFirstName?: string;

  /**  */
  volunteerLastName?: string;

  /**  */
  volunteerDisplayName?: string;

  /**  */
  leaderUserId?: string;

  /**  */
  leaderFirstName?: string;

  /**  */
  leaderLastName?: string;

  /**  */
  leaderDisplayName?: string;

  /**  */
  bubbleId?: string;

  /**  */
  bubbleName?: string;

  /**  */
  roomId?: string;

  /**  */
  roomName?: string;

  /**  */
  eventId?: string;

  /**  */
  eventName?: string;

  /**  */
  description?: string;

  /**  */
  meetingTimeId?: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  totalMinutes?: number;

  /**  */
  totalMinutesConfirmed?: number;

  /**  */
  whenConfirmed?: Date;
}

export type AgeBracket = 0 | 1 | 2 | 3;

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type DefaultLanguage =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53
  | 54
  | 55
  | 56
  | 57
  | 58
  | 59
  | 60
  | 61
  | 62
  | 63
  | 64
  | 65
  | 66
  | 67
  | 68
  | 69
  | 70
  | 71
  | 72
  | 73
  | 74
  | 75
  | 76
  | 77
  | 78
  | 79
  | 80
  | 81
  | 82
  | 83
  | 84
  | 85
  | 86
  | 87
  | 88
  | 89
  | 90
  | 91
  | 92
  | 93
  | 94
  | 95
  | 96
  | 97
  | 98
  | 99
  | 100
  | 101
  | 102
  | 103
  | 104
  | 105
  | 106
  | 107
  | 108;

export type DonationType = 0 | 1 | 2;

export type Gender = 0 | 1 | 2 | 3;
export type ProblemDetailsWithAdditionalProperties = ProblemDetails & { [additionalProperties: string]: any | null };
export type RelationshipTypeEnum = 0 | 1 | 2 | 3 | 4;

export type SearchResultType = 0 | 1;
