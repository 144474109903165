import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteEvent,
  deleteRsvp,
  leave,
  requestMembership as requestMembershipSession,
  rsvp,
  updateMeetingTime,
} from '../actions/events';
import {
  createPod,
  deletePod,
  getMyPods,
  removeMember as removePodMember,
  requestMembership as requestMembershipPod,
  updatePod,
} from '../actions/pods';
import { type RoomDto } from '../services/ApiService';
import { type PodState } from './state';

const initialState: PodState = {
  isLoading: false,
  myPods: [],
};

export const pods = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(getMyPods.fulfilled, (state: PodState, action: PayloadAction<RoomDto[]>) => {
        state.myPods = action.payload;
      })
      .addMatcher(
        isAnyOf(
          createPod.pending,
          updatePod.pending,
          deletePod.pending,
          deleteEvent.pending,
          updateMeetingTime.pending
        ),
        (state: PodState) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createPod.rejected,
          createPod.fulfilled,
          updatePod.rejected,
          updatePod.fulfilled,
          deletePod.rejected,
          deletePod.fulfilled,
          deleteEvent.rejected,
          deleteEvent.fulfilled,
          updateMeetingTime.rejected,
          updateMeetingTime.fulfilled,
          removePodMember.fulfilled
        ),
        (state: PodState) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          removePodMember.fulfilled,
          leave.fulfilled,
          requestMembershipPod.fulfilled,
          requestMembershipSession.fulfilled,
          rsvp.fulfilled,
          deleteRsvp.fulfilled,
          updateMeetingTime.fulfilled
        ),
        // @ts-expect-error todo: figure out the correct type
        (state: PodState, action: PayloadAction<RoomDto>) => {
          const index = state.myPods.findIndex(
            (result) => result?.id === action?.payload?.id && result.id && action.payload && action.payload.id
          );
          if (index !== -1) {
            state.myPods[index] = action.payload;
          }
        }
      ),
  initialState,
  name: 'pods',
  reducers: {},
});
