import { lazy } from 'react';

/**
 * @description Lazily load the `AdminPage` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const AdminPageAsync = lazy(async function () {
  return import('./AdminPage').then((module) => ({ default: module.AdminPage }));
});
