/** Loading this file asynchronously causes errors */
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { persistor } from '~services/providers/ReduxProvider';
import { Route } from '~services/router/Route';

import { useDispatch } from '../../../hooks';
import { actions as userActions } from '../../../state/userSlice';

const purge = async () => {
  await persistor.purge();
};

export const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.logout());
    purge();
  }, []);

  return <Navigate to={Route.login()} />;
};
