import { Feature, overrides as domainOverrides } from '../state/features';
import { useSelector } from './useTypedRedux';

/**
 * Check if a feature is enabled
 *
 * truth table for this implementation
 *
 * | state.features | output |
 * |:---------------|:-------|
 * | false          | false  |
 * | true           | true   |
 */
export const useFeature = (feature: Feature, overrides = domainOverrides): boolean => {
  const f = useSelector((state) => state.features[feature]);
  return overrides[window.location.hostname]?.[feature] ?? f;
};

export function useFeatures(overrides = domainOverrides) {
  const features = useSelector((state) => state.features);
  return { ...features, ...overrides[window.location.hostname] };
}
