import { Rsvp as RsvpTable, RsvpProps as RsvpTableProps } from '../../../../cc-ui/components/Tables/Schedule/Rsvp';
import { useSelector } from '../../../../hooks';
import { useUserGetUserRsvpsSearch } from '../../../../services/openapi/generated/CrowdCoursingComponents';

const tLoadingWithEllipses = 'Loading...';
const tOopsWithEllipses = 'Oops...';

export const Rsvp = () => {
  const userId = useSelector((state) => state.user?.id ?? '');
  const { data, error, isLoading } = useUserGetUserRsvpsSearch({ pathParams: { userId } });

  if (isLoading || !data) {
    return <p>{tLoadingWithEllipses}</p>;
  }

  if (error) {
    return <p>{tOopsWithEllipses}</p>;
  }

  const rsvps: RsvpTableProps['rsvps'] = data.map(({ schoolName, eventId, eventName, meetingTimeDto }) => ({
    beginMeetingTime: new Date(meetingTimeDto!.beginMeetingTime!),
    endMeetingTime: new Date(meetingTimeDto!.endMeetingTime!),
    eventId: eventId!,
    eventName: eventName!,
    rsvpUserId: userId,
    schoolName: schoolName,
  }));
  const handleClickRow: RsvpTableProps['onClickRow'] = () => {};

  return <RsvpTable onClickRow={handleClickRow} rsvps={rsvps} />;
};
