import { Edit as EditIcon, QrCode as QrCodeIcon, Share as ShareIcon } from '@mui/icons-material';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';

import { ResourceList } from '../../cc-ui';
import AddMemberButton from '../../cc-ui/components/MemberManagement/AddMemberButton';
import { useSelector } from '../../hooks';
import { useDispatch } from '../../hooks/useTypedRedux';
import { EventService } from '../../services/ApiService';
import {
  EventRoomSummaryQuery,
  SessionSummaryQuery,
  useDeleteEventResourceMutation,
  useEventRoomSummaryQuery,
  useSaveEventResourceMutation,
  useSessionSummaryQuery,
} from '../../services/graphql/generated/react-query';
import { Route } from '../../services/router/Route';
import { successToast } from '../../state/notifications';
import { ManageResourcesChip } from '../../ui/organisms/Resources/Manage';
import { fullyQualifiedUrl, makeBubbleAvatarUrl, makeEventAvatarUrl } from '../../utilities/utils';
import { Chat } from '../Chat/Chat';
import { ManageList } from '../Shared/ManageList';
import EventActionButton from './EventActionButton';
import EventDescription from './EventDescription';

const tCopyTooltip = 'Copies a sharable link to this event';
const tCopySuccess = 'Sharable link copied to clipboard!';
const tQRTooltip = 'View the event QR code';
const tEditSessionTooltip = 'Lets you edit the event';

interface ViewEventProps {
  event: NonNullable<SessionSummaryQuery['session']>;
  isLoadingEvent: boolean;
  refetchEvent: () => void;
  room?: NonNullable<EventRoomSummaryQuery['room']>;
}

const ViewEvent = ({ event, room, isLoadingEvent, refetchEvent }: ViewEventProps) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);
  const { mutate: saveResource } = useSaveEventResourceMutation({ onSuccess: () => refetchEvent() });
  const { mutate: deleteResource } = useDeleteEventResourceMutation({ onSuccess: () => refetchEvent() });

  const { userIsFacilitator: isFacilitator, userIsMember: isMember, userIsPending: isRequestPending } = event;

  const bubbleAvatarUrl = makeBubbleAvatarUrl(event.bubbleId);
  const avatarUrl = makeEventAvatarUrl(event.id);
  const isEventOwner = userId === event.ownerId;
  const isFacilitatorOrMember = isFacilitator || isMember;
  const accessibilitySegment = event.isPublic ? 'Open' : 'Private';
  const sharableSessionLink = fullyQualifiedUrl(
    room?.id ? Route.roomViewEvent({ eventId: event.id, roomId: room.id }) : Route.eventView({ eventId: event.id })
  );
  const canView = isFacilitator || isMember;
  const hasResources = event.resources.length > 0;
  const reload = () => window.location.reload();
  const handleLeave = () => {
    refetchEvent();
    reload();
  };

  const taskListProps = (arr: unknown[]) => ({
    empty: arr.length === 0,
    error: false,
    loading: isLoadingEvent,
    success: arr.length > 0,
  });
  return (
    <>
      <Grid container direction="column" item spacing={1}>
        <Grid container direction="row" item key="TitleRow">
          <Grid container direction="row" gap="0.5em" item xs={11}>
            <Grid item>
              <Avatar src={avatarUrl} sx={{ height: '6em', width: '6em' }} />
            </Grid>
            <Grid direction="column" item>
              <Grid container direction="row" gap="0.5em" item>
                <Grid item>
                  <Typography variant="h4">{event.name}</Typography>
                </Grid>
                <Grid alignContent="center" item>
                  <Tooltip placement="top-start" title={tCopyTooltip}>
                    <ShareIcon
                      onClick={() => {
                        navigator.clipboard.writeText(sharableSessionLink);
                        dispatch(successToast({ message: tCopySuccess }));
                      }}
                      style={{
                        cursor: 'pointer',
                        filter:
                          'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                      }}
                    />
                  </Tooltip>
                </Grid>
                {isFacilitator && (
                  <>
                    <Grid alignContent="center" item>
                      <Tooltip placement="top-start" title={tQRTooltip}>
                        <Link
                          style={{ color: 'unset', textDecoration: 'none' }}
                          target="_blank"
                          to={Route.eventQr({ eventId: event.id })}
                        >
                          <QrCodeIcon color="primary" />
                        </Link>
                      </Tooltip>
                    </Grid>
                    <Grid alignContent="center" item>
                      <Tooltip placement="top-start" title={tEditSessionTooltip}>
                        <Link
                          style={{ color: 'unset', textDecoration: 'none' }}
                          to={
                            room?.id
                              ? Route.roomEditEvent({ eventId: event.id, roomId: room.id })
                              : Route.eventEdit({ eventId: event.id })
                          }
                        >
                          <EditIcon color="info" />
                        </Link>
                      </Tooltip>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{accessibilitySegment}</Typography>
              </Grid>
              {canView && (
                <Grid container direction="row" gap="0.5em" marginTop="1em">
                  <Grid item>
                    <ManageList
                      isFacilitator={isFacilitator}
                      items={event.eventMilestones}
                      refetch={refetchEvent}
                      variant={{
                        async onAction(item) {
                          await EventService.milestones1({ eventId: event.id, milestoneId: item.id });
                        },
                        async onAdd({ description, dueDate, name }) {
                          await EventService.milestones({
                            body: { description, dueDate, eventId: event.id, name },
                            eventId: event.id,
                          });
                        },
                        type: 'milestones',
                      }}
                      {...taskListProps(event.eventMilestones)}
                    />
                  </Grid>
                  <Grid item>
                    <ManageList
                      isFacilitator={isFacilitator}
                      items={event.eventTasks}
                      refetch={refetchEvent}
                      variant={{
                        async onAction(item) {
                          await EventService.tasks1({ eventId: event.id, taskId: item.id });
                        },
                        async onAdd({ description, dueDate, name }) {
                          await EventService.tasks({
                            body: { description, dueDate, eventId: event.id, name, userId },
                            eventId: event.id,
                          });
                        },
                        type: 'tasks',
                      }}
                      {...taskListProps(event.eventTasks)}
                    />
                  </Grid>
                  {isFacilitator && (
                    <Grid item>
                      <ManageResourcesChip
                        onDeleteResource={(item) => deleteResource({ request: { eventId: event.id, id: item.id } })}
                        onSaveResource={(newItem, oldItem) =>
                          saveResource({ request: { eventId: event.id, id: oldItem?.id, ...newItem } })
                        }
                        resources={event.resources}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={1}>
            <Grid container direction="row" justifyContent="flex-end" justifyItems="flex-end">
              <Grid item>
                <Avatar src={bubbleAvatarUrl} sx={{ height: '6rem', width: '6rem' }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" marginTop="1em">
            <Grid hidden={!(isFacilitator || isEventOwner)} item marginRight="1em">
              <AddMemberButton eventId={event.id} />
            </Grid>
            <Grid item>
              <EventActionButton
                eventId={event.id}
                isEventOwner={isEventOwner}
                isFacilitator={isFacilitator}
                isMember={isMember}
                isPending={isRequestPending}
                name={event.name}
                onJoinRequested={refetchEvent}
                onLeaveRequested={handleLeave}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item key="DescriptionRow" xs={12}>
          <EventDescription
            event={event}
            isFacilitator={isFacilitator}
            isMember={isMember}
            refetch={refetchEvent}
            series={room?.series ?? []}
          />
        </Grid>
        {isFacilitatorOrMember && hasResources && (
          <Grid data-testid="resources-row" direction="row" item key="ResourcesRow" marginTop="2em" xs={12}>
            <Typography variant="h5">Resource Links</Typography>
            <ResourceList items={event.resources} />
          </Grid>
        )}
        {canView && (
          <Grid container direction="row" item key="Chat" marginTop="2em">
            <Grid item key="Chat" xs={12}>
              <Chat eventId={event.id} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ViewEventRouteValidator = ({ room, eventId }: { room?: ViewEventProps['room']; eventId: string }) => {
  const { data, refetch, isRefetching } = useSessionSummaryQuery({ eventId });

  if (!data?.session) {
    return null;
  }

  // Not all places that link to this page have an event's roomId easily available
  // so we redirect to avoid piping the roomId where it is difficult to load
  if (!room && data.session.roomId) {
    return <Navigate replace to={Route.roomViewEvent({ eventId, roomId: data.session.roomId })} />;
  }

  // This is to guard against a mistake where the page was navigated to with a roomId
  // but the event doesn't have an associated room
  if (room && !data.session.roomId) {
    return <Navigate replace to={Route.eventView({ eventId })} />;
  }

  return <ViewEvent event={data.session} isLoadingEvent={isRefetching} refetchEvent={refetch} room={room} />;
};

const ViewEventWithRoom = ({ roomId, eventId }: { roomId: string; eventId: string }) => {
  const { data: room } = useEventRoomSummaryQuery({ roomId }, { select: (data) => data.room });

  if (!room) {
    return null;
  }

  return <ViewEventRouteValidator eventId={eventId} room={room} />;
};

const EditRoomlessSession = ({ eventId }: { eventId: string }) => {
  return <ViewEventRouteValidator eventId={eventId} />;
};

/** wraps ViewEvent, validating URL parameters and converting to props to ease types */
const ViewEventWrapper = () => {
  const { roomId, eventId } = useParams();

  if (!eventId) {
    return null;
  }

  return roomId ? <ViewEventWithRoom eventId={eventId} roomId={roomId} /> : <EditRoomlessSession eventId={eventId} />;
};

export default ViewEventWrapper;
